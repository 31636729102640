import React, { ReactChild, ReactElement } from 'react';
import { Box, Fade, IconButton, makeStyles } from '@material-ui/core';
import { Close } from '@novozymes/components';

interface GraphErrorOverlayProps {
  show?: boolean;
  onClose?: () => void;
  children: ReactChild[];
}

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'absolute',
    zIndex: 1000,
    marginLeft: '106px',
    marginTop: '6px',
    padding: '24px',
    width: 'calc(100% - 112px)',
    backgroundColor: '#F6F7F5',
  },
  closeButton: {
    position: 'absolute',
    top: 8,
    right: 8,
  },
}));

const GraphErrorOverlay = ({ show, onClose, children }: GraphErrorOverlayProps): ReactElement => {
  const classes = useStyles();
  return (
    <Fade in={show}>
      <Box className={classes.wrapper}>
        {!!onClose && (
          <IconButton onClick={onClose} className={classes.closeButton}>
            <Close />
          </IconButton>
        )}
        {children}
      </Box>
    </Fade>
  );
};

export default GraphErrorOverlay;
