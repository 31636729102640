import React, { ChangeEvent, useState, useCallback, useEffect, ReactElement, useContext, useMemo } from 'react';
import { Box, IconButton, FormControlLabel, Checkbox, makeStyles, Dialog } from '@material-ui/core';
import { OutlinedInputWithLabel, Button, colors } from '@novozymes/components';
import CloseIcon from '@material-ui/icons/Close';
import { Scenario } from 'scenario/ScenarioType';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { ScenarioFetchContext } from 'scenario/ScenarioGetter';
import { activeScenarioState } from 'state/atomState';
import API from 'API';
import { getCurrentRoute } from 'navigation/processNavigation';
import { getUserName } from '../../utils/getUser';

export interface SaveDialogProps {
  scenario: Scenario;
  show: boolean;
  onClose(): void;
}

const dialogStyles = makeStyles(() => ({
  container: {
    backgroundColor: colors.black67,
  },
}));

const useStyles = makeStyles((theme) => ({
  saveModal: {
    width: 400,
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2, 4),
  },
  saveModalControls: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  modalHeader: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
}));

const SaveDialog = ({ show, onClose, scenario }: SaveDialogProps): ReactElement => {
  const [userSelectedTitle, setUserSelectedTitle] = useState(scenario.title);
  const [saveAsNew, setSaveAsNew] = useState(!scenario.id);
  const setActiveScenario = useSetRecoilState(activeScenarioState);
  const history = useHistory();
  const location = useLocation();
  const fetchScenario = useContext(ScenarioFetchContext);

  const currentUrl = useMemo(() => getCurrentRoute(location.pathname), [location.pathname]);

  const classes = useStyles();
  const dialogClasses = dialogStyles();

  const { t } = useTranslation('saveDialog');

  const handleCheckboxClick = useCallback(() => {
    setSaveAsNew(!saveAsNew);
  }, [saveAsNew]);

  useEffect(() => {
    setUserSelectedTitle(scenario.title);
  }, [scenario]);

  const handleSaveSubmit = useCallback(async () => {
    const { data, id, region } = scenario;

    const userName = await getUserName();

    const body = {
      title: userSelectedTitle,
      scenarioData: data,
      region,
      id,
      creatorName: userName,
    };

    const updateFunction = saveAsNew ? API.Scenarios.create : API.Scenarios.update;

    try {
      const response = await updateFunction({ body, id });
      if (saveAsNew) {
        const createData = response.data<{ statusCode: string; body: string }>();
        history.replace(`${currentUrl}/${createData.body}`);
      } else {
        const updateData = response.data<{ statusCode: string; body: string }>();

        if (updateData.statusCode !== '200') {
          throw Error(`Scenario update unsuccessfull: ${updateData.body}`);
        } else {
          setActiveScenario({ ...scenario, title: userSelectedTitle });
          fetchScenario(id);
        }
      }
      onClose();
    } catch (err) {
      console.log(err);
    }
  }, [scenario, userSelectedTitle, saveAsNew, location.pathname]);

  return (
    <Dialog classes={dialogClasses} open={show} onClose={onClose} disableAutoFocus disableEnforceFocus>
      <Box className={classes.saveModal}>
        <Box className={classes.modalHeader}>
          <IconButton id="btn-save-dialog-close" aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <OutlinedInputWithLabel
          type="text"
          key="scenarioTitle"
          label={t('scenarioTitleInput')}
          value={userSelectedTitle}
          handleChange={(event: ChangeEvent<HTMLInputElement>): void => {
            const { value } = event.target;
            setUserSelectedTitle(value);
          }}
        />
        <Box py={2} className={classes.saveModalControls}>
          {scenario.id ? (
            <FormControlLabel
              id="check-save-dialog-save-as-new"
              control={<Checkbox checked={saveAsNew} onChange={handleCheckboxClick} name="saveAsNew" color="primary" />}
              label={t('saveAsNew')}
            />
          ) : (
            <Box />
          )}
          <Button
            id="btn-save-dialog-save"
            type="primary"
            onClick={handleSaveSubmit}
            disabled={userSelectedTitle.length <= 1}
            small
          >
            {t('save')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default SaveDialog;
