import React, { ReactElement } from 'react';

import { F55SepWaterEvaporationCostState } from 'state/selectors';

import EvaporationComponent, { EvaporationComponentProps } from 'components/Evaporation';

const F55EvapSW = (): ReactElement => {
  const props: EvaporationComponentProps = {
    translationKey: 'F55EvapSepWater',
    evaporatorTypeKey: 'F55SepWaterEvaporatorType',
    electricityPriceKey: 'F55SepWaterElectricityPrice',
    electricityConsumptionKey: 'F55SepWaterElectricityConsumption',
    effectsKey: 'F55SepWaterEffects',
    steamPriceKey: 'F55SepWaterSteamPrice',
    evaporationCostState: F55SepWaterEvaporationCostState,
  };

  return <EvaporationComponent {...props} />;
};

export default F55EvapSW;
