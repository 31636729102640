import { ModelName } from './ScenarioType';

export type Range = {
  min: number;
  max: number;
};

export type EnzymeRanges = {
  dosage: Range;
  ds: Range;
};

export type EnzymeData = Record<ModelName, EnzymeRanges>;

export const ENZYME_RANGES: EnzymeData = {
  'Extenda Pro': {
    dosage: {
      min: 0.145,
      max: 0.472,
    },
    ds: {
      min: 28,
      max: 40,
    },
  },
  'Extenda Prime': {
    dosage: {
      min: 0.145,
      max: 0.472,
    },
    ds: {
      min: 28,
      max: 40,
    },
  },
  'Extenda Peak 1.5 Extra': {
    dosage: {
      min: 0.338,
      max: 1.102,
    },
    ds: {
      min: 28,
      max: 40,
    },
  },
  'Extenda Peak 2.5 Extra': {
    dosage: {
      min: 0.2028,
      max: 0.6612,
    },
    ds: {
      min: 28,
      max: 40,
    },
  },
  'Extenda Go 2 Extra': {
    dosage: {
      min: 0.22,
      max: 0.801,
    },
    ds: {
      min: 28,
      max: 40,
    },
  },
  'Extenda Go 3.5 Extra': {
    dosage: {
      min: 0.126,
      max: 0.458,
    },
    ds: {
      min: 28,
      max: 40,
    },
  },
  'Dextrozyme Go': {
    dosage: {
      min: 0.221,
      max: 0.8,
    },
    ds: {
      min: 28,
      max: 40,
    },
  },
  'Dex High DX Plus': {
    dosage: {
      min: 0.211,
      max: 0.628,
    },
    ds: {
      min: 28,
      max: 40,
    },
  },
  'Extenda Standard': {
    dosage: {
      min: 0.19,
      max: 0.637,
    },
    ds: {
      min: 28,
      max: 40,
    },
  },
  'Dextrozyme DX': {
    dosage: {
      min: 0.44,
      max: 1.48,
    },
    ds: {
      min: 28,
      max: 40,
    },
  },
  'Dextrozyme DX 1.5X': {
    dosage: {
      min: 0.29,
      max: 0.99,
    },
    ds: {
      min: 28,
      max: 40,
    },
  },
  'Dextrozyme DX 2X': {
    dosage: {
      min: 0.22,
      max: 0.74,
    },
    ds: {
      min: 28,
      max: 40,
    },
  },
  'Dextrozyme GA': {
    dosage: {
      min: 0.3,
      max: 0.96,
    },
    ds: {
      min: 28,
      max: 40,
    },
  },
  'Dextrozyme GA 1.5X': {
    dosage: {
      min: 0.2,
      max: 0.64,
    },
    ds: {
      min: 28,
      max: 40,
    },
  },
};

const getEnzymeRange = (enzymeName: ModelName): EnzymeRanges => ENZYME_RANGES[enzymeName];

export default getEnzymeRange;
