// import { DextrozymeDX, DextrozymeDX15X, DextrozymeDX2X } from './DextrozymeDX';
// import { DextrozymeGA, DextrozymeGA15X } from './DextrozymeGA';
//import { DextrozymeGo } from './DextrozymeGo';
import EnzymeModel from './enzymeModelType';
import { ExtendaGo2Extra, ExtendaGo35Extra } from './ExtendaGo';
import { ExtendaPeak15Extra, ExtendaPeak25Extra } from './ExtendaPeak';
import { DextrozymeHighDXPlus } from './DextrozymeHighDXPlus';
import { ExtendaStandard } from './ExtendaStandard';
import { ExtendaPro, ExtendaPrime } from './ExtendaPro';

export interface ModelsType {
  'Dex High DX Plus': EnzymeModel;
  'Extenda Go 2 Extra': EnzymeModel;
  'Extenda Go 3.5 Extra': EnzymeModel;
  'Extenda Peak 1.5 Extra': EnzymeModel;
  'Extenda Peak 2.5 Extra': EnzymeModel;
  'Extenda Standard': EnzymeModel;
  'Extenda Pro': EnzymeModel;
  'Extenda Prime': EnzymeModel;

  'Dextrozyme Go': EnzymeModel;
  'Dextrozyme DX': EnzymeModel;
  'Dextrozyme DX 1.5X': EnzymeModel;
  'Dextrozyme DX 2X': EnzymeModel;
  'Dextrozyme GA': EnzymeModel;
  'Dextrozyme GA 1.5X': EnzymeModel;
}

const models = {
  'Dex High DX Plus': DextrozymeHighDXPlus,
  'Extenda Go 2 Extra': ExtendaGo2Extra,
  'Extenda Go 3.5 Extra': ExtendaGo35Extra,
  'Extenda Peak 1.5 Extra': ExtendaPeak15Extra,
  'Extenda Peak 2.5 Extra': ExtendaPeak25Extra,
  'Extenda Standard': ExtendaStandard,
  'Extenda Pro': ExtendaPro,
  'Extenda Prime': ExtendaPrime,
};

// TODO: Keeping the for reference. Need to be archived and removed from here.
// const oldModels =
//   process.env.REACT_APP_STAGE === 'sandbox'
//     ? {
//         'Dextrozyme Go': DextrozymeGo,
//         'Dextrozyme DX': DextrozymeDX,
//         'Dextrozyme DX 1.5X': DextrozymeDX15X,
//         'Dextrozyme DX 2X': DextrozymeDX2X,
//         'Dextrozyme GA': DextrozymeGA,
//         'Dextrozyme GA 1.5X': DextrozymeGA15X,
//       }
//     : {};

export default { ...models /* ...oldModels */ } as ModelsType;
