import EnzymeModel from './enzymeModelType';

export const ExtendaGo2Extra: EnzymeModel = (input) => {
  const DSgns = 34;
  const DScur = input.enzymeDs;
  const DoseGns = 0.21856;
  const scaleFactor = 472 / 1000;
  const DOSEcur = input.dosage * scaleFactor;

  const data = {
    '6H': [
      67.71941258, -0.414954206, 0, 0, -3.396426001, -0.004514833, -0.885734521, 145.3495673, -246.094287, 338.891896,
      0,
    ],
    '12H': [
      92.84435391, -0.438069529, -0.014917213, 0, -3.949671071, -0.028185076, -0.332356847, 153.3248696, -416.5493965,
      514.976543, 0,
    ],
    '18H': [
      101.2914611, -0.358439037, -0.011547516, 0, -2.555926096, -0.04448969, 0.572546869, 114.789635, -605.0968779,
      1375.421884, 0,
    ],
    '24H': [
      103.2763855, -0.291964151, -0.01202878, 0, -0.800492781, -0.051667588, 1.088180273, 66.95537398, -625.8750149,
      2199.366491, 0,
    ],
    '36H': [
      103.4805374, -0.229539959, -0.005678585, 0, 0.029961418, -0.038822031, 1.145296427, 10.39001516, -227.148024,
      2631.790473, -9247.114367,
    ],
    '48H': [
      100.586776, -0.135530419, -0.003877294, -0.001911249, 0.1139863, -0.018667503, 0.783783197, 1.087815412,
      -78.76804653, 1377.624635, -5845.00033,
    ],
    '60H': [
      102.1587767, -0.18168641, -0.004992064, 0, 0.236872277, 0, 0.399440525, 0.87311612, -86.64267885, 417.4177439, 0,
    ],
    '72H': [
      100.9610468, -0.149754365, -0.003853444, -0.000772756, 0.248903503, 0.011093005, 0.12983058, -1.147252874,
      -47.02796853, 196.0410925, 0,
    ],
    '96H': [
      101.7111386, -0.182644367, -0.002888981, 0, 0.345608921, 0.026444612, -0.106005309, -4.592399136, -26.37922358,
      104.2621987, 0,
    ],
  };

  const dataValues = Object.values(data);

  const result = dataValues.map(
    (data) =>
      data[0] +
      data[1] * DScur +
      data[2] * (DScur - DSgns) ** 2 +
      data[3] * (DScur - DSgns) ** 3 +
      data[4] +
      data[5] * (DScur - DSgns) +
      data[6] * (DOSEcur - DoseGns) * (DScur - DSgns) +
      data[7] * (DOSEcur - DoseGns) +
      data[8] * (DOSEcur - DoseGns) ** 2 +
      data[9] * (DOSEcur - DoseGns) ** 3 +
      data[10] * (DOSEcur - DoseGns) ** 4
  );

  return result;
};

export const ExtendaGo35Extra: EnzymeModel = (input) => {
  const multiFactor = 1.75;

  return ExtendaGo2Extra({ enzymeDs: input.enzymeDs, dosage: input.dosage * multiFactor });
};
