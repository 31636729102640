import { Box, Fade, Grid, Typography } from '@material-ui/core';
import DividerWithSpacing from 'components/layout/DividerWithSpacing';
import ValueHighlight from 'components/ValueHighlight';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import {
  currency,
  DmhMassBalanceSavingsState,
  enzymePricesGivenState,
  enzymeSavingsState,
  evaporationSavingsState,
  F55DilWaterSavingsState,
  ionExchangeSavingsState,
  totalDailySavingsState,
} from 'state/selectors';
import useInputWithState from 'state/useInputWithState';
import Placeholder from 'components/Placeholder';
import getAtomState from 'state/atomState';
import { SavingsChartEntry } from 'components/graphs/SavingsChart';
import SavingsChartWrapper from 'components/graphs/SavingsChartWrapper';
import { ProductionSteps } from 'scenario/ScenarioType';
import { isDMHDisabled, isF55Disabled } from 'utils/isProcessStepDisabled';

const SavingsSummary = (): ReactElement => {
  const activeCurrency = useRecoilValue(currency);
  const enzymePricesGiven = useRecoilValue(enzymePricesGivenState);
  const { t } = useTranslation('savingsSummary');

  const [refEnzymePrice, refEnzymePriceInput] = useInputWithState<number>({
    key: 'refEnzymePrice',
    unit: `${activeCurrency} / kg`,
  });

  const [newEnzymePrice, newEnzymePriceInput] = useInputWithState<number>({
    key: 'newEnzymePrice',
    unit: `${activeCurrency} / kg`,
  });

  const capacity = useRecoilValue(getAtomState<number>('saccCapacity'));

  const refEnzymeName = useRecoilValue(getAtomState<string>('refEnzymeName'));
  const newEnzymeName = useRecoilValue(getAtomState<string>('newEnzymeName'));

  const refEnzymeDosage = useRecoilValue(getAtomState<number>('refEnzymeDosing'));
  const newEnzymeDosage = useRecoilValue(getAtomState<number>('newEnzymeDosing'));

  const evapSavings = useRecoilValue(evaporationSavingsState) || 0;
  const dmhSavings = useRecoilValue(DmhMassBalanceSavingsState);
  const dailyDMHSavings = dmhSavings?.DMHDailySavings || 0;
  const f55WaterSepSavings = useRecoilValue(F55DilWaterSavingsState) || 0;

  const F55IXSavings = useRecoilValue(ionExchangeSavingsState)?.totalDailySavingsF55;

  const enzymeSavings = useRecoilValue(enzymeSavingsState) || 0;

  const productionSteps = useRecoilValue(getAtomState<ProductionSteps>('productionSteps'));
  const dmhDisabled = isDMHDisabled(productionSteps);
  const f55Disabled = isF55Disabled(productionSteps);

  const totalDailySavings = useRecoilValue(totalDailySavingsState);

  const savingsData: SavingsChartEntry[] = [
    {
      savingType: t('chart.totalSaving'),
      savings: totalDailySavings,
      highlight: true,
    },
    { savingType: t('chart.evap'), savings: evapSavings },
  ];

  if (!dmhDisabled) {
    savingsData.push({
      savingType: t('chart.dmh'),
      savings: dailyDMHSavings,
    });
  }

  if (!f55Disabled) {
    savingsData.push({
      savingType: t('chart.f55Water'),
      savings: f55WaterSepSavings,
    });
    savingsData.push({
      savingType: t('chart.f55Ion'),
      savings: F55IXSavings,
    });
  }

  savingsData.push({
    savingType: t('chart.enzyme'),
    savings: enzymeSavings,
    disable: !enzymePricesGiven,
  });

  const dailySavingsWithoutEnzyme = totalDailySavings - enzymeSavings;
  const yearlySavingsWithoutEnzyme = dailySavingsWithoutEnzyme * 365;
  const savingsPerDSWithoutEnzyme = dailySavingsWithoutEnzyme / (capacity || 1);

  return (
    <>
      <Grid container spacing={9}>
        <Grid item xs={6}>
          <Typography variant="h4">{t('costNoEnzyme')}</Typography>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <ValueHighlight value={yearlySavingsWithoutEnzyme} unit={`${activeCurrency} / year`} highlight />
            </Grid>
            <Grid item xs={4}>
              <ValueHighlight value={dailySavingsWithoutEnzyme} unit={`${activeCurrency} / day`} highlight />
            </Grid>
            <Grid item xs={4}>
              <ValueHighlight value={savingsPerDSWithoutEnzyme} unit={`${activeCurrency} / tDS`} highlight />
            </Grid>
          </Grid>

          <DividerWithSpacing pt={5} pb={3} />

          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={8}>
              <Typography variant="body1">{`${t('priceRef')} - ${refEnzymeName}`}</Typography>
            </Grid>
            <Grid item xs={4}>
              {refEnzymePriceInput}
            </Grid>

            <Grid item xs={8}>
              <Typography variant="body1">{`${t('priceNew')} - ${newEnzymeName}`}</Typography>
            </Grid>
            <Grid item xs={4}>
              {newEnzymePriceInput}
            </Grid>
          </Grid>

          <DividerWithSpacing pt={3} pb={4} />

          <Typography variant="h4">{t('costWithEnzyme')}</Typography>

          {!enzymePricesGiven && (
            <Placeholder>
              <Typography variant="body1">{t('noPricesGiven')}</Typography>
            </Placeholder>
          )}

          <Fade in={enzymePricesGiven}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <ValueHighlight value={totalDailySavings * 365} unit={`${activeCurrency} / year`} large highlight />
              </Grid>
              <Grid item xs={4}>
                <ValueHighlight value={totalDailySavings} unit={`${activeCurrency} / day`} large highlight />
              </Grid>
              <Grid item xs={12}>
                <Box mb={2} />
              </Grid>
            </Grid>
          </Fade>

          <Fade in={enzymePricesGiven}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1">{t('referenceSolution')}</Typography>
              </Grid>
              <Grid item xs={4}>
                <ValueHighlight value={refEnzymeName} />
              </Grid>
              <Grid item xs={4}>
                <ValueHighlight value={refEnzymeDosage} unit="kg / tDS" />
              </Grid>
              <Grid item xs={4}>
                <ValueHighlight value={refEnzymePrice} unit={`${activeCurrency} / kg`} />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body1">{t('newSolution')}</Typography>
              </Grid>
              <Grid item xs={4}>
                <ValueHighlight value={newEnzymeName} />
              </Grid>
              <Grid item xs={4}>
                <ValueHighlight value={newEnzymeDosage} unit="kg / tDS" />
              </Grid>
              <Grid item xs={4}>
                <ValueHighlight value={newEnzymePrice} unit={`${activeCurrency} / kg`} />
              </Grid>
            </Grid>
          </Fade>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="h4">{t('summary')}</Typography>
          <SavingsChartWrapper data={savingsData} />
        </Grid>
      </Grid>
    </>
  );
};

export default SavingsSummary;
