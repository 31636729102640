import { Grid, Typography } from '@material-ui/core';
import ValueHighlight from 'components/ValueHighlight';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import getAtomState from 'state/atomState';
import useInputWithState from 'state/useInputWithState';

const F55Process = (): ReactElement => {
  const [, F55CapacityInput] = useInputWithState({
    key: 'F55Capacity',
    unit: 'tDS/day',
  });
  const [, F55RefTemperatureInput] = useInputWithState({
    key: 'F55RefTemperature',
    unit: '°C',
  });
  const [, F55RefDSInput] = useInputWithState({ key: 'F55RefDS', unit: '%' });

  const inletDXref = useRecoilValue(getAtomState<number>('refDX'));
  const inletDXnew = useRecoilValue(getAtomState<number>('newDX'));

  const { t } = useTranslation('F55Process');

  return (
    <>
      <Typography variant="h3">{t('title')}</Typography>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={2} />

        <Grid item xs={2}>
          <Typography variant="body1">{t('capacityLabel')}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body1">{t('temperatureLabel')}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body1">{t('DSLabel')}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body1">{t('inletDXLabel')}</Typography>
        </Grid>
        <Grid item xs={2} />

        <Grid item xs={2}>
          <Typography variant="body1">{t('reference')}</Typography>
        </Grid>
        <Grid item xs={2}>
          {F55CapacityInput}
        </Grid>
        <Grid item xs={2}>
          {F55RefTemperatureInput}
        </Grid>
        <Grid item xs={2}>
          {F55RefDSInput}
        </Grid>
        <Grid item xs={2}>
          <ValueHighlight value={inletDXref} unit="%" />
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={2}>
          <Typography variant="body1">{t('new')}</Typography>
        </Grid>
        <Grid item xs={6} />

        <Grid item xs={2}>
          <ValueHighlight value={inletDXnew} unit="%" />
        </Grid>
        <Grid item xs={2} />
      </Grid>
    </>
  );
};

export default F55Process;
