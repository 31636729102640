import React, { ReactElement } from 'react';
import { Box, Divider, BoxProps } from '@material-ui/core';

export interface DividerWithSpacingProps extends BoxProps {
  pt?: number;
  pb?: number;
}

const DividerWithSpacing = ({ pt = 6, pb = 5, ...props }: DividerWithSpacingProps): ReactElement => (
  <Box pt={pt} pb={pb} {...props}>
    <Divider />
  </Box>
);

export default DividerWithSpacing;
