import { ConcPreset } from './ScenarioType';

const defaultConc: ConcPreset = {
  HCl: 32,
  H2SO4: 96,
  NaOH: 50,
  Na2CO3: 99.7,
  'Ca(OH)2': 4,
  NH4OH: 28,
  CaCl2: 100,
  'CaCl2*2H2O': 75.5,
  NaHSO3: 100,
  'Mg(HSO3)2': 30,
  'MgSO4*7H2O': 63,
  DMH: 91,
  hydrol: 100,
  DE95: 100,
};

export default defaultConc;
