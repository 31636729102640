import { Box, makeStyles, Theme, Tooltip, Typography } from '@material-ui/core';
import { colors } from '@novozymes/components';
import React, { ReactElement, ReactNode } from 'react';

interface ButtonWithIconProps {
  id: string;
  icon: ReactNode;
  title: string;
  onClick: () => void;
  disabled?: boolean;
  tooltipTitle?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'inline-flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 1, 0.5, 0.5),
    borderRadius: theme.spacing(0.5),
    '&:hover': {
      backgroundColor: (props: ButtonWithIconProps): string => (props.disabled ? 'inherit' : 'rgba(255,255,255,0.1)'),
    },
    cursor: (props: ButtonWithIconProps): string => (props.disabled ? 'default' : 'pointer'),
    color: (props: ButtonWithIconProps): string => (props.disabled ? colors.white65 : 'inherit'),
  },
  title: {
    fontSize: '16px',
    lineHeight: '24px',
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '24px',
    width: '24px',
    marginRight: theme.spacing(0.5),
    '& svg': {
      height: '15px',
    },
  },
}));

const ButtonWithIcon = (props: ButtonWithIconProps): ReactElement => {
  const { id, icon, title, onClick, disabled, tooltipTitle } = props;

  const classes = useStyles(props);

  return (
    <Tooltip
      title={tooltipTitle || 'coming soon'}
      arrow
      disableFocusListener={!disabled}
      disableHoverListener={!disabled}
      disableTouchListener={!disabled}
    >
      <Box id={id} className={classes.wrapper} onClick={disabled ? undefined : onClick}>
        <Box className={classes.iconWrapper}>{icon}</Box>
        <Typography className={classes.title}>{title}</Typography>
      </Box>
    </Tooltip>
  );
};

export default ButtonWithIcon;
