interface EvaporationSavingsInput {
  evaporationCost: number;
  evaporationRef: number;
  evaporationNew: number;
}
const evaporationSavings = ({ evaporationCost, evaporationRef, evaporationNew }: EvaporationSavingsInput): number => {
  return evaporationCost * (evaporationRef - evaporationNew);
};

export default evaporationSavings;
