import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { ResponsiveContainer } from 'recharts';
import {
  Box,
  makeStyles,
  Grid,
  useTheme,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  withStyles,
  Radio,
  RadioProps,
} from '@material-ui/core';
import { colors } from '@novozymes/components';
import { useRecoilState, useRecoilValue } from 'recoil';
import { isEnzymesOutOfRangeState } from 'state/selectors';
import { useTranslation } from 'react-i18next';
import { fitDosageStateNew, fitDosageStateRef } from 'state/tempAtoms';
import FitDSDXControlls from 'components/FitDSDXControlls';
import DXChart from './DXChart';
import LegendEntry from './LegendEntry';
import { DXChartBaseProps } from './DXChartTypes';
import GraphErrorOverlay from './GraphErrorOverlay';

export interface DXChartWrapperProps extends DXChartBaseProps {
  height: number;
  heading?: string;
}

const useStyles = makeStyles((theme) => ({
  label: {
    display: 'flex',
    alignItems: 'center',
  },
  heading: {
    fontWeight: 'bold',
    fontSize: '16px',
    textAlign: 'center',
  },
  legendContainer: {
    width: '100%',
    background: '#F6F7F5',
    padding: theme.spacing(2),
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  fitButton: {
    cursor: 'pointer',
    '&:hover': {
      fontWeight: 'bold',
    },
  },
  fitError: {
    color: colors.errorRed,
  },

  chartWrapper: {
    position: 'relative',
  },

  outOfRangeHeading: {
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '20px',
    paddingBottom: '12px',
  },
  outOfRangeBody: { fontSize: '16px', lineHeight: '24px' },
  zoomContainer: {
    width: '100%',
    padding: theme.spacing(2),
  },
  radioButtonHeading: {
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: theme.spacing(0.5),
  },
  radioButtonLabel: {
    fontSize: '14px',
    lineHeight: '20px',
  },
}));

const ColoredRadio = withStyles({
  root: {
    padding: '8px',
    color: 'rgba(0, 0, 0, 0.67)',
    '&$checked': {
      color: 'rgba(0, 0, 0, 0.67)',
    },
  },
  checked: {},
})((props: RadioProps) => <Radio size="small" color="default" {...props} />);

const DXChartWrapper = ({
  data,
  modelAName,
  modelBName,
  heading,
  height,
  ...restProps
}: DXChartWrapperProps): ReactElement => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation('DXChart');

  const isOutOfRange = useRecoilValue(isEnzymesOutOfRangeState);
  const [showOutOfRange, setShowOutOfRange] = useState(false);
  const [timer, setTimer] = useState<NodeJS.Timeout | undefined>(undefined);

  const [zoomValue, setZoomValue] = useState(1);

  const [fitRefError, setFitRefError] = useRecoilState(fitDosageStateRef);
  const [fitNewError, setFitNewError] = useRecoilState(fitDosageStateNew);
  const [showNoMatch, setShowNoMatch] = useState(!!fitRefError || !!fitNewError);

  useEffect(() => {
    if (fitRefError === 'out of range' || fitNewError === 'out of range') {
      setShowNoMatch(false);
    } else {
      setShowNoMatch(!!fitRefError || !!fitNewError);
    }
  }, [fitRefError, fitNewError]);

  const onShowNoMatchClose = useCallback(() => {
    setFitRefError(undefined);
    setFitNewError(undefined);
  }, []);

  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }
    setShowOutOfRange(false);

    if (isOutOfRange) {
      const newTimer = setTimeout(() => {
        setShowOutOfRange(isOutOfRange);
      }, 2000);
      setTimer(newTimer);
    }
  }, [isOutOfRange, setShowOutOfRange]);

  const enzymeText = useMemo(() => {
    if (fitRefError && fitNewError) {
      return 'enzymes';
    }
    if (fitRefError) {
      return 'reference enzyme';
    }
    if (fitNewError) {
      return 'new enzyme';
    }
  }, [fitRefError, fitNewError]);

  const highOrLow = useMemo(() => {
    if (fitRefError === fitNewError) {
      return fitRefError;
    }
    if ((fitRefError === 'high' && fitNewError === 'low') || (fitRefError === 'low' && fitNewError === 'high')) {
      return 'high and low';
    }
    return fitRefError || fitNewError;
  }, [fitRefError, fitNewError]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={1} />
      <Grid item xs={8} className={classes.heading}>
        {heading}
      </Grid>
      <Grid item xs={3} />

      <Grid item xs={9} justify="center">
        <Box className={classes.chartWrapper}>
          <GraphErrorOverlay show={showNoMatch && !showOutOfRange} onClose={onShowNoMatchClose}>
            <Typography className={classes.outOfRangeHeading}>{t('fittingErrorTitle', { highOrLow })}</Typography>
            <Typography className={classes.outOfRangeBody}>{t('fittingErrorText', { enzymeText })}</Typography>
            <Box pt={2}>
              <FitDSDXControlls type={fitNewError ? 'new' : 'ref'} />
            </Box>
          </GraphErrorOverlay>

          <GraphErrorOverlay show={showOutOfRange}>
            <Typography className={classes.outOfRangeHeading}>{t('errorBoxTitle')}</Typography>
            <Typography className={classes.outOfRangeBody}>{t('errorBoxText')}</Typography>
          </GraphErrorOverlay>
        </Box>
        <ResponsiveContainer height={height}>
          <DXChart
            data={data}
            modelAName={modelAName}
            modelBName={modelBName}
            modelAColor={colors.primaryGreenHover}
            modelBColor={theme.palette.secondary.main}
            zoom={zoomValue}
            {...restProps}
          />
        </ResponsiveContainer>
      </Grid>
      <Grid item xs={3}>
        <Box className={classes.legendContainer}>
          <LegendEntry name={modelAName && `1. ${modelAName}`} color={colors.primaryGreenHover} />
          <LegendEntry name={modelBName && `2. ${modelBName}`} color={theme.palette.secondary.main} />
          <LegendEntry type="dot" name={modelAName && `${modelAName} target`} color={colors.primaryGreenHover} />
          <LegendEntry type="dot" name={modelBName && `${modelBName} target`} color={theme.palette.secondary.main} />
        </Box>

        <Box className={classes.zoomContainer}>
          <FormControl component="fieldset">
            <Box className={classes.radioButtonHeading}>Zoom</Box>
            <RadioGroup
              aria-label="zoom"
              name="zoom"
              value={zoomValue}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                setZoomValue(parseInt(event.target.value, 0));
              }}
            >
              <FormControlLabel
                id="radio-zoom-1"
                key="1"
                value={1}
                control={<ColoredRadio />}
                label="x1"
                labelPlacement="end"
                classes={{
                  label: classes.radioButtonLabel,
                }}
              />
              <FormControlLabel
                id="radio-zoom-5"
                key="5"
                value={5}
                control={<ColoredRadio />}
                label="x5"
                labelPlacement="end"
                classes={{
                  label: classes.radioButtonLabel,
                }}
              />
              <FormControlLabel
                id="radio-zoom-10"
                key="10"
                value={10}
                control={<ColoredRadio />}
                label="x10"
                labelPlacement="end"
                classes={{
                  label: classes.radioButtonLabel,
                }}
              />
            </RadioGroup>
          </FormControl>
        </Box>
      </Grid>
    </Grid>
  );
};

export default DXChartWrapper;
