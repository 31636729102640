import { TIMESTEPS } from 'calc/enzymeConstants';
import { isFinite } from 'lodash';
import { RouteIndex } from 'navigation/navigationTypes';
import getEnzymeRange, { EnzymeRanges } from './enzymeData';
import { ScenarioDataKey, ScenarioData, ModelName, ValidationResult, ScenarioErrors } from './ScenarioType';

type EvaluationFunction = (input: any, scenarioData?: Partial<ScenarioData>) => ValidationResult;

const isEmpty = (input?: string | number): boolean =>
  input === undefined ||
  (typeof input === 'string' && input.trim() === '') ||
  (typeof input === 'number' && !isFinite(input));

const getEmptyMessage = (nameOfField?: string): string =>
  !isEmpty(nameOfField) ? `Please enter a value for ${nameOfField}` : '';

const getEmptyWithRangeMessage = (range: { max: number; min: number }): string =>
  `Please enter a value between ${range.min}-${range.max}`;
const getOutOfRangeMessage = (range: { max: number; min: number }): string =>
  `Must be between ${range.min} and ${range.max}`;

const checkIfEmpty: (nameOfField?: string) => EvaluationFunction =
  (nameOfField) =>
  (input): ValidationResult => {
    if (isEmpty(input)) {
      return {
        hasError: true,
        message: getEmptyMessage(nameOfField),
      };
    }
    return {
      hasError: false,
    };
  };

const isNumber = (value: any): boolean => {
  const parsedValue = parseFloat(value);
  return typeof parsedValue === 'number' && !Number.isNaN(parsedValue);
};

const checkIfInRange =
  (min: number, max: number) =>
  (value: number): ValidationResult => {
    if (isEmpty(value) || !isNumber(value)) {
      return {
        hasError: true,
        message: getEmptyWithRangeMessage({ min, max }),
      };
    }

    if (max < value || min > value) {
      return {
        hasError: true,
        message: getOutOfRangeMessage({ min, max }),
      };
    }
    return {
      hasError: false,
    };
  };

const checkEnzRange: (
  nameOfField: string,
  enzNameKey: ScenarioDataKey,
  dataKey: keyof EnzymeRanges
) => EvaluationFunction =
  (nameOfField, enzNameKey, dataKey) =>
  (input, scenarioData): ValidationResult => {
    if (!scenarioData) {
      return {
        hasError: false,
      };
    }

    const enzName = scenarioData[enzNameKey] as ModelName | undefined;
    if (!enzName) {
      return {
        hasError: false,
      };
    }

    const ranges = getEnzymeRange(enzName);
    const range = ranges[dataKey];

    if (!range) {
      if (isEmpty(input)) {
        return {
          hasError: true,
          message: getEmptyMessage(nameOfField),
        };
      }
      return {
        hasError: false,
      };
    }

    if (isEmpty(input)) {
      return {
        hasError: true,
        message: getEmptyWithRangeMessage(range),
      };
    }

    if (input > range.max || input < range.min) {
      return {
        hasError: true,
        message: getOutOfRangeMessage(range),
      };
    }

    return {
      hasError: false,
    };
  };

const checkIfFieldIsValue =
  (key: ScenarioDataKey, equalTo: any, evalFunc: EvaluationFunction) =>
  (input: any, scenarioData?: Partial<ScenarioData>): ValidationResult => {
    if (!scenarioData) {
      return {
        hasError: false,
      };
    }

    const value = scenarioData[key];

    if (value === equalTo) {
      return evalFunc(input, scenarioData);
    }
    return {
      hasError: false,
    };
  };

type SectionValidators = Partial<Record<ScenarioDataKey, EvaluationFunction>>;

const setScenario: SectionValidators = {
  saccCapacity: checkIfEmpty('Saccharification capacity'),
  productionSteps: checkIfEmpty('Production steps'),
  newEnzymeName: checkIfEmpty('New enzyme'),
  newEnzymeDosing: checkEnzRange('New enzyme dosage', 'newEnzymeName', 'dosage'),
  newEnzymeDS: checkEnzRange('New enzyme DS', 'newEnzymeName', 'ds'),
  newTime: checkIfInRange(TIMESTEPS[0], TIMESTEPS[TIMESTEPS.length - 1]),
  newDX: checkIfInRange(0, 100),
  refEnzymeName: checkIfEmpty('Ref enzyme'),
  refEnzymeDosing: checkEnzRange('Ref enzyme dosage', 'refEnzymeName', 'dosage'),
  refEnzymeDS: checkEnzRange('Ref enzyme DS', 'refEnzymeName', 'ds'),
  refTime: checkIfInRange(TIMESTEPS[0], TIMESTEPS[TIMESTEPS.length - 1]),
  refDX: checkIfInRange(0, 100),
};

const evaporation: SectionValidators = {
  evapDSFinal: checkIfInRange(0, 100),
  evaporatorType: checkIfEmpty(),
  electricityPrice: checkIfFieldIsValue('evaporatorType', 'MVR', checkIfEmpty()),
  electricityConsumption: checkIfFieldIsValue('evaporatorType', 'MVR', checkIfEmpty()),
  effects: checkIfFieldIsValue('evaporatorType', 'TVR', checkIfEmpty()),
  steamPrice: checkIfFieldIsValue('evaporatorType', 'TVR', checkIfEmpty()),
};

const DMH: SectionValidators = {
  dmhHydrol: checkIfEmpty('Hydrol'),
  dmhDe95: checkIfEmpty('DE95'),
  dmhYieldCentri: checkIfInRange(0, 100),
  dmhDsDay: checkIfEmpty(),
  dmhDsProcent: checkIfEmpty(),
  dmhDxProcent: checkIfEmpty(),
  dmhEvapDsProcent: checkIfEmpty(),
  dmhEvapDxProcent: checkIfEmpty(),
};

const F55: SectionValidators = {
  F55Capacity: checkIfEmpty('Capacity'),
  F55RefTemperature: checkIfEmpty('Temperature'),
  F55RefDS: checkIfInRange(0, 100),
  F55MgType: checkIfEmpty('Magnesium source'),
  F55MgPrice: checkIfEmpty('Magnesium price'),
  F55MgConc: checkIfInRange(0, 100),
  F55MgDosage: checkIfEmpty('Magnesium dosage'),
  F55SO2Type: checkIfEmpty('SO2 source'),
  F55SO2Price: checkIfEmpty('SO2 price'),
  F55SO2Conc: checkIfInRange(0, 100),
  F55SO2Dosage: checkIfEmpty('SO2 dosage'),
  F55BaseType: checkIfEmpty('Base source'),
  F55BasePrice: checkIfEmpty('Base price'),
  F55BaseConc: checkIfInRange(0, 100),
  F55AcidType: checkIfEmpty('Acid source'),
  F55AcidPrice: checkIfEmpty('Acid price'),
  F55AcidConc: checkIfInRange(0, 100),
  F55SepWaterEvaporatorType: checkIfEmpty(),
  F55SepWaterElectricityPrice: checkIfFieldIsValue('F55SepWaterEvaporatorType', 'MVR', checkIfEmpty()),
  F55SepWaterElectricityConsumption: checkIfFieldIsValue('F55SepWaterEvaporatorType', 'MVR', checkIfEmpty()),
  F55SepWaterEffects: checkIfFieldIsValue('F55SepWaterEvaporatorType', 'TVR', checkIfEmpty()),
  F55SepWaterSteamPrice: checkIfFieldIsValue('F55SepWaterEvaporatorType', 'TVR', checkIfEmpty()),
  F55DP2PlusRecovery: checkIfInRange(0, 100),
  F55FructoseRecovery: checkIfInRange(0, 100),
  F55DilutionWater: checkIfEmpty('Dilution water'),
  refFxProcentageIso: checkIfEmpty(),
  refFxProcentageMixing2: checkIfEmpty(),
  refFxProcentageF55: checkIfEmpty(),
  F55SweetWaterEvaporatorType: checkIfEmpty(),
  F55SweetWaterElectricityPrice: checkIfFieldIsValue('F55SweetWaterEvaporatorType', 'MVR', checkIfEmpty()),
  F55SweetWaterElectricityConsumption: checkIfFieldIsValue('F55SweetWaterEvaporatorType', 'MVR', checkIfEmpty()),
  F55SweetWaterEffects: checkIfFieldIsValue('F55SweetWaterEvaporatorType', 'TVR', checkIfEmpty()),
  F55SweetWaterSteamPrice: checkIfFieldIsValue('F55SweetWaterEvaporatorType', 'TVR', checkIfEmpty()),
  F55IXOperationCat: checkIfEmpty(),
  F55IXResinCapCat: checkIfEmpty(),
  F55IXResinLifeCat: checkIfEmpty(),
  F55IXResinPriceCat: checkIfEmpty(),
  F55IXRegenChemCat: checkIfEmpty(),
  F55IXChemPriceCat: checkIfEmpty(),
  F55IXChemConcentrationCat: checkIfInRange(0, 100),
  F55IXChemConsumpCat: checkIfEmpty(),
  F55IXWaterCat: checkIfEmpty(),
  F55IXSweetWaterDilCat: checkIfEmpty(),
  F55IXWasteWaterCat: checkIfEmpty(),
  F55IXCrossRegenCat: checkIfEmpty(),
  F55IXOperationAn: checkIfEmpty(),
  F55IXResinCapAn: checkIfEmpty(),
  F55IXResinLifeAn: checkIfEmpty(),
  F55IXResinPriceAn: checkIfEmpty(),
  F55IXRegenChemAn: checkIfEmpty(),
  F55IXChemPriceAn: checkIfEmpty(),
  F55IXChemConcentrationAn: checkIfInRange(0, 100),
  F55IXChemConsumpAn: checkIfEmpty(),
  F55IXWaterAn: checkIfEmpty(),
  F55IXSweetWaterDilAn: checkIfEmpty(),
  F55IXWasteWaterAn: checkIfEmpty(),
  F55IXCrossRegenAn: checkIfEmpty(),
  F55IXWaterPrice: checkIfEmpty(),
  F55IXWasteWaterCost: checkIfEmpty(),
};

const validators: SectionValidators[] = [setScenario, evaporation, DMH, F55];

const getScenarioValidationErrors =
  (routeIndex: RouteIndex) =>
  (scenarioData: Partial<ScenarioData>): ScenarioErrors => {
    const validatorsForPage = validators[routeIndex];

    const errorsFound: ScenarioErrors = (Object.keys(validatorsForPage) as ScenarioDataKey[]).reduce(
      (acc, scenarioKey) => {
        const validator = validatorsForPage && validatorsForPage[scenarioKey];

        if (validator) {
          const error = validator(scenarioData[scenarioKey], scenarioData);
          return { ...acc, [scenarioKey]: error };
        }

        return acc;
      },
      {} as any
    );
    return errorsFound;
  };

export default getScenarioValidationErrors;

export const checkIfValidationErrors = (errorState: ScenarioErrors): boolean =>
  Object.values(errorState).findIndex((errorObj?: ValidationResult) => errorObj?.hasError) !== -1;
