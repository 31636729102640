import { Region } from './ScenarioType';

const currencies: Record<Region, string> = {
  [Region.China]: '元',
  [Region.CWE]: '€',
  [Region.EE]: '€',
  [Region.India]: '$',
  [Region.JKA]: '$',
  [Region.LA]: '$',
  [Region.MEA]: '€',
  [Region.NA]: '$',
  [Region.SEA]: '$',
};

export default currencies;
