import React, { ReactElement } from 'react';
import EvaporationData from 'sections/EvaporationData';
import PageTitle from 'components/PageTitle';
import { useTranslation } from 'react-i18next';
import Evaporation from 'sections/Evaporation';
import DividerWithSpacing from 'components/layout/DividerWithSpacing';

const EvaporationPage = (): ReactElement => {
  const { t } = useTranslation('pageTitles');
  return (
    <>
      <PageTitle title={t('2')} />
      <EvaporationData />
      <DividerWithSpacing />
      <Evaporation />
    </>
  );
};

export default EvaporationPage;
