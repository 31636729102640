import { Acid, Base, Magnesium, So2Source } from 'scenario/ScenarioType';

export const mgContent: Record<Magnesium, number> = {
  'MgSO4*7H2O': 0.098,
  'Mg(HSO3)2': 0.129,
};

export const so2Content: Record<So2Source, number> = {
  NaHSO3: 0.615,
};

export const eqAcid: Record<Acid, number> = {
  HCl: 27.4,
  H2SO4: 20.4,
};

export const eqBase: Record<Base, number> = {
  NaOH: 25,
  Na2CO3: 18.9,
  NH4OH: 28.57,
  'Ca(OH)2': 27.0,
};
