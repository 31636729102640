import React, { ReactElement, ReactNode } from 'react';
import { Box, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useInputWithState from 'state/useInputWithState';
import DXChartWrapper from 'components/graphs/DXChartsWrapper';
import models from 'calc/enzymeModels/Models';
import { useRecoilValue } from 'recoil';
import { activeScenarioRegion, DXDevelopmentState } from 'state/selectors';
import getEnzymeRange from 'scenario/enzymeData';
import { ModelName } from 'scenario/ScenarioType';
import FitDosageControls from 'components/FitDosageControls';
import { TIMESTEPS } from 'calc/enzymeConstants';

const useStyles = makeStyles((theme: Theme) => ({
  sectionTitle: {
    fontWeight: 'bold',
    paddingBottom: theme.spacing(4),
  },
  heading: {
    fontWeight: 'bold',
    paddingBottom: theme.spacing(3),
  },
  label: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {},
  inputLeft: {
    padding: theme.spacing(0, 1, 0, 0),
  },
  inputRight: {
    padding: theme.spacing(0, 0, 0, 1),
  },
}));

const GridInputs = ({ refInput, newInput }: { refInput: ReactNode; newInput: ReactNode }): ReactElement => {
  const classes = useStyles();

  return (
    <Grid item xs={9} container>
      <Grid item xs={6} className={classes.inputLeft}>
        {refInput}
      </Grid>
      <Grid item xs={6} className={classes.inputRight}>
        {newInput}
      </Grid>
    </Grid>
  );
};

const ComparisonScenario = (): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation('comparisonScenario');
  const { t: titleTranslations } = useTranslation('pageTitles');
  const selectedRegion = useRecoilValue(activeScenarioRegion);

  // Ref Enzyme
  const [refEnzymeName, refEnzymeNameInput] = useInputWithState<ModelName>({
    key: 'refEnzymeName',
    type: 'Select',
    selectOptions: Object.keys(models)
      .filter((modelName) => {
        if ((modelName === 'Dex High DX Plus'
          || modelName === 'Extenda Prime')
          && selectedRegion !== 'China') {
          return false;
        } else if (modelName === 'Extenda Pro' && selectedRegion !== 'North America') {
          return false;
        } else {
          return true;
        }
      })
      .map((modelName) => ({
        value: modelName,
        label: modelName,
      })),
    placeholder: t('refEnzyme'),
  });

  const refEnzymeRanges = refEnzymeName && getEnzymeRange(refEnzymeName);
  const [, refEnzymeDosingInput] = useInputWithState({
    key: 'refEnzymeDosing',
    unit: 'kg / tDS',
    placeholder: refEnzymeRanges && `${refEnzymeRanges.dosage.min} - ${refEnzymeRanges.dosage.max}`,
    enableShowPrevious: true,
  });
  const [, refDSInput] = useInputWithState({
    key: 'refEnzymeDS',
    unit: '%',
    placeholder: refEnzymeRanges && `${refEnzymeRanges.ds.min} - ${refEnzymeRanges.ds.max}`,
    enableShowPrevious: true,
  });
  const [refTime, refTimeInput] = useInputWithState<string>({
    key: 'refTime',
    unit: 'hours',
    placeholder: `${TIMESTEPS[0]} - ${TIMESTEPS[TIMESTEPS.length - 1]}`,
    enableShowPrevious: true,
  });
  const [refDX, refDXInput] = useInputWithState<string>({
    key: 'refDX',
    unit: '%',
    placeholder: `0 - 100`,
    enableShowPrevious: true,
  });

  // New Enzyme
  const [newEnzymeName, newEnzymeNameInput] = useInputWithState<ModelName>({
    key: 'newEnzymeName',
    type: 'Select',
    selectOptions: Object.keys(models)
      .filter((modelName) => {
        if ((modelName === 'Dex High DX Plus'
          || modelName === 'Extenda Prime')
          && selectedRegion !== 'China') {
          return false;
        } else if (modelName === 'Extenda Pro' && selectedRegion !== 'North America') {
          return false;
        } else {
          return true;
        }
      })
      .map((modelName) => ({
        value: modelName,
        label: modelName,
      })),
    placeholder: t('newEnzyme'),
  });

  const newEnzymeRanges = newEnzymeName && getEnzymeRange(newEnzymeName);
  const [, newEnzymeDosingInput] = useInputWithState({
    key: 'newEnzymeDosing',
    unit: 'kg / tDS',
    placeholder: newEnzymeRanges && `${newEnzymeRanges.dosage.min} - ${newEnzymeRanges.dosage.max}`,
    enableShowPrevious: true,
  });
  const [, newDSInput] = useInputWithState({
    key: 'newEnzymeDS',
    unit: '%',
    placeholder: newEnzymeRanges && `${newEnzymeRanges.ds.min} - ${newEnzymeRanges.ds.max}`,
    enableShowPrevious: true,
  });
  const [newTime, newTimeInput] = useInputWithState<string>({
    key: 'newTime',
    unit: 'hours',
    placeholder: `${TIMESTEPS[0]} - ${TIMESTEPS[TIMESTEPS.length - 1]}`,
    enableShowPrevious: true,
  });
  const [newDX, newDXInput] = useInputWithState<string>({
    key: 'newDX',
    unit: '%',
    placeholder: `0 - 100`,
    enableShowPrevious: true,
  });

  const graphData = useRecoilValue(DXDevelopmentState);

  const newTarget = newTime && newDX ? { time: parseFloat(newTime), dx: parseFloat(newDX) } : undefined;
  const refTarget = refTime && refDX ? { time: parseFloat(refTime), dx: parseFloat(refDX) } : undefined;

  return (
    <>
      <Typography variant="h2" className={classes.sectionTitle}>
        {titleTranslations('1')}
      </Typography>

      <Grid container>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={4} />
            <Grid item xs={4} className={classes.heading}>
              <Typography variant="body1">{t('reference')}</Typography>
            </Grid>
            <Grid item xs={4} className={classes.heading}>
              <Typography variant="body1">{t('newSolution')}</Typography>
            </Grid>
            <Grid item xs={3} className={classes.label}>
              <Typography variant="body1"> {t('enzymeLabel')} </Typography>
            </Grid>
            <GridInputs refInput={refEnzymeNameInput} newInput={newEnzymeNameInput} />

            <Grid item xs={3} className={classes.label}>
              <Typography variant="body1">{t('dosingLabel')}</Typography>
            </Grid>
            <GridInputs refInput={refEnzymeDosingInput} newInput={newEnzymeDosingInput} />

            <Grid item xs={3} className={classes.label}>
              <Typography variant="body1">{t('DSLabel')}</Typography>
            </Grid>
            <GridInputs refInput={refDSInput} newInput={newDSInput} />
          </Grid>
          <Box mb={2} />
          <Grid container spacing={2}>
            <Grid item xs={3} className={classes.label}>
              <Typography variant="body1">{t('timeLabel')}</Typography>
            </Grid>

            <GridInputs refInput={refTimeInput} newInput={newTimeInput} />

            <Grid item xs={3} className={classes.label}>
              <Typography variant="body1">{t('DXLabel')}</Typography>
            </Grid>
            <GridInputs refInput={refDXInput} newInput={newDXInput} />

            <Grid item xs={3} />
            <GridInputs refInput={<FitDosageControls type="ref" />} newInput={<FitDosageControls type="new" />} />
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <DXChartWrapper
            data={graphData}
            height={400}
            heading={t('DXDevelopment')}
            modelATarget={newTarget}
            modelBTarget={refTarget}
            modelAName={newEnzymeName}
            modelBName={refEnzymeName}
          />
        </Grid>
      </Grid>
    </>
  );
};
export default ComparisonScenario;
