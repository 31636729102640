import { ModelsType } from 'calc/enzymeModels/Models';

export enum Region {
  China = 'China',
  CWE = 'Central West Europe',
  EE = 'East Europe',
  India = 'India',
  JKA = 'Japan and Korea',
  LA = 'Latin America ',
  MEA = 'Middle East Africa',
  NA = 'North America',
  SEA = 'South East Asia',
}

export enum Acid {
  HCl = 'HCl',
  H2SO4 = 'H2SO4',
}
export enum Base {
  NaOH = 'NaOH',
  Na2CO3 = 'Na2CO3',
  NH4OH = 'NH4OH',
  'Ca(OH)2' = 'Ca(OH)2',
}
export enum Magnesium {
  'MgSO4*7H2O' = 'MgSO4*7H2O',
  'Mg(HSO3)2' = 'Mg(HSO3)2',
}
export enum So2Source {
  'NaHSO3' = 'NaHSO3',
}

export type EvaporatorType = 'MVR' | 'TVR';
export type Effects = '1' | '2' | '3' | '4+' | undefined;

export enum ProductionSteps {
  DMH = 'Only DMH',
  F55 = 'Only F55',
  DMHAndF55 = 'Both DMH & F55',
  none = 'Neither DMH nor F55',
}

export enum Operation {
  'Counter current' = 'Counter current',
  'Co-current' = 'Co-current',
}

export type PricePreset = {
  HCl?: number;
  H2SO4?: number;
  NaOH?: number;
  Na2CO3?: number;
  'Ca(OH)2'?: number;
  NH4OH?: number;
  CaCl2?: number;
  'CaCl2*2H2O'?: number;
  NaHSO3?: number;
  'Mg(HSO3)2'?: number;
  'MgSO4*7H2O'?: number;
  catResin?: number;
  anResin?: number;
  steam?: number;
  electricity?: number;
  processWater?: number;
  wasteWater?: number;
  DMH?: number;
  hydrol?: number;
  DE95?: number;
};

export type ConcPreset = {
  HCl?: number;
  H2SO4?: number;
  NaOH?: number;
  Na2CO3?: number;
  'Ca(OH)2'?: number;
  NH4OH?: number;
  CaCl2?: number;
  'CaCl2*2H2O'?: number;
  NaHSO3?: number;
  'Mg(HSO3)2'?: number;
  'MgSO4*7H2O'?: number;
  DMH?: number;
  hydrol?: number;
  DE95?: number;
};

export type ModelName = keyof ModelsType;

export interface ScenarioData {
  saccCapacity: number;
  productionSteps: ProductionSteps;
  refEnzymeName: ModelName;
  newEnzymeName: ModelName;
  refEnzymeDosing: number;
  newEnzymeDosing: number;
  refEnzymePrice: number;
  newEnzymePrice: number;
  refEnzymeDS: number;
  newEnzymeDS: number;
  refTime: number;
  newTime: number;
  refDX: number;
  newDX: number;
  dmhYieldCentri: number;
  dmhHydrol: number;
  dmhDe95: number;
  evapDSFinal: number;
  evaporatorType: EvaporatorType;
  electricityPrice: number;
  electricityConsumption: number;
  effects: Effects;
  steamPrice: number;
  F55Capacity: number;
  F55RefTemperature: number;
  F55RefDS: number;
  F55MgType: Magnesium;
  F55MgPrice: number;
  F55MgConc: number;
  F55MgDosage: number;
  F55SO2Type: So2Source;
  F55SO2Price: number;
  F55SO2Conc: number;
  F55SO2Dosage: number;
  F55BaseType: Base;
  F55BasePrice: number;
  F55BaseConc: number;
  F55AcidType: Acid;
  F55AcidPrice: number;
  F55AcidConc: number;
  F55SepWaterEvaporatorType: EvaporatorType;
  F55SepWaterElectricityPrice: number;
  F55SepWaterElectricityConsumption: number;
  F55SepWaterEffects: Effects;
  F55SepWaterSteamPrice: number;
  F55SweetWaterEvaporatorType: EvaporatorType;
  F55SweetWaterElectricityPrice: number;
  F55SweetWaterElectricityConsumption: number;
  F55SweetWaterEffects: Effects;
  F55SweetWaterSteamPrice: number;
  F55DP2PlusRecovery: number;
  F55FructoseRecovery: number;
  F55DilutionWater: number;
  refFxProcentageIso: number;
  refFxProcentageMixing2: number;
  refFxProcentageF55: number;
  F55IXOperationCat: Operation;
  F55IXOperationAn: Operation;
  F55IXResinCapCat: number;
  F55IXResinCapAn: number;
  F55IXResinLifeCat: number;
  F55IXResinLifeAn: number;
  F55IXResinPriceCat: number;
  F55IXResinPriceAn: number;
  F55IXRegenChemCat: Acid;
  F55IXRegenChemAn: Base;
  F55IXChemPriceCat: number;
  F55IXChemPriceAn: number;
  F55IXChemConsumpCat: number;
  F55IXChemConsumpAn: number;
  F55IXChemConcentrationCat: number;
  F55IXChemConcentrationAn: number;
  F55IXWaterCat: number;
  F55IXWaterAn: number;
  F55IXSweetWaterDilCat: number;
  F55IXSweetWaterDilAn: number;
  F55IXWasteWaterCat: number;
  F55IXWasteWaterAn: number;
  F55IXCrossRegenCat: number;
  F55IXCrossRegenAn: number;
  F55IXWaterPrice: number;
  F55IXWasteWaterCost: number;
  dmhEvapDsProcent: number;
  dmhEvapDxProcent: number;
  dmhDsDay: number;
  dmhDsProcent: number;
  dmhDxProcent: number;
}

export type ScenarioDataKey = keyof ScenarioData;

export type ValidationResult = { hasError: boolean; message?: string };
export type ScenarioErrors = Partial<Record<ScenarioDataKey, ValidationResult | undefined>>;

export interface Scenario {
  title: string;
  creatorName?: string;
  id?: string;
  region?: Region;
  data: Partial<ScenarioData>;
  updatedAt: number;
}

export interface ScenarioFromBackend {
  Title: string;
  ScenarioId: string;
  CreatorName: string;
  ScenarioData: ScenarioData;
  ScenarioRegion: Region;
  UpdatedAt: number;
}

export interface ScenarioParams {
  scenarioId?: string;
  stepUrl: string;
}
