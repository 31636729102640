export default <T>(func?: (funcInput: any) => T, defaultReturnValue: any = undefined) =>
  (input: any): T => {
    if (input === undefined || input === null) {
      return defaultReturnValue;
    }
    if (typeof input === 'object' && Object.values(input).some((value) => value === undefined)) {
      return defaultReturnValue;
    }

    if (!func) {
      return defaultReturnValue;
    }

    return func(input);
  };
