import { Box } from '@material-ui/core';
import { Button } from '@novozymes/components';
import FitDosage from 'calc/FitDosage';
import React, { ReactElement, useCallback } from 'react';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { ModelName } from 'scenario/ScenarioType';
import getAtomState, { shouldShowPreviousState } from 'state/atomState';
import { fitDosageStateNew, fitDosageStateRef } from 'state/tempAtoms';
import undefinedInputChecker from 'utils/undefinedInputChecker';

interface FitDosageControlsProps {
  type: 'ref' | 'new';
}

const FitDosageControls = ({ type }: FitDosageControlsProps): ReactElement => {
  const isNew = type === 'new';
  const modelName = useRecoilValue(getAtomState<ModelName>(isNew ? 'newEnzymeName' : 'refEnzymeName'));
  const enzymeDs = useRecoilValue(getAtomState<number>(isNew ? 'newEnzymeDS' : 'refEnzymeDS'));
  const targetDX = useRecoilValue(getAtomState<number>(isNew ? 'newDX' : 'refDX'));
  const targetTime = useRecoilValue(getAtomState<number>(isNew ? 'newTime' : 'refTime'));
  const setDosage = useSetRecoilState(getAtomState<number>(isNew ? 'newEnzymeDosing' : 'refEnzymeDosing'));
  const setShowPreviousState = useSetRecoilState(shouldShowPreviousState);

  const setError = useSetRecoilState(isNew ? fitDosageStateNew : fitDosageStateRef);

  const handleFitClick = useCallback(() => {
    setShowPreviousState(true);

    const result = undefinedInputChecker(FitDosage)({
      modelName,
      enzymeDs,
      targetDX,
      targetTime,
    });

    if (typeof result === 'number') {
      setDosage(result);
    } else {
      setError(result);
    }
  }, [modelName, enzymeDs, targetDX, targetTime]);

  return (
    <Box>
      <Button id={`btn-fit-dose-${type}`} type="secondary" small onClick={handleFitClick}>
        {`Fit ${isNew ? 'new' : 'ref.'} dosage to target`}
      </Button>
    </Box>
  );
};

export default FitDosageControls;
