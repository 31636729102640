import React, { ReactElement } from 'react';
import ReactPDF, { Text, View } from '@react-pdf/renderer';
import { Theme } from '@material-ui/core';
import { Scenario } from 'scenario/ScenarioType';
import { CalcDMHOutput } from 'calc/DMH';
import PageWithHeader from './PageWithHeader';
import PDFValueHighlight from './PDFValueHighlight';
import { renderIfNotUndefined } from './helpers';
import PDFMassBalanceTable, { GridRow } from './PDFMassbalanceTable';

interface DMHPDFProps {
  currency: string;
  currentScenario: Scenario;
  calculationResult: Record<string, any>;
  styles: ReactPDF.Styles;
  theme: Theme;
}

const massBalanceToGridRows = (
  massBal: CalcDMHOutput | undefined,
  saccDsPro: number | undefined,
  saccDxPro: number | undefined,
  evapDs: number | undefined,
  evapDx: number | undefined,
  dmhDsDay: number | undefined,
  dmhDsPro: number | undefined,
  dmhDxPro: number | undefined
): GridRow[] => {
  return [
    ['Sacch.', massBal?.totalSacc, massBal?.dailyDSSacc, massBal?.dailyDXSacc, saccDsPro, saccDxPro],
    [
      'Hydrol Rtn.',
      massBal?.totalHydrolRec,
      massBal?.dailyDSHydrolRec,
      massBal?.dailyDXHydrolRec,
      massBal?.procentageDSHydrolRec,
      massBal?.procentageDXHydrolRec,
    ],
    ['Evaporation', massBal?.totalEvap, massBal?.dailyDSEvap, massBal?.dailyDXEvap, evapDs, evapDx],
    [
      'Crystallization',
      massBal?.totalCrystal,
      massBal?.dailyDSCrystal,
      massBal?.dailyDXCrystal,
      massBal?.procentageDSCrystal,
      massBal?.procentageDXCrystal,
    ],
    [
      'Centrifugation',
      massBal?.totalCentrif,
      massBal?.dailyDSCentrif,
      massBal?.dailyDXCentrif,
      massBal?.procentageDSCentrif,
      massBal?.procentageDXCentrif,
    ],
    [
      'Hydrol total',
      massBal?.totalHydrolTot,
      massBal?.dailyDSHydrolTot,
      massBal?.dailyDXHydrolTot,
      massBal?.procentageDSHydrolTot,
      massBal?.procentageDXHydrolTot,
    ],
    [
      'Hydrol out',
      massBal?.totalHydrolOut,
      massBal?.dailyDSHydrolOut,
      massBal?.dailyDSHydrolOut,
      massBal?.procentageDSHydrolOut,
      massBal?.procentageDXHydrolOut,
    ],
    ['DMH', massBal?.totalDMH, dmhDsDay, massBal?.dailyDXDMH, dmhDsPro, dmhDxPro],
  ];
};

const DMHPDF = ({ currency, currentScenario, calculationResult, styles, theme }: DMHPDFProps): ReactElement => {
  const evapDsValue = currentScenario.data.dmhEvapDsProcent;
  const evapDxValue = currentScenario.data.dmhEvapDxProcent;
  const dmhDsDayValue = currentScenario.data.dmhDsDay;
  const dmhDsProcentValue = currentScenario.data.dmhDsProcent;
  const dmhDxProcentValue = currentScenario.data.dmhDxProcent;

  const colLabels = [
    { label: 'Total', unit: `t/day` },
    { label: 'DS', unit: `t/day` },
    { label: 'DX', unit: `t/day` },
    { label: 'DS', unit: `%` },
    { label: 'DX', unit: `%` },
  ];
  const refDsPro = calculationResult.refDSOutlet;
  const refDxPro = currentScenario.data.refDX;
  const refMass = calculationResult.DmhRefMassBalance;

  const refMassRows = massBalanceToGridRows(
    refMass,
    refDsPro,
    refDxPro,
    evapDsValue,
    evapDxValue,
    dmhDsDayValue,
    dmhDsProcentValue,
    dmhDxProcentValue
  );

  const newDsPro = calculationResult.newDSOutlet;
  const newDxPro = currentScenario.data.newDX;
  const newMass = calculationResult.DmhNewMassBalance;

  const newMassRows = massBalanceToGridRows(
    newMass,
    newDsPro,
    newDxPro,
    evapDsValue,
    evapDxValue,
    dmhDsDayValue,
    dmhDsProcentValue,
    dmhDxProcentValue
  );

  return (
    <>
      <PageWithHeader theme={theme}>
        <View>
          <Text style={styles.h2}>3a. Production step: DMH Crystallization</Text>
        </View>
        {renderIfNotUndefined(3, [
          currentScenario.data.dmhHydrol,
          currentScenario.data.dmhYieldCentri,
          currentScenario.data.dmhDe95,
        ])(
          <>
            <View>
              <Text style={styles.h3}>Reference mass balance</Text>
            </View>
            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <Text style={styles.tableHeading}>Hydrol</Text>
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.dmhHydrol} unit={`${currency}/t`} />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={100} unit="%" />
              </View>
              <View style={styles.gridItem}>
                <Text style={styles.tableHeading}>DMH Yield after centrifuge</Text>
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.dmhYieldCentri} unit="% DS (w/w)" />
              </View>
            </View>
            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <Text style={styles.tableHeading}>DE95</Text>
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.dmhDe95} unit={`${currency}/t`} />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={100} unit="%" />
              </View>
              <View style={styles.gridItem} />
              <View style={styles.gridItem} />
            </View>
          </>
        )}

        <View>
          <Text style={styles.h3}>Reference mass balance</Text>
        </View>

        {renderIfNotUndefined(3, [
          refMass,
          refDsPro,
          refDxPro,
          evapDsValue,
          evapDxValue,
          dmhDsDayValue,
          dmhDsProcentValue,
          dmhDxProcentValue,
        ])(<PDFMassBalanceTable styles={styles} rowData={refMassRows} colLabels={colLabels} />)}
      </PageWithHeader>
      <PageWithHeader theme={theme}>
        <View>
          <Text style={styles.h3}>New mass balance</Text>
        </View>

        {renderIfNotUndefined(3, [
          newMass,
          newDsPro,
          newDxPro,
          evapDsValue,
          evapDxValue,
          dmhDsDayValue,
          dmhDsProcentValue,
          dmhDxProcentValue,
        ])(<PDFMassBalanceTable styles={styles} rowData={newMassRows} colLabels={colLabels} />)}

        {renderIfNotUndefined(3, [
          calculationResult.dmhSavings?.DMHDailySavings,
          calculationResult.dmhSavings?.DMHDailySavingsPerTonDS,
        ])(
          <View style={[styles.gridRow, { marginTop: 16 }]}>
            <View style={styles.gridItem} />
            <View style={styles.gridItem} />
            <View style={styles.gridItem} />
            <View style={styles.gridItem}>
              <Text style={styles.tableHeading}>Saving</Text>
            </View>
            <View style={styles.gridItem}>
              <PDFValueHighlight value={calculationResult.dmhSavings?.DMHDailySavings} unit={`${currency}/day`} />
            </View>
            <View style={styles.gridItem}>
              <PDFValueHighlight
                value={calculationResult.dmhSavings?.DMHDailySavingsPerTonDS}
                unit={`${currency}/t DS`}
              />
            </View>
          </View>
        )}
      </PageWithHeader>
    </>
  );
};

export default DMHPDF;
