import React, { ReactElement } from 'react';
import { Box } from '@material-ui/core';
import BasicProcessDetails from 'sections/BasicProcessDetails';
import ComparisonScenario from 'sections/ComparisonScenario';
import DividerWithSpacing from 'components/layout/DividerWithSpacing';

const SetScenario = (): ReactElement => (
  <>
    <Box mt={6} />
    <BasicProcessDetails />
    <DividerWithSpacing />
    <ComparisonScenario />
  </>
);

export default SetScenario;
