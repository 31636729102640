import DividerWithSpacing from 'components/layout/DividerWithSpacing';
import PageTitle from 'components/PageTitle';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import IonExchangeSavings from 'sections/IonExchangeSavings';
import SavingsDetails from 'sections/SavingsDetails';
import SavingsSummary from 'sections/SavingsSummary';

const Savings = (): ReactElement => {
  const { t } = useTranslation('pageTitles');

  return (
    <>
      <PageTitle title={t('4')} />
      <SavingsSummary />
      <DividerWithSpacing />
      <SavingsDetails />
      <DividerWithSpacing />
      <IonExchangeSavings />
    </>
  );
};

export default Savings;
