import { atom, RecoilState, selector } from 'recoil';
import { Scenario, ScenarioErrors, ScenarioDataKey } from '../scenario/ScenarioType';
import defaultScenario from '../scenario/DefaultScenario';
import getScenarioEffect from './stateEffects';

export const remoteScenarioState = atom<Scenario | undefined>({
  key: 'remoteScenario',
  default: undefined,
});
export const activeScenarioState = atom<Scenario>({
  key: 'activeScenario',
  default: defaultScenario,
});
export const scenarioErrorState = atom<ScenarioErrors>({
  key: 'scenarioErrors',
  default: {},
});

export const shouldShowPreviousState = atom<boolean>({
  key: 'shouldShowPreviousState',
  default: false,
});

type FieldStatesType = Partial<{ [key in ScenarioDataKey]: RecoilState<any> }>;
export const fieldStates: FieldStatesType = {};

function getAtomState<T>(key: ScenarioDataKey): RecoilState<T | undefined> {
  const value = fieldStates[key] as RecoilState<T | undefined>;
  if (!value) {
    const fieldState: RecoilState<T | undefined> = selector({
      key,
      get: ({ get }) => {
        const activeScenario = get(activeScenarioState);
        return activeScenario.data[key] as unknown as T | undefined;
      },
      set: ({ get, set }, newValue) => {
        const activeScenario = get(activeScenarioState);
        const scenarioEffect = getScenarioEffect(key);
        const updatedScenario: Scenario = scenarioEffect({
          ...activeScenario,
          data: { ...activeScenario.data, [key]: newValue },
        });
        set(activeScenarioState, updatedScenario);
      },
    });
    fieldStates[key] = fieldState;
    return fieldState;
  }
  return value;
}
export default getAtomState;
