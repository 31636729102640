import React, { ReactElement } from 'react';
import ReactPDF, { Text, View } from '@react-pdf/renderer';
import { Theme } from '@material-ui/core';
import { Scenario } from 'scenario/ScenarioType';
import { F55SeparationNewMassBalanceOutput, F55SeparationMassBalanceOutput } from 'calc/F55Types';
import PageWithHeader from './PageWithHeader';
import PDFValueHighlight from './PDFValueHighlight';
import { renderIfNotUndefined } from './helpers';
import PDFMassBalanceTable, { GridRow } from './PDFMassbalanceTable';

interface F55PDFProps {
  currency: string;
  currentScenario: Scenario;
  calculationResult: Record<string, any>;
  styles: ReactPDF.Styles;
  theme: Theme;
}

const massBalanceToGridRows = (
  massBal: F55SeparationNewMassBalanceOutput | F55SeparationMassBalanceOutput | undefined,
  F55Capacity: number | undefined,
  DX: number | undefined,
  procentageFxIso: number | undefined,
  FxProcentageF55: number | undefined,
  FxProcentageMixing2: number | undefined
): GridRow[] => {
  return [
    [
      'Sacch.',
      F55Capacity,
      massBal?.dailyDP2PlusSacc,
      massBal?.dailyFxSacc,
      DX,
      massBal?.procentageDP2PlusSacc,
      massBal?.procentageFxSacc,
    ],
    [
      'Mixing 1',
      massBal?.dailyDSMixing1,
      massBal?.dailyDP2PlusMixing1,
      massBal?.dailyFxMixing1,
      massBal?.procentageDP1Mixing1,
      massBal?.procentageDP2PlusMixing1,
      massBal?.procentageFxMixing1,
    ],
    [
      'Isomerisation',
      massBal?.dailyDSIso,
      massBal?.dailyDP2PlusIso,
      massBal?.dailyFxIso,
      massBal?.procentageDP1Iso,
      massBal?.procentageDP2PlusIso,
      procentageFxIso,
    ],
    [
      'Chrom.',
      massBal?.dailyDSChrom,
      massBal?.dailyDP2PlusChrom,
      massBal?.dailyFxChrom,
      massBal?.procentageDP1Chrom,
      massBal?.procentageDP2PlusChrom,
      massBal?.procentageFxChrom,
    ],
    [
      'Vol. to mixing 2',
      massBal?.dailyDSMixing2,
      massBal?.dailyDP2PlusMixing2,
      massBal?.dailyFxMixing2,
      massBal?.procentageDP1Mixing2,
      massBal?.procentageDP2PlusMixing2,
      FxProcentageMixing2,
    ],
    [
      'F42',
      massBal?.dailyDSF42,
      massBal?.dailyDP2PlusF42,
      massBal?.dailyFxF42,
      massBal?.procentageDP1F42,
      massBal?.procentageDP2PlusF42,
      massBal?.procentageFxF42,
    ],
    [
      'F55',
      F55Capacity,
      massBal?.dailyDP2PlusF55,
      massBal?.dailyFxF55,
      DX,
      massBal?.procentageDP2PlusF55,
      FxProcentageF55,
    ],
  ];
};
const colLabels = [
  { label: 'DS' },
  { label: 'DP2+' },
  { label: 'Fx' },
  { label: 'DP1', unit: '%' },
  { label: 'DP2+', unit: '%' },
  { label: 'Fx', unit: '%' },
];

const F55PDF = ({ currency, currentScenario, calculationResult, styles, theme }: F55PDFProps): ReactElement => {
  const newMassRows = massBalanceToGridRows(
    calculationResult.F55NewMassBalance,
    currentScenario.data.F55Capacity,
    currentScenario.data.newDX,
    calculationResult.F55NewMassBalance.procentageFxIso,
    currentScenario.data.refFxProcentageF55,
    currentScenario.data.refFxProcentageMixing2
  );
  const refMassRows = massBalanceToGridRows(
    calculationResult.F55RefMassBalance,
    currentScenario.data.F55Capacity,
    currentScenario.data.refDX,
    currentScenario.data.refFxProcentageIso,
    currentScenario.data.refFxProcentageF55,
    currentScenario.data.refFxProcentageMixing2
  );

  return (
    <>
      <PageWithHeader theme={theme}>
        <View>
          <Text style={styles.h2}>3b. Production step: F55 isomerisation</Text>
        </View>
        {renderIfNotUndefined(4, [
          currentScenario.data.F55Capacity,
          currentScenario.data.F55RefTemperature,
          currentScenario.data.F55RefDS,
          currentScenario.data.refDX,
          currentScenario.data.newDX,
        ])(
          <>
            <View>
              <Text style={styles.h3}>Process</Text>
            </View>
            <View style={styles.gridRow}>
              <View style={styles.gridItem} />
              <View style={styles.gridItem}>
                <Text style={styles.tableHeading}>Capacity</Text>
              </View>
              <View style={styles.gridItem}>
                <Text style={styles.tableHeading}>Temperature</Text>
              </View>
              <View style={styles.gridItem}>
                <Text style={styles.tableHeading}>Dry solids (DS)</Text>
              </View>
              <View style={styles.gridItem}>
                <Text style={styles.tableHeading}>Inlet Dextrose (DX)</Text>
              </View>
            </View>
            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <Text>Reference</Text>
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.F55Capacity} unit="t DS/day" />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.F55RefTemperature} unit="°C" />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.F55RefDS} unit="%" />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.refDX} unit="%" />
              </View>
            </View>
            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <Text>New</Text>
              </View>
              <View style={styles.gridItem} />
              <View style={styles.gridItem} />
              <View style={styles.gridItem} />
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.newDX} unit="%" />
              </View>
            </View>
          </>
        )}

        <View>
          <Text style={styles.h3}>Chemical addition</Text>
        </View>
        {renderIfNotUndefined(4, [
          currentScenario.data.F55MgType,
          currentScenario.data.F55MgPrice,
          currentScenario.data.F55MgConc,
          currentScenario.data.F55MgDosage,
          currentScenario.data.F55SO2Type,
          currentScenario.data.F55SO2Price,
          currentScenario.data.F55SO2Conc,
          currentScenario.data.F55SO2Dosage,
          currentScenario.data.F55BaseType,
          currentScenario.data.F55BasePrice,
          currentScenario.data.F55BaseConc,
          currentScenario.data.F55AcidType,
          currentScenario.data.F55AcidPrice,
          currentScenario.data.F55AcidConc,
        ])(
          <>
            <View style={styles.gridRow}>
              <View style={styles.gridItem} />
              <View style={styles.gridItem}>
                <Text style={styles.tableHeading}>Type</Text>
              </View>
              <View style={styles.gridItem}>
                <Text style={styles.tableHeading}>Price</Text>
              </View>
              <View style={styles.gridItem}>
                <Text style={styles.tableHeading}>Concentration</Text>
              </View>
              <View style={styles.gridItem}>
                <Text style={styles.tableHeading}>Dosage</Text>
              </View>
            </View>
            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <Text>Mg</Text>
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.F55MgType} />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.F55MgPrice} unit={`${currency}/kg`} />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.F55MgConc} unit="%" />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.F55MgDosage} unit="ppm" />
              </View>
            </View>
            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <Text>SO2</Text>
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.F55SO2Type} />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.F55SO2Price} unit={`${currency}/kg`} />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.F55SO2Conc} unit="%" />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.F55SO2Dosage} unit="ppm" />
              </View>
            </View>
            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <Text>Base</Text>
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.F55BaseType} />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.F55BasePrice} unit={`${currency}/kg`} />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.F55BaseConc} unit="%" />
              </View>
              <View style={styles.gridItem} />
            </View>
            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <Text>Acid</Text>
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.F55AcidType} />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.F55AcidPrice} unit={`${currency}/kg`} />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.F55AcidConc} unit="%" />
              </View>
              <View style={styles.gridItem} />
            </View>
          </>
        )}
        <View>
          <Text style={styles.h3}>Evaporation of separation water</Text>
        </View>
        {renderIfNotUndefined(4, [currentScenario.data.F55SepWaterEvaporatorType])(
          currentScenario.data.F55SepWaterEvaporatorType === 'MVR' ? (
            <>
              <View style={styles.gridRow}>
                <View style={styles.gridItem}>
                  <Text style={styles.tableHeading}>Evaporation type</Text>
                </View>
                <View style={styles.gridItem}>
                  <Text style={styles.tableHeading}>Electricity consumption</Text>
                </View>
                <View style={styles.gridItem}>
                  <Text style={styles.tableHeading}>Electricity price</Text>
                </View>
                <View style={styles.gridItem}>
                  <Text style={styles.tableHeading}>Cost</Text>
                </View>
                <View style={styles.gridItem} />
              </View>
              <View style={styles.gridRow}>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={currentScenario.data.F55SepWaterEvaporatorType} />
                </View>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={currentScenario.data.F55SepWaterElectricityConsumption} />
                </View>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={currentScenario.data.F55SepWaterElectricityPrice} />
                </View>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={calculationResult.F55SepWaterEvaporationCost} unit={`${currency}/kg`} />
                </View>

                <View style={styles.gridItem} />
              </View>
            </>
          ) : (
            <>
              <View style={styles.gridRow}>
                <View style={styles.gridItem}>
                  <Text style={styles.tableHeading}>Evaporation type</Text>
                </View>
                <View style={styles.gridItem}>
                  <Text style={styles.tableHeading}>Effects</Text>
                </View>
                <View style={styles.gridItem}>
                  <Text style={styles.tableHeading}>T steam/t evap</Text>
                </View>
                <View style={styles.gridItem}>
                  <Text style={styles.tableHeading}>Steam price</Text>
                </View>
                <View style={styles.gridItem}>
                  <Text style={styles.tableHeading}>Cost</Text>
                </View>
              </View>
              <View style={styles.gridRow}>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={currentScenario.data.F55SepWaterEvaporatorType} />
                </View>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={currentScenario.data.F55SepWaterEffects} />
                </View>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={calculationResult.F55SepWaterSteamCons} />
                </View>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={currentScenario.data.F55SepWaterSteamPrice} />
                </View>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={calculationResult.F55SepWaterEvaporationCost} unit={`${currency}/kg`} />
                </View>
              </View>
            </>
          )
        )}

        <View>
          <Text style={styles.h3}>Chromatography separation</Text>
        </View>
        {renderIfNotUndefined(4, [
          currentScenario.data.F55DP2PlusRecovery,
          currentScenario.data.F55FructoseRecovery,
          currentScenario.data.F55DilutionWater,
        ])(
          <>
            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <Text style={styles.tableHeading}>DP2+ recovery</Text>
              </View>
              <View style={styles.gridItem}>
                <Text style={styles.tableHeading}>Fructose recovery</Text>
              </View>
              <View style={styles.gridItem}>
                <Text style={styles.tableHeading}>Dilution water</Text>
              </View>
              <View style={styles.gridItem} />
            </View>
            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.F55DP2PlusRecovery} />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.F55FructoseRecovery} />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.F55DilutionWater} />
              </View>
              <View style={styles.gridItem} />
            </View>
          </>
        )}
      </PageWithHeader>
      <PageWithHeader theme={theme}>
        <View>
          <Text style={styles.h3}>Reference mass balance</Text>
        </View>

        {renderIfNotUndefined(3, [
          calculationResult.F55RefMassBalance,
          currentScenario.data.F55Capacity,
          currentScenario.data.refDX,
          currentScenario.data.refFxProcentageIso,
          currentScenario.data.refFxProcentageF55,
          currentScenario.data.refFxProcentageMixing2,
        ])(<PDFMassBalanceTable styles={styles} rowData={refMassRows} colLabels={colLabels} />)}

        <View>
          <Text style={styles.h3}>New mass balance</Text>
        </View>
        {renderIfNotUndefined(3, [
          calculationResult.F55NewMassBalance,
          currentScenario.data.F55Capacity,
          currentScenario.data.newDX,
          calculationResult.F55NewMassBalance.procentageFxIso,
          currentScenario.data.refFxProcentageF55,
          currentScenario.data.refFxProcentageMixing2,
        ])(<PDFMassBalanceTable styles={styles} rowData={newMassRows} colLabels={colLabels} />)}
      </PageWithHeader>
      <PageWithHeader theme={theme}>
        <View>
          <Text style={styles.h3}>Evaporation of sweet water</Text>
        </View>
        {renderIfNotUndefined(4, [currentScenario.data.F55SweetWaterEvaporatorType])(
          currentScenario.data.F55SweetWaterEvaporatorType === 'MVR' ? (
            <>
              <View style={styles.gridRow}>
                <View style={styles.gridItem}>
                  <Text style={styles.tableHeading}>Evaporation type</Text>
                </View>
                <View style={styles.gridItem}>
                  <Text style={styles.tableHeading}>Electricity consumption</Text>
                </View>
                <View style={styles.gridItem}>
                  <Text style={styles.tableHeading}>Electricity price</Text>
                </View>
                <View style={styles.gridItem}>
                  <Text style={styles.tableHeading}>Cost</Text>
                </View>
                <View style={styles.gridItem} />
              </View>
              <View style={styles.gridRow}>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={currentScenario.data.F55SweetWaterEvaporatorType} />
                </View>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={currentScenario.data.F55SweetWaterElectricityConsumption} />
                </View>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={currentScenario.data.F55SweetWaterElectricityPrice} />
                </View>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={calculationResult.F55SweetWaterEvaporationCost} unit={`${currency}/kg`} />
                </View>

                <View style={styles.gridItem} />
              </View>
            </>
          ) : (
            <>
              <View style={styles.gridRow}>
                <View style={styles.gridItem}>
                  <Text style={styles.tableHeading}>Evaporation type</Text>
                </View>
                <View style={styles.gridItem}>
                  <Text style={styles.tableHeading}>Effects</Text>
                </View>
                <View style={styles.gridItem}>
                  <Text style={styles.tableHeading}>T steam/t evap</Text>
                </View>
                <View style={styles.gridItem}>
                  <Text style={styles.tableHeading}>Steam price</Text>
                </View>
                <View style={styles.gridItem}>
                  <Text style={styles.tableHeading}>Cost</Text>
                </View>
              </View>
              <View style={styles.gridRow}>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={currentScenario.data.F55SweetWaterEvaporatorType} />
                </View>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={currentScenario.data.F55SweetWaterEffects} />
                </View>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={calculationResult.F55SweetWaterSteamCons} />
                </View>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={currentScenario.data.F55SweetWaterSteamPrice} />
                </View>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={calculationResult.F55SweetWaterEvaporationCost} unit={`${currency}/kg`} />
                </View>
              </View>
            </>
          )
        )}
        {renderIfNotUndefined(4, [
          currentScenario.data.F55IXOperationCat,
          currentScenario.data.F55IXOperationAn,
          currentScenario.data.F55IXResinCapCat,
          currentScenario.data.F55IXResinCapAn,
          currentScenario.data.F55IXResinLifeCat,
          currentScenario.data.F55IXResinLifeAn,
          currentScenario.data.F55IXResinPriceCat,
          currentScenario.data.F55IXResinPriceAn,
          currentScenario.data.F55IXRegenChemCat,
          currentScenario.data.F55IXRegenChemAn,
          currentScenario.data.F55IXChemPriceCat,
          currentScenario.data.F55IXChemPriceAn,
          currentScenario.data.F55IXChemConcentrationCat,
          currentScenario.data.F55IXChemConcentrationAn,
          currentScenario.data.F55IXChemConsumpCat,
          currentScenario.data.F55IXChemConsumpAn,
          currentScenario.data.F55IXWaterCat,
          currentScenario.data.F55IXWaterAn,
          currentScenario.data.F55IXSweetWaterDilCat,
          currentScenario.data.F55IXSweetWaterDilAn,
          currentScenario.data.F55IXWasteWaterCat,
          currentScenario.data.F55IXWasteWaterAn,
          currentScenario.data.F55IXCrossRegenCat,
          currentScenario.data.F55IXCrossRegenAn,
          currentScenario.data.F55IXWaterPrice,
          currentScenario.data.F55IXWasteWaterCost,
        ])(
          <>
            <View>
              <Text style={styles.h3}>Ion exchange data</Text>
            </View>
            <View style={styles.gridRow}>
              <View
                style={{
                  flex: 1,
                }}
              >
                <View style={styles.gridRow}>
                  <View style={styles.gridItem}>
                    <Text style={styles.tableHeading}>IX Input data</Text>
                  </View>
                  <View style={styles.gridItem}>
                    <Text style={styles.tableHeading}>Cation</Text>
                  </View>
                  <View style={styles.gridItem}>
                    <Text style={styles.tableHeading}>Anion</Text>
                  </View>
                </View>

                <View style={styles.gridRow}>
                  <View style={styles.gridItem}>
                    <Text>Operation</Text>
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight value={currentScenario.data.F55IXOperationCat} />
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight value={currentScenario.data.F55IXOperationAn} />
                  </View>
                </View>
                <View style={styles.gridRow}>
                  <View style={styles.gridItem}>
                    <Text>Resin capacity</Text>
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight value={currentScenario.data.F55IXResinCapCat} unit="eq/l resin" />
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight value={currentScenario.data.F55IXResinCapAn} unit="eq/l resin" />
                  </View>
                </View>
                <View style={styles.gridRow}>
                  <View style={styles.gridItem}>
                    <Text>Resin lifetime</Text>
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight value={currentScenario.data.F55IXResinLifeCat} unit="cycles" />
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight value={currentScenario.data.F55IXResinLifeAn} unit="cycles" />
                  </View>
                </View>
                <View style={styles.gridRow}>
                  <View style={styles.gridItem}>
                    <Text>Resin price</Text>
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight value={currentScenario.data.F55IXResinPriceCat} unit={`${currency}/l resin`} />
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight value={currentScenario.data.F55IXResinPriceAn} unit={`${currency}/l resin`} />
                  </View>
                </View>
                <View style={styles.gridRow}>
                  <View style={styles.gridItem}>
                    <Text>Regeneration chemical</Text>
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight value={currentScenario.data.F55IXRegenChemCat} />
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight value={currentScenario.data.F55IXRegenChemAn} />
                  </View>
                </View>
                <View style={styles.gridRow}>
                  <View style={styles.gridItem}>
                    <Text>Chemical price</Text>
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight value={currentScenario.data.F55IXChemPriceCat} unit={`${currency}/kg`} />
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight value={currentScenario.data.F55IXChemPriceAn} unit={`${currency}/kg`} />
                  </View>
                </View>
                <View style={styles.gridRow}>
                  <View style={styles.gridItem}>
                    <Text>Chemical concentration</Text>
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight value={currentScenario.data.F55IXChemConcentrationCat} unit="% (w/w)" />
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight value={currentScenario.data.F55IXChemConcentrationAn} unit="% (w/w)" />
                  </View>
                </View>
                <View style={styles.gridRow}>
                  <View style={styles.gridItem}>
                    <Text>Chemical consumption</Text>
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight value={currentScenario.data.F55IXChemConsumpCat} unit="eq/l resin" />
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight value={currentScenario.data.F55IXChemConsumpAn} unit="eq/l resin" />
                  </View>
                </View>
                <View style={styles.gridRow}>
                  <View style={styles.gridItem}>
                    <Text>Water</Text>
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight value={currentScenario.data.F55IXWaterCat} unit="BV/reg." />
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight value={currentScenario.data.F55IXWaterAn} unit="BV/reg." />
                  </View>
                </View>
              </View>

              <View
                style={{
                  flex: 1,
                }}
              >
                <View style={styles.gridRow}>
                  <View style={styles.gridItem}>
                    <Text style={styles.tableHeading}>IX Input data</Text>
                  </View>
                  <View style={styles.gridItem}>
                    <Text style={styles.tableHeading}>Cation</Text>
                  </View>
                  <View style={styles.gridItem}>
                    <Text style={styles.tableHeading}>Anion</Text>
                  </View>
                </View>
                <View style={styles.gridRow}>
                  <View style={styles.gridItem}>
                    <Text>Sweet water dilution</Text>
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight value={currentScenario.data.F55IXSweetWaterDilCat} unit="BV/reg." />
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight value={currentScenario.data.F55IXSweetWaterDilAn} unit="BV/reg." />
                  </View>
                </View>
                <View style={styles.gridRow}>
                  <View style={styles.gridItem}>
                    <Text>Waste water</Text>
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight value={currentScenario.data.F55IXWasteWaterCat} unit="BV/reg." />
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight value={currentScenario.data.F55IXWasteWaterAn} unit="BV/reg." />
                  </View>
                </View>
                <View style={styles.gridRow}>
                  <View style={styles.gridItem}>
                    <Text>Cross regeneration</Text>
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight value={currentScenario.data.F55IXCrossRegenCat} unit="cycles" />
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight value={currentScenario.data.F55IXCrossRegenAn} unit="cycles" />
                  </View>
                </View>
                <View style={styles.gridRow}>
                  <View style={styles.gridItem}>
                    <Text>Water price</Text>
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight value={currentScenario.data.F55IXWaterPrice} unit={`${currency}/m3`} />
                  </View>
                  <View style={styles.gridItem} />
                </View>
                <View style={styles.gridRow}>
                  <View style={styles.gridItem}>
                    <Text>Waste water cost</Text>
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight value={currentScenario.data.F55IXWasteWaterCost} unit={`${currency}/m3`} />
                  </View>
                  <View style={styles.gridItem} />
                </View>
              </View>
            </View>
          </>
        )}
      </PageWithHeader>
    </>
  );
};

export default F55PDF;
