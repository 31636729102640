import { Box, Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import ValueHighlight from 'components/ValueHighlight';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { ProductionSteps } from 'scenario/ScenarioType';
import getAtomState from 'state/atomState';
import { currency, F55IXReductionSavingsState, F55IXRegenSavingsState } from 'state/selectors';
import { isF55Disabled } from 'utils/isProcessStepDisabled';

const useStyles = makeStyles(() => ({
  flexGrow: {
    flex: '2 1 0',
  },
  removeMargin: {
    margin: 0,
  },
}));

const IonExchangeSavings = (): ReactElement => {
  const activeCurrency = useRecoilValue(currency);
  const { t } = useTranslation('ionSavings');
  const classes = useStyles();

  const productionSteps = useRecoilValue(getAtomState<ProductionSteps>('productionSteps'));
  const f55Disabled = isF55Disabled(productionSteps);

  const regenSavings = useRecoilValue(F55IXRegenSavingsState);
  const reducSavings = useRecoilValue(F55IXReductionSavingsState);

  const regenUnit = `${activeCurrency} / eq`;
  const reducUnit = `${activeCurrency} / day`;

  if (f55Disabled) {
    return <></>;
  }

  return (
    <>
      <Typography variant="h2">{t('title')}</Typography>

      <Box mb={4} />

      <Grid container alignItems="center" spacing={9}>
        <Grid item className={classes.flexGrow}>
          <Grid item xs={12}>
            <Typography variant="h5">{t('regenCost')}</Typography>
            <Box mb={4} />
          </Grid>

          <Grid container spacing={2} alignItems="center">
            <Grid item xs={4} />
            <Grid item xs={4}>
              <Typography variant="h5">{t('cation')}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h5">{t('anion')}</Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1">{t('chem')}</Typography>
            </Grid>
            <Grid item xs={4}>
              <ValueHighlight value={regenSavings?.chemCatSavings} unit={regenUnit} />
            </Grid>
            <Grid item xs={4}>
              <ValueHighlight value={regenSavings?.chemAnSavings} unit={regenUnit} />
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1">{t('wasteWater')}</Typography>
            </Grid>
            <Grid item xs={4}>
              <ValueHighlight value={regenSavings?.wasteWaterCatSavings} unit={regenUnit} />
            </Grid>
            <Grid item xs={4}>
              <ValueHighlight value={regenSavings?.wasteWaterAnSavings} unit={regenUnit} />
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1">{t('water')}</Typography>
            </Grid>
            <Grid item xs={4}>
              <ValueHighlight value={regenSavings?.waterCatSavings} unit={regenUnit} />
            </Grid>
            <Grid item xs={4}>
              <ValueHighlight value={regenSavings?.waterAnSavings} unit={regenUnit} />
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1">{t('resin')}</Typography>
            </Grid>
            <Grid item xs={4}>
              <ValueHighlight value={regenSavings?.resinCatSavings} unit={regenUnit} />
            </Grid>
            <Grid item xs={4}>
              <ValueHighlight value={regenSavings?.resinAnSavings} unit={regenUnit} />
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1">{t('sweatWater')}</Typography>
            </Grid>
            <Grid item xs={4}>
              <ValueHighlight value={regenSavings?.sweatWaterCatSavings} unit={regenUnit} />
            </Grid>
            <Grid item xs={4}>
              <ValueHighlight value={regenSavings?.sweatWaterAnSavings} unit={regenUnit} />
            </Grid>

            <Grid item xs={4}>
              <Typography variant="h5" className={classes.removeMargin}>
                {t('total')}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <ValueHighlight value={regenSavings?.totalCatSavings} unit={regenUnit} highlight />
            </Grid>
            <Grid item xs={4}>
              <ValueHighlight value={regenSavings?.totalAnSavings} unit={regenUnit} highlight />
            </Grid>
          </Grid>
        </Grid>

        <Divider orientation="vertical" flexItem />

        <Grid item className={classes.flexGrow}>
          <Grid item xs={12}>
            <Typography variant="h5">{t('reductionLoad')}</Typography>
            <Box mb={4} />
          </Grid>

          <Grid container spacing={2} alignItems="center">
            <Grid item xs={4} />
            <Grid item xs={4}>
              <Typography variant="h5">{t('cation')}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h5">{t('anion')}</Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1">{t('mag')}</Typography>
            </Grid>
            <Grid item xs={4}>
              <ValueHighlight value={reducSavings?.magnesiumCatReduction} unit={reducUnit} />
            </Grid>
            <Grid item xs={4}>
              <ValueHighlight value={reducSavings?.magnesiumAnReduction} unit={reducUnit} />
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1">{t('sodium')}</Typography>
            </Grid>
            <Grid item xs={4}>
              <ValueHighlight value={reducSavings?.so2CatReduction} unit={reducUnit} />
            </Grid>
            <Grid item xs={4}>
              <ValueHighlight value={reducSavings?.so2AnReduction} unit={reducUnit} />
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1">{t('base')}</Typography>
            </Grid>
            <Grid item xs={4}>
              <ValueHighlight value={reducSavings?.baseCatEqReduction} unit={reducUnit} />
            </Grid>
            <Grid item xs={4}>
              <ValueHighlight value={reducSavings?.baseAnEqReduction} unit={reducUnit} />
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1">{t('acid')}</Typography>
            </Grid>
            <Grid item xs={4}>
              <ValueHighlight value={reducSavings?.acidCatEqReduction} unit={reducUnit} />
            </Grid>
            <Grid item xs={4}>
              <ValueHighlight value={reducSavings?.acidAnEqReduction} unit={reducUnit} />
            </Grid>

            <Grid item xs={4}>
              <Typography variant="h5" className={classes.removeMargin}>
                {t('total')}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <ValueHighlight value={reducSavings?.totalCatEqReduction} unit={reducUnit} highlight />
            </Grid>
            <Grid item xs={4}>
              <ValueHighlight value={reducSavings?.totalAnEqReduction} unit={reducUnit} highlight />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default IonExchangeSavings;
