import React, { ReactElement } from 'react';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ProductionSteps } from 'scenario/ScenarioType';
import useInputWithState from 'state/useInputWithState';

const useStyles = makeStyles(() => ({
  label: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const BasicProcessDetails = (): ReactElement => {
  const [, saccCapacityInput] = useInputWithState({
    key: 'saccCapacity',
    unit: 'tons DS/day',
  });
  const [, productionStepsInput] = useInputWithState({
    key: 'productionSteps',
    type: 'Select',
    selectOptions: Object.keys(ProductionSteps).map((enumKey) => ({
      value: ProductionSteps[enumKey as keyof typeof ProductionSteps],
      label: ProductionSteps[enumKey as keyof typeof ProductionSteps],
    })),
  });

  const classes = useStyles();

  const { t } = useTranslation('processDetails');

  return (
    <>
      <Box mb={2}>
        <Typography variant="h5">{t('title')}</Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={2} className={classes.label}>
          <Typography variant="body1">{t('saccCapacity')}</Typography>
        </Grid>
        <Grid item xs={2}>
          {saccCapacityInput}
        </Grid>
        <Grid item xs={2} className={classes.label}>
          <Typography variant="body1">{t('productionSteps')}</Typography>
        </Grid>
        <Grid item xs={2}>
          {productionStepsInput}
        </Grid>
      </Grid>
    </>
  );
};

export default BasicProcessDetails;
