import DMH from 'pages/DMH';
import Evaporation from 'pages/Evaporation';
import Savings from 'pages/Savings';
import SetScenario from 'pages/SetScenario';
import React, { ReactElement } from 'react';

import F55 from 'pages/F55';
import { useParams } from 'react-router-dom';
import { ScenarioParams, ProductionSteps } from 'scenario/ScenarioType';
import { selectorFamily } from 'recoil';
import getAtomState from 'state/atomState';
import { isF55Disabled, isDMHDisabled } from 'utils/isProcessStepDisabled';
import { RouteIndex } from './navigationTypes';

interface ProcessStep {
  title: string;
  url: string;
  component: ReactElement;
  checkDisabled: (params?: any) => boolean;
}

export const PROCESS_STEPS: ProcessStep[] = [
  {
    title: '1. Set scenario',
    url: 'set-scenario',
    component: <SetScenario />,
    checkDisabled: (): boolean => false,
  },
  {
    title: '2. Evaporation',
    url: 'evaporation',
    component: <Evaporation />,
    checkDisabled: (): boolean => false,
  },
  {
    title: '3a. DMH',
    url: 'dmh',
    component: <DMH />,
    checkDisabled: isDMHDisabled,
  },
  {
    title: '3b. F55',
    url: 'f55',
    component: <F55 />,
    checkDisabled: isF55Disabled,
  },
  {
    title: '4. Your savings',
    url: 'your-savings',
    component: <Savings />,
    checkDisabled: (): boolean => false,
  },
];

export const getRouteIndex = (currentRoute: string): RouteIndex | undefined => {
  const index = PROCESS_STEPS.findIndex((step) => currentRoute.startsWith(`/step/${step.url}`));
  if (index !== -1) {
    return index as RouteIndex;
  }
};

export const getPrevRoute = (currentRoute: string): string | undefined => {
  const currentIndex = getRouteIndex(currentRoute);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const params = useParams<ScenarioParams>();
  if (currentIndex === undefined || currentIndex < 1) {
    return undefined;
  }
  return `/step/${PROCESS_STEPS[currentIndex - 1].url}${params.scenarioId ? `/${params.scenarioId}` : ''}`;
};

export const getCurrentRoute = (currentRoute: string): string | undefined => {
  const currentIndex = getRouteIndex(currentRoute);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const params = useParams<ScenarioParams>();
  if (currentIndex === undefined) {
    return undefined;
  }
  return `/step/${PROCESS_STEPS[currentIndex].url}${params.scenarioId ? `/${params.scenarioId}` : ''}`;
};

export const isCurrentRouteActive =
  (currentRoute: string) =>
  (currentPath: string): boolean =>
    getRouteIndex(currentRoute) === getRouteIndex(currentPath);

export const nextRouteState = selectorFamily<string | undefined, string>({
  key: 'nextRoute',
  get:
    (currentRoute: string) =>
    ({ get }): string | undefined => {
      const currentIndex = getRouteIndex(currentRoute);
      const params = useParams<ScenarioParams>();
      if (currentIndex === PROCESS_STEPS.length - 1) {
        return undefined;
      }
      const productionSteps = get(getAtomState<ProductionSteps>('productionSteps'));
      const nextIndex = currentIndex === undefined ? 0 : currentIndex + 1;
      const forwardRoutes = PROCESS_STEPS.slice(nextIndex);
      const nextRoute = forwardRoutes.find((step) => !step.checkDisabled(productionSteps));

      return nextRoute && `/step/${nextRoute.url}${params.scenarioId ? `/${params.scenarioId}` : ''}`;
    },
});

export const prevRouteState = selectorFamily<string | undefined, string>({
  key: 'prevRoute',
  get:
    (currentRoute: string) =>
    ({ get }): string | undefined => {
      const currentIndex = getRouteIndex(currentRoute);
      const params = useParams<ScenarioParams>();
      if (currentIndex === undefined || currentIndex < 1) {
        return undefined;
      }
      const productionSteps = get(getAtomState<ProductionSteps>('productionSteps'));

      const backwardRoutes = PROCESS_STEPS.slice(undefined, currentIndex).reverse();
      const prevRoute = backwardRoutes.find((step) => !step.checkDisabled(productionSteps));

      return prevRoute && `/step/${prevRoute.url}${params.scenarioId ? `/${params.scenarioId}` : ''}`;
    },
});

export default {};
