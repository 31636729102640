import { Acid, Base, Magnesium, So2Source } from '../scenario/ScenarioType';
import { AcidEq, BaseEq, MAGNESIUM_WEIGHT_EQ, SO2_WEIGHT_EQ } from './ChemicalConstants';
import { eqAcid, eqBase, mgContent, so2Content } from './helpers/chemicalContent';

export interface IonExRegenerationSavingsInput {
  resinCatPrice: number;
  resinAnPrice: number;
  resinCatExchangeCapacity: number;
  resinAnExchangeCapacity: number;
  resinCatLifetime: number;
  resinAnLifetime: number;

  acidName: Acid;
  acidPrice: number;
  acidConcentration: number;
  acidConsumption: number;

  baseName: Base;
  basePrice: number;
  baseConcentration: number;
  baseConsumption: number;

  wasteWaterPrice: number;
  wasteWaterCat: number;
  wasteWaterAn: number;

  waterPrice: number;
  waterCat: number;
  waterAn: number;

  sweatWaterEvaporationCost: number;
  sweatWaterDilutionCat: number;
  sweatWaterDilutionAn: number;
}

export interface IonExRegerationSavingsOutput {
  resinCatSavings: number;
  resinAnSavings: number;
  chemCatSavings: number;
  chemAnSavings: number;
  wasteWaterCatSavings: number;
  wasteWaterAnSavings: number;
  waterCatSavings: number;
  waterAnSavings: number;
  sweatWaterCatSavings: number;
  sweatWaterAnSavings: number;
  totalCatSavings: number;
  totalAnSavings: number;
}

export const IonExRegenerationSavings = (
  input: IonExRegenerationSavingsInput
): IonExRegerationSavingsOutput | undefined => {
  // IX costs per eq exchanged
  // Resins
  const resinCatSavings = input.resinCatPrice / (input.resinCatExchangeCapacity * input.resinCatLifetime);
  const resinAnSavings = input.resinAnPrice / (input.resinAnExchangeCapacity * input.resinAnLifetime);

  const acidEq = AcidEq(input.acidName);
  const baseEq = BaseEq(input.baseName);

  if (!acidEq || !baseEq) {
    return undefined;
  }

  // Chemicals
  const chemCatSavings =
    ((input.acidPrice * (100 / input.acidConcentration)) / acidEq) *
    (input.acidConsumption / input.resinCatExchangeCapacity);
  const chemAnSavings =
    ((input.basePrice * (100 / input.baseConcentration)) / baseEq) *
    (input.baseConsumption / input.resinAnExchangeCapacity);

  // Waste water
  const wasteWaterCatSavings = (input.wasteWaterPrice / 1000) * (input.wasteWaterCat / input.resinCatExchangeCapacity);
  const wasteWaterAnSavings = (input.wasteWaterPrice / 1000) * (input.wasteWaterAn / input.resinAnExchangeCapacity);

  // Water
  const waterCatSavings = (input.waterPrice / 1000) * (input.waterCat / input.resinCatExchangeCapacity);
  const waterAnSavings = (input.waterPrice / 1000) * (input.waterAn / input.resinAnExchangeCapacity);

  // Sweet water dilution
  const sweatWaterCatSavings =
    (input.sweatWaterEvaporationCost / 1000) * (input.sweatWaterDilutionCat / input.resinCatExchangeCapacity);
  const sweatWaterAnSavings =
    (input.sweatWaterEvaporationCost / 1000) * (input.sweatWaterDilutionAn / input.resinAnExchangeCapacity);

  // Total
  const totalCatSavings =
    resinCatSavings + chemCatSavings + wasteWaterCatSavings + waterCatSavings + sweatWaterCatSavings;
  const totalAnSavings = resinAnSavings + chemAnSavings + wasteWaterAnSavings + waterAnSavings + sweatWaterAnSavings;

  return {
    resinCatSavings,
    resinAnSavings,
    chemCatSavings,
    chemAnSavings,
    wasteWaterCatSavings,
    wasteWaterAnSavings,
    waterCatSavings,
    waterAnSavings,
    sweatWaterCatSavings,
    sweatWaterAnSavings,
    totalCatSavings,
    totalAnSavings,
  };
};

export interface IonExReductionIXReductionInput {
  dailyDSMixing1New: number;
  dailyDSMixing1Ref: number;
  drySolidsref: number;

  magnesiumName: Magnesium;
  magnesiumDosage: number;

  so2SourceName: So2Source;
  so2SourceDosage: number;
}

export interface IonExReductionIXReductionOutput {
  magnesiumCatReduction: number;
  magnesiumAnReduction: number;
  so2CatReduction: number;
  so2AnReduction: number;
  baseCatEqReduction: number;
  baseAnEqReduction: number;
  acidAnEqReduction: number;
  acidCatEqReduction: number;
  totalCatEqReduction: number;
  totalAnEqReduction: number;
}

export const IonExReductionIXReduction = (input: IonExReductionIXReductionInput): IonExReductionIXReductionOutput => {
  // Ion exchange loads
  // IX load from Magnesium addition (MgSO4*7 H2O or Mg(HSO3)2)
  const magnesiumCatReduction =
    (input.magnesiumDosage * 10 ** -6 * (input.dailyDSMixing1Ref - input.dailyDSMixing1New) * 1000 * 100) /
    input.drySolidsref /
    MAGNESIUM_WEIGHT_EQ;
  const magnesiumAnReduction =
    (input.magnesiumDosage * 10 ** -6 * (input.dailyDSMixing1Ref - input.dailyDSMixing1New) * 1000 * 100) /
    input.drySolidsref /
    MAGNESIUM_WEIGHT_EQ;

  // IX load from SO2 addition (NaHSO3)
  const so2CatReduction =
    input.magnesiumName === Magnesium['MgSO4*7H2O']
      ? (input.so2SourceDosage * 10 ** -6 * (input.dailyDSMixing1Ref - input.dailyDSMixing1New) * 1000 * 100) /
        input.drySolidsref /
        SO2_WEIGHT_EQ
      : 0;
  const so2AnReduction =
    input.magnesiumName === Magnesium['MgSO4*7H2O']
      ? (input.so2SourceDosage * 10 ** -6 * (input.dailyDSMixing1Ref - input.dailyDSMixing1New) * 1000 * 100) /
        input.drySolidsref /
        SO2_WEIGHT_EQ
      : 0;

  // IX load from isomerisation base (NaOH or Na2CO3) - NOTE: Only Na2CO3 is an option meaning the NaOH if cases have been removed
  const baseCatEqReduction =
    (input.magnesiumName === Magnesium['MgSO4*7H2O'] ? so2CatReduction : magnesiumAnReduction) * 2;
  const baseAnEqReduction = 0; // hydroxides gives zero load and carbonate is assumed to give zero load

  // IX load from isomerisation acid, assuming that pH is lowered after isomerisation
  // NOTE: Only Na2CO3 is an option meaning the NaOH if cases have been removed
  const acidAnEqReduction =
    input.magnesiumName === Magnesium['MgSO4*7H2O']
      ? so2CatReduction * 2
      : magnesiumCatReduction + magnesiumAnReduction;
  const acidCatEqReduction = 0;

  // Total IX load from isomerisation and chromatograhic separation
  const totalCatEqReduction = magnesiumCatReduction + so2CatReduction + baseCatEqReduction + acidCatEqReduction;
  const totalAnEqReduction = magnesiumAnReduction + so2AnReduction + baseAnEqReduction + acidAnEqReduction;

  return {
    magnesiumCatReduction,
    magnesiumAnReduction,
    so2CatReduction,
    so2AnReduction,
    baseCatEqReduction,
    baseAnEqReduction,
    acidAnEqReduction,
    acidCatEqReduction,
    totalCatEqReduction,
    totalAnEqReduction,
  };
};

export interface IonExSavingsIsoInput {
  totalCatEqReduction: number;
  totalAnEqReduction: number;
  resinCatSavings: number;
  resinAnSavings: number;
  chemCatSavings: number;
  chemAnSavings: number;
  wasteWaterCatSavings: number;
  wasteWaterAnSavings: number;
  waterCatSavings: number;
  waterAnSavings: number;
  sweatWaterCatSavings: number;
  sweatWaterAnSavings: number;
  refDailyDSMixing1: number;
  newDailyDSMixing1: number;
  mgType: Magnesium;
  mgDosage: number;
  mgPrice: number;
  mgConc: number;
  F55RefDS: number;
  so2SourceDosage: number;
  so2SourcePrice: number;
  so2SourceConc: number;
  so2Type: So2Source;
  baseCatEqReduction: number;
  basePrice: number;
  baseConc: number;
  baseType: Base;
  baseAnEqReduction: number;
  acidPrice: number;
  acidConc: number;
  acidType: Acid;
}

export interface IonExSavingsIsoOutput {
  dailyTotalChemSavingsF55: number;
  totalDailySavingsF55: number;
}

export const IonExSavingsIso = ({
  totalCatEqReduction,
  totalAnEqReduction,
  resinCatSavings,
  resinAnSavings,
  chemCatSavings,
  chemAnSavings,
  wasteWaterCatSavings,
  wasteWaterAnSavings,
  waterCatSavings,
  waterAnSavings,
  sweatWaterCatSavings,
  sweatWaterAnSavings,
  refDailyDSMixing1,
  newDailyDSMixing1,
  mgDosage,
  mgPrice,
  mgConc,
  mgType,
  F55RefDS,
  so2SourceDosage,
  so2SourcePrice,
  so2SourceConc,
  so2Type,
  baseCatEqReduction,
  basePrice,
  baseConc,
  baseType,
  baseAnEqReduction,
  acidPrice,
  acidConc,
  acidType,
}: IonExSavingsIsoInput): IonExSavingsIsoOutput => {
  // Resin saving
  const resingDailySavingsIX = totalCatEqReduction * resinCatSavings + totalAnEqReduction * resinAnSavings;

  // Regeneration chemicals saving
  const dailyChemSavingsIX = totalCatEqReduction * chemCatSavings + totalAnEqReduction * chemAnSavings;

  // Waste water saving
  const daiyWasteWaterSavingsIX = totalCatEqReduction * wasteWaterCatSavings + totalAnEqReduction * wasteWaterAnSavings;

  // Water saving
  const dailyWaterSavingsIX = totalCatEqReduction * waterCatSavings + totalAnEqReduction * waterAnSavings;

  // Sweet water saving in m3 per day
  const dailySweetWaterSavingsIX =
    totalCatEqReduction * sweatWaterCatSavings + totalAnEqReduction * sweatWaterAnSavings;

  // Magnesium saving
  const dailyMagnesiumSavingsF55 =
    ((mgDosage * 100) / F55RefDS) *
    10 ** -6 *
    ((refDailyDSMixing1 - newDailyDSMixing1) * 1000) *
    ((mgPrice * (100 / mgConc)) / mgContent[mgType]);

  // SO2 saving
  const dailySO2SavingsF55 =
    ((so2SourceDosage * 100) / F55RefDS) *
    10 ** -6 *
    ((refDailyDSMixing1 - newDailyDSMixing1) * 1000) *
    ((so2SourcePrice * (100 / so2SourceConc)) / so2Content[so2Type]);

  // pH adjustment base saving
  const dailyBaseSavingsF55 = baseCatEqReduction * ((basePrice * (100 / baseConc)) / eqBase[baseType]);

  // pH adjustment acid saving
  const dailyAcidSavingsF55 = baseAnEqReduction * ((acidPrice * (100 / acidConc)) / eqAcid[acidType]);

  // Total isomerisation chemicals and IX saving for F55
  const dailyTotalChemSavingsF55 =
    dailyMagnesiumSavingsF55 + dailySO2SavingsF55 + dailyBaseSavingsF55 + dailyAcidSavingsF55;
  const totalDailySavingsF55 =
    resingDailySavingsIX +
    dailyChemSavingsIX +
    daiyWasteWaterSavingsIX +
    dailyWaterSavingsIX +
    dailySweetWaterSavingsIX +
    dailyMagnesiumSavingsF55 +
    dailySO2SavingsF55 +
    dailyBaseSavingsF55 +
    dailyAcidSavingsF55;

  return { dailyTotalChemSavingsF55, totalDailySavingsF55 };
};
