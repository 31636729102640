import { F55SeparationMassBalanceOutput, F55SeparationRefMassBalanceInput } from './F55Types';
import K from './helpers/K';
import XE from './helpers/XE';

const F55SeparationRefMassBalance = ({
  saccDx,
  fluctoseRecovery,
  refIsoInletTemp,
  DP2PlusRecovery,
  dailyDSF55,
  procentageFxF55,
  dailyDSSacc,
  procentageFxIso,
  procentageFxMixing2,
}: F55SeparationRefMassBalanceInput): F55SeparationMassBalanceOutput => {
  const procentageDP1F55 = saccDx;
  const procentageDP1Sacc = saccDx;

  const procentageDP2PlusF55 = 100 - procentageDP1F55;
  const dailyDP2PlusF55 = (procentageDP2PlusF55 * dailyDSF55) / 100;
  const dailyFxF55 = (procentageFxF55 * dailyDSF55) / 100;
  const procentageDP2PlusSacc = 100 - procentageDP1Sacc;
  const dailyDP2PlusSacc = dailyDP2PlusF55;
  const procentageFxSacc = 0;
  const dailyFxSacc = 0;
  const procentageFxF42 = procentageFxIso;

  const dailyDSMixing2 = ((procentageFxF55 - procentageFxF42) * dailyDSF55) / (procentageFxMixing2 - procentageFxF42);
  const dailyFxMixing2 = (dailyDSMixing2 * procentageFxMixing2) / 100;
  const dailyDSF42 = dailyDSF55 - dailyDSMixing2;

  const dailyFxF42 = (dailyDSF42 * procentageFxF42) / 100;
  const dailyFxIso = (dailyFxMixing2 * 100) / fluctoseRecovery;
  const dailyFxChrom = dailyFxMixing2 - dailyFxIso;

  const dailyFxMixing1 = -dailyFxChrom;
  const dailyDSIso = (dailyFxIso * 100) / procentageFxIso;
  const dailyDSChrom = dailyDSMixing2 - dailyDSIso;

  const dailyDSMixing1 = dailyDSSacc - dailyDSChrom;
  const procentageFxMixing1 = (dailyFxMixing1 * 100) / dailyDSMixing1;
  const procentageFxChrom = (dailyFxChrom * 100) / dailyDSChrom;

  const dailyDP2PlusF42 =
    (dailyDSF42 * dailyDP2PlusSacc) /
    ((dailyDSIso * DP2PlusRecovery) / 100) /
    (1 + dailyDSF42 / ((dailyDSIso * DP2PlusRecovery) / 100));

  const dailyDP2PlusMixing2 = dailyDP2PlusF55 - dailyDP2PlusF42;
  const procentageDP2PlusMixing2 = (dailyDP2PlusMixing2 * 100) / dailyDSMixing2;

  const procentageDP1Mixing2 = 100 - procentageDP2PlusMixing2;
  const procentageDP2PlusF42 = (dailyDP2PlusF42 * 100) / dailyDSF42;
  const procentageDP1F42 = 100 - procentageDP2PlusF42;
  const dailyDP2PlusChrom = (-dailyDP2PlusMixing2 * (100 - DP2PlusRecovery)) / DP2PlusRecovery;
  const procentageDP2PlusChrom = (dailyDP2PlusChrom * 100) / dailyDSChrom;
  const procentageDP1Chrom = 100 - procentageDP2PlusChrom;
  const dailyDP2PlusIso = -dailyDP2PlusChrom + dailyDP2PlusMixing2;

  const procentageDP2PlusIso = (dailyDP2PlusIso * 100) / dailyDSIso;
  const procentageDP1Iso = 100 - procentageDP2PlusIso;
  const dailyDP2PlusMixing1 = dailyDP2PlusIso + dailyDP2PlusF42;
  const procentageDP2PlusMixing1 = (dailyDP2PlusMixing1 * 100) / dailyDSMixing1;
  const procentageDP1Mixing1 = 100 - procentageDP2PlusMixing1;

  // Calculate New fructose out
  const DP1F55 = procentageDP1Mixing1;
  const XInF55 = procentageFxMixing1 / procentageDP1Mixing1;
  const XoutF55 = procentageFxIso / procentageDP1Mixing1;
  const FlowIsofeedF55 = dailyDSMixing1;

  const LNF55 = Math.log((XE(K(refIsoInletTemp)) - XInF55) / (XE(K(refIsoInletTemp)) - XoutF55));

  return {
    procentageDP2PlusF55,
    dailyDP2PlusF55,
    dailyFxF55,
    procentageDP2PlusSacc,
    dailyDP2PlusSacc,
    procentageFxSacc,
    dailyFxSacc,
    procentageFxF42,
    dailyDSMixing2,
    dailyFxMixing2,
    dailyDSF42,
    dailyFxF42,
    dailyFxIso,
    dailyFxChrom,
    dailyFxMixing1,
    dailyDSIso,
    dailyDSChrom,
    dailyDSMixing1,
    procentageFxMixing1,
    procentageFxChrom,
    dailyDP2PlusF42,
    dailyDP2PlusMixing2,
    procentageDP2PlusMixing2,
    procentageDP1Mixing2,
    procentageDP2PlusF42,
    procentageDP1F42,
    dailyDP2PlusChrom,
    procentageDP2PlusChrom,
    procentageDP1Chrom,
    dailyDP2PlusIso,
    procentageDP2PlusIso,
    procentageDP1Iso,
    dailyDP2PlusMixing1,
    procentageDP2PlusMixing1,
    procentageDP1Mixing1,
    DP1F55,
    XInF55,
    XoutF55,
    FlowIsofeedF55,
    LNF55: Number.isNaN(LNF55) ? 0 : LNF55,
  };
};

export default F55SeparationRefMassBalance;
