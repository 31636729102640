import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Amplify } from '@aws-amplify/core';
import { RecoilRoot } from 'recoil';
import Page from 'components/layout/Page';
import DocumentView from 'components/DocumentView';
import amplifyConfig from 'amplifyConfig';

import StepRouter from 'navigation/StepRouter';
import ErrorBoundary from 'components/ErrorBoundary';
import ScenarioGetter from 'scenario/ScenarioGetter';
import Redirecter from 'Redirecter';
import { AuthEvent, validateState } from '@novozymes-digital/auth';
import { initializeCookieConsent, initializeGTM } from '@novozymes-digital/utils';
import TagManager from 'react-gtm-module';
import Cookies from 'pages/Cookies';
import Privacy from 'pages/Privacy';
import TermsConditions from 'pages/TermsConditions';
import Legal from 'pages/Legal';
import { getUserType } from 'utils/getUser';
import Home from './pages/Home';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'authentication-wrapper': any;
    }
  }
}

Amplify.configure(amplifyConfig);

function App(): ReactElement {
  const [authLoaded, setAuthLoaded] = useState(false);
  const [authStateReturnURL, setAuthStateReturnURL] = useState('');
  const [userType, setUserType] = useState('not-logged-in');

  useEffect(() => {
    // Load cookie consent
    initializeCookieConsent();

    initializeGTM('GTM-KTWCZXC');
  }, []);

  const onAuthEvent = useCallback(async (event) => {
    const authEvent = event as AuthEvent;
    if (authEvent.detail.newAuthState === 'signedIn') {
      setAuthLoaded(true);
      const currentUserType = await getUserType();
      setUserType(currentUserType);
    }
  }, []);
  // eslint-disable-next-line no-undef
  window.addEventListener('AuthStateChange', onAuthEvent);

  useEffect(() => {
    const goTOUrl = validateState();
    if (goTOUrl) {
      setAuthStateReturnURL(goTOUrl);
    }
  }, []);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        user_type: userType,
      },
    });
  }, [userType]);

  return (
    <authentication-wrapper id="authentication" amplifyConfig={JSON.stringify(amplifyConfig)}>
      {authLoaded && authStateReturnURL ? (
        <RecoilRoot>
          <Router>
            <ErrorBoundary>
              <ScenarioGetter>
                <Redirecter redirectTo={authStateReturnURL}>
                  <Switch>
                    <Route path="/step/:stepUrl/:scenarioId?" component={StepRouter} />
                    <Route exact path="/cookies">
                      <Page hideProcessHeader hideFooter isHomePage>
                        <Cookies />
                      </Page>
                    </Route>
                    <Route exact path="/privacy">
                      <Page hideProcessHeader hideFooter isHomePage>
                        <Privacy />
                      </Page>
                    </Route>
                    <Route exact path="/terms">
                      <Page hideProcessHeader hideFooter isHomePage>
                        <TermsConditions />
                      </Page>
                    </Route>
                    <Route exact path="/legal">
                      <Page hideProcessHeader hideFooter isHomePage>
                        <Legal />
                      </Page>
                    </Route>

                    <Route exact path="/docs/:name">
                      <Page hideProcessHeader hideFooter isHomePage>
                        <DocumentView />
                      </Page>
                    </Route>
                    <Route path="/:scenarioId?">
                      <Page>
                        <Home />
                      </Page>
                    </Route>
                  </Switch>
                </Redirecter>
              </ScenarioGetter>
            </ErrorBoundary>
          </Router>
        </RecoilRoot>
      ) : null}
    </authentication-wrapper>
  );
}

export default App;
