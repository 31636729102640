/* eslint-disable max-len */
/* eslint-disable no-tabs */
import React, { ReactElement } from 'react';
import { Link, Typography } from '@material-ui/core';
import { getHomeLink } from 'utils/navigateHome';

const Privacy = (): ReactElement => (
  <>
    <Typography variant="h1">Privacy policy</Typography>
    <Typography variant="body1">
      To read more about the Refinery Optimizers privacy policy go to <Link href={getHomeLink('privacy')}>Privacy</Link>
    </Typography>
  </>
);

export default Privacy;
