import { nextRouteState } from 'navigation/processNavigation';
import React, { ReactElement, ReactNode, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

const Redirecter = ({ redirectTo, children }: { redirectTo?: string; children: ReactNode }): ReactElement => {
  const history = useHistory();
  const location = useLocation();
  const nextRoute = useRecoilValue(nextRouteState(location.pathname));

  useEffect(() => {
    if (redirectTo) {
      const path = location.pathname;
      if (path !== redirectTo) {
        if (redirectTo === '/' && nextRoute) {
          history.replace(nextRoute);
        } else {
          history.replace(redirectTo);
        }
      }
    }
  }, [redirectTo]);

  return <>{children}</>;
};

export default Redirecter;
