import React, { ReactElement, ReactNode } from 'react';
import ReactPDF, { Page, View, Image, Text, StyleSheet } from '@react-pdf/renderer';
import { Theme } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';

const useStyles = (theme: Theme): ReactPDF.Styles =>
  StyleSheet.create({
    header: {
      backgroundColor: theme.palette.secondary.main,
      height: '80px',
      padding: theme.spacing(2),
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: theme.spacing(4),
    },
    logo: {
      width: '130px',
      height: '39px',
    },
    headerText: {
      color: theme.palette.common.white,
      fontSize: 10,
      fontWeight: 400,
      lineHeight: 1.43,
    },
    body: {
      flexGrow: 1,
      paddingHorizontal: theme.spacing(4),
      color: fade(theme.palette.common.black, 0.8),
      fontSize: 10,
    },
  });

const PageWithHeader = ({
  children,
  theme,
  ...restProps
}: {
  children: ReactNode;
  theme: Theme;
} & ReactPDF.PageProps): ReactElement => {
  const styles = useStyles(theme);
  return (
    <Page size="A4" {...restProps}>
      <View fixed style={styles.header}>
        <View>
          <Image src="/logo_white.png" style={styles.logo} />
          <Text style={styles.headerText}>Saccharification Lab</Text>
        </View>

        <Text
          style={styles.headerText}
          fixed
          render={({ pageNumber, totalPages }): string => `${pageNumber} / ${totalPages}`}
        />
      </View>
      <View style={styles.body}>{children}</View>
    </Page>
  );
};

export default PageWithHeader;
