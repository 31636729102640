import React, { ReactElement, ReactNode } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { Acid, Base, Operation } from 'scenario/ScenarioType';
import { currency } from 'state/selectors';
import useInputWithState from 'state/useInputWithState';

interface RowData {
  label: ReactNode;
  cation: ReactNode;
  anion: ReactNode;
}

const ThreeColumnRow = ({ data }: { data: RowData }): ReactElement => (
  <Box mb={1}>
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={4}>
        {data.label}
      </Grid>
      <Grid item xs={4}>
        {data.cation}
      </Grid>
      <Grid item xs={4}>
        {data.anion}
      </Grid>
    </Grid>
  </Box>
);

const F55IonExchange = (): ReactElement => {
  const { t } = useTranslation('F55IonExchange');

  const activeCurrency = useRecoilValue(currency);

  const [, F55IXOperationCatInput] = useInputWithState({
    key: 'F55IXOperationCat',
    type: 'Select',
    selectOptions: Object.keys(Operation).map((enumKey) => ({
      value: enumKey,
      label: Operation[enumKey as keyof typeof Operation],
    })),
  });
  const [, F55IXOperationAnInput] = useInputWithState({
    key: 'F55IXOperationAn',
    type: 'Select',
    selectOptions: Object.keys(Operation).map((enumKey) => ({
      value: enumKey,
      label: Operation[enumKey as keyof typeof Operation],
    })),
  });

  const [, F55IXResinCapCatInput] = useInputWithState({
    key: 'F55IXResinCapCat',
    unit: 'eq / l resin',
  });
  const [, F55IXResinCapAnInput] = useInputWithState({
    key: 'F55IXResinCapAn',
    unit: 'eq / l resin',
  });

  const [, F55IXResinLifeCatInput] = useInputWithState({
    key: 'F55IXResinLifeCat',
    unit: 'cycles',
  });
  const [, F55IXResinLifeAnInput] = useInputWithState({
    key: 'F55IXResinLifeAn',
    unit: 'cycles',
  });

  const [, F55IXResinPriceCatInput] = useInputWithState({
    key: 'F55IXResinPriceCat',
    unit: `${activeCurrency} / l resin`,
  });
  const [, F55IXResinPriceAnInput] = useInputWithState({
    key: 'F55IXResinPriceAn',
    unit: `${activeCurrency} / l resin`,
  });

  const [, F55IXRegenChemCatInput] = useInputWithState({
    key: 'F55IXRegenChemCat',
    type: 'Select',
    selectOptions: Object.keys(Acid).map((enumKey) => ({
      value: enumKey,
      label: Acid[enumKey as keyof typeof Acid],
    })),
  });
  const [, F55IXRegenChemAnInput] = useInputWithState({
    key: 'F55IXRegenChemAn',
    type: 'Select',
    selectOptions: Object.keys(Base).map((enumKey) => ({
      value: enumKey,
      label: Base[enumKey as keyof typeof Base],
    })),
  });

  const [, F55IXChemPriceCatInput] = useInputWithState({
    key: 'F55IXChemPriceCat',
    unit: `${activeCurrency} / kg`,
  });
  const [, F55IXChemPriceAnInput] = useInputWithState({
    key: 'F55IXChemPriceAn',
    unit: `${activeCurrency} / kg`,
  });

  const [, F55IXChemConsumpCatInput] = useInputWithState({
    key: 'F55IXChemConsumpCat',
    unit: 'eq / l resin',
  });
  const [, F55IXChemConsumpAnInput] = useInputWithState({
    key: 'F55IXChemConsumpAn',
    unit: 'eq / l resin',
  });

  const [, F55IXChemConcentrationCatInput] = useInputWithState({
    key: 'F55IXChemConcentrationCat',
    unit: '% (w/w)',
  });
  const [, F55IXChemConcentrationAnInput] = useInputWithState({
    key: 'F55IXChemConcentrationAn',
    unit: '% (w/w)',
  });

  const [, F55IXWaterCatInput] = useInputWithState({
    key: 'F55IXWaterCat',
    unit: 'BV / reg.',
  });
  const [, F55IXWaterAnInput] = useInputWithState({
    key: 'F55IXWaterAn',
    unit: 'BV / reg.',
  });

  const [, F55IXSweetWaterDilCatInput] = useInputWithState({
    key: 'F55IXSweetWaterDilCat',
    unit: 'BV / reg.',
  });
  const [, F55IXSweetWaterDilAnInput] = useInputWithState({
    key: 'F55IXSweetWaterDilAn',
    unit: 'BV / reg.',
  });

  const [, F55IXWasteWaterCatInput] = useInputWithState({
    key: 'F55IXWasteWaterCat',
    unit: 'BV / reg.',
  });
  const [, F55IXWasteWaterAnInput] = useInputWithState({
    key: 'F55IXWasteWaterAn',
    unit: 'BV / reg.',
  });

  const [, F55IXCrossRegenCatInput] = useInputWithState({
    key: 'F55IXCrossRegenCat',
    unit: 'cycles',
  });
  const [, F55IXCrossRegenAnInput] = useInputWithState({
    key: 'F55IXCrossRegenAn',
    unit: 'cycles',
  });

  const [, F55IXWaterPriceInput] = useInputWithState({
    key: 'F55IXWaterPrice',
    unit: `${activeCurrency} / m3`,
  });
  const [, F55IXWasteWaterCostInput] = useInputWithState({
    key: 'F55IXWasteWaterCost',
    unit: `${activeCurrency} / m3`,
  });

  const leftSection: RowData[] = [
    {
      label: <Typography variant="body1">{t('operationLabel')}</Typography>,
      cation: F55IXOperationCatInput,
      anion: F55IXOperationAnInput,
    },
    {
      label: <Typography variant="body1">{t('resinCapacityLabel')}</Typography>,
      cation: F55IXResinCapCatInput,
      anion: F55IXResinCapAnInput,
    },
    {
      label: <Typography variant="body1">{t('resinLifetimeLabel')}</Typography>,
      cation: F55IXResinLifeCatInput,
      anion: F55IXResinLifeAnInput,
    },
    {
      label: <Typography variant="body1">{t('resinPriceLabel')}</Typography>,
      cation: F55IXResinPriceCatInput,
      anion: F55IXResinPriceAnInput,
    },
    {
      label: <Typography variant="body1">{t('regenChemicalLabel')}</Typography>,
      cation: F55IXRegenChemCatInput,
      anion: F55IXRegenChemAnInput,
    },
    {
      label: <Typography variant="body1">{t('chemicalPriceLabel')}</Typography>,
      cation: F55IXChemPriceCatInput,
      anion: F55IXChemPriceAnInput,
    },
    {
      label: <Typography variant="body1">{t('chemConcentrationLabel')}</Typography>,
      cation: F55IXChemConcentrationCatInput,
      anion: F55IXChemConcentrationAnInput,
    },
    {
      label: <Typography variant="body1">{t('chemConsumptionLabel')}</Typography>,
      cation: F55IXChemConsumpCatInput,
      anion: F55IXChemConsumpAnInput,
    },
    {
      label: <Typography variant="body1">{t('waterLabel')}</Typography>,
      cation: F55IXWaterCatInput,
      anion: F55IXWaterAnInput,
    },
  ];

  const rightSection: RowData[] = [
    {
      label: <Typography variant="body1">{t('sweetWaterDilLabel')}</Typography>,
      cation: F55IXSweetWaterDilCatInput,
      anion: F55IXSweetWaterDilAnInput,
    },
    {
      label: <Typography variant="body1">{t('wasteWaterLabel')}</Typography>,
      cation: F55IXWasteWaterCatInput,
      anion: F55IXWasteWaterAnInput,
    },
    {
      label: <Typography variant="body1">{t('crossRegenLabel')}</Typography>,
      cation: F55IXCrossRegenCatInput,
      anion: F55IXCrossRegenAnInput,
    },
    {
      label: <Typography variant="body1">{t('waterPriceLabel')}</Typography>,
      cation: F55IXWaterPriceInput,
      anion: '',
    },
    {
      label: <Typography variant="body1">{t('wasteWaterCostLabel')}</Typography>,
      cation: F55IXWasteWaterCostInput,
      anion: '',
    },
  ];

  const headings = (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Typography variant="body1">{t('labelHeading')}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body1">{t('cationHeading')}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body1">{t('anionHeading')}</Typography>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Box mb={2}>
        <Typography variant="h3">{t('title')}</Typography>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          {headings}
          {leftSection.map((row: RowData) => (
            <ThreeColumnRow data={row} key={`${row.label}`} />
          ))}
        </Grid>
        <Grid item xs={6}>
          {headings}
          {rightSection.map((row: RowData) => (
            <ThreeColumnRow data={row} key={`${row.label}`} />
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default F55IonExchange;
