import React, { useCallback, createContext, ReactElement, ReactNode, useEffect, useContext, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { activeScenarioState, remoteScenarioState } from 'state/atomState';
import { ScenarioFromBackend, ScenarioParams } from 'scenario/ScenarioType';
import { Response } from 'mappersmith';
import { useParams } from 'react-router-dom';
import { useAsyncError } from 'utils/useAsyncError';
import { Loading } from '@novozymes/components';
import API from '../API';
import defaultScenario from './DefaultScenario';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ScenarioFetchContext = createContext<(scenarioId?: string) => Promise<void>>((scenarioId?: string) =>
  Promise.resolve()
);

interface DataFromBackend {
  statusCode: string;
  body: ScenarioFromBackend | null;
}

const ScenarioGetter = ({ children }: { children?: ReactNode }): ReactElement => {
  const [activeScenario, setActiveScenarioState] = useRecoilState(activeScenarioState);
  const setRemoteScenario = useSetRecoilState(remoteScenarioState);
  const throwAsyncError = useAsyncError();

  const fetchScenarios = useCallback(
    async (scenarioId?: string) => {
      if (!scenarioId) {
        setRemoteScenario(undefined);

        return;
      }
      let result: Response;
      try {
        result = await API.Scenarios.get({ id: scenarioId });
      } catch (err) {
        throwAsyncError(err);
        // logger.error(`Failed to get Scenarios`, { error: err.message });
        setRemoteScenario(undefined);
        return;
      }

      if (!result) {
        return;
      }

      const data = result.data<DataFromBackend>();

      if (data.body === null) {
        throwAsyncError(Error(`Scenario with id: ${scenarioId} was not found`));
        return;
      }

      const scenarioFromBackend = {
        ...defaultScenario,
        title: data.body.Title,
        id: data.body.ScenarioId,
        creatorName: data.body.CreatorName,
        data: { ...defaultScenario.data, ...data.body.ScenarioData },
        region: data.body.ScenarioRegion,
        updatedAt: data.body.UpdatedAt,
      };

      setRemoteScenario(scenarioFromBackend);

      if (scenarioFromBackend.id !== activeScenario.id) {
        setActiveScenarioState(scenarioFromBackend);
      }
    },
    [activeScenario]
  );

  return <ScenarioFetchContext.Provider value={fetchScenarios}>{children}</ScenarioFetchContext.Provider>;
};

export const ScenarioParamsFetcher = ({ children }: { children?: ReactNode }): ReactElement => {
  const params = useParams<ScenarioParams>();
  const fetchScenario = useContext(ScenarioFetchContext);
  const remoteScenario = useRecoilValue(remoteScenarioState);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (params.scenarioId && remoteScenario?.id !== params.scenarioId) {
      fetchScenario(params.scenarioId).then(() => {
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }, [params, remoteScenario]);

  if (isLoading) {
    return <Loading overlay />;
  }
  return <>{children}</>;
};

export default ScenarioGetter;
