interface CalcSaccDSEndInput {
  DX: number;
  DS: number;
}

const calcSaccDSEnd = ({ DX, DS }: CalcSaccDSEndInput): number => {
  return (1 + ((100 - 12 - (100 - DX) / 2) / 100) * 0.11) * DS;
};

export default calcSaccDSEnd;
