interface DMHSavingsInput {
  newDailyDSHydrolOut: number;
  refDailyDSHydrolOut: number;
  newDailyDSSacc: number;
  refDailyDSSacc: number;
  DMHHydrolPrice: number;
  DMHDE95Price: number;
  dailyDSDMH: number;
}

export interface DMHSavingsOutput {
  DMHDailySavings: number;
  DMHDailySavingsPerTonDS: number;
}

const DMHSavings = ({
  newDailyDSHydrolOut,
  refDailyDSHydrolOut,
  newDailyDSSacc,
  refDailyDSSacc,
  DMHHydrolPrice,
  DMHDE95Price,
  dailyDSDMH,
}: DMHSavingsInput): DMHSavingsOutput => {
  const DMHHydrolDailySavings = DMHHydrolPrice * (newDailyDSHydrolOut - refDailyDSHydrolOut);
  const DMHDE95DailySavings = DMHDE95Price * (refDailyDSSacc - newDailyDSSacc);

  const DMHDailySavings = DMHHydrolDailySavings + DMHDE95DailySavings;

  const DMHDailySavingsPerTonDS = DMHDailySavings / dailyDSDMH;

  return { DMHDailySavings, DMHDailySavingsPerTonDS };
};
export default DMHSavings;
