import EnzymeModel from './enzymeModelType';

export const ExtendaPeak15Extra: EnzymeModel = (input) => {
  const DSgns = 34;
  const DScur = input.enzymeDs;
  const DoseGns = 0.21856;
  const scaleFactor = 275 / 1000;
  const DOSEcur = input.dosage * scaleFactor;

  const data = {
    '6H': [
      67.71941258, -0.414954206, 0, 0, -3.051310852, -0.151856795, -2.055093795, 166.4242388, -327.4284679,
      -704.8430807, 0,
    ],
    '12H': [
      92.84435391, -0.438069529, -0.014917213, 0, -2.360803527, -0.144419148, -0.486181966, 170.6167497, -549.2185722,
      36.27100142, 0,
    ],
    '18H': [
      101.2914611, -0.358439037, -0.011547516, 0, -0.915286087, -0.094957778, 0.940648111, 124.1023861, -734.0906633,
      1118.513174, 0,
    ],
    '24H': [
      103.2763855, -0.291964151, -0.01202878, 0, 0.325607418, -0.050483375, 1.640934078, 61.2152242, -657.6205124,
      3366.900881, 0,
    ],
    '36H': [
      103.4805374, -0.229539959, -0.005678585, 0, 0.512808564, -0.002078674, 1.676328318, 10.6659619, -92.4621605,
      2366.977462, -24764.92949,
    ],
    '48H': [
      100.586776, -0.135530419, -0.003877294, -0.001911249, 0.437284491, -0.000276793, 1.378217305, 1.185389074,
      40.46326078, 991.4970677, -18993.49897,
    ],
    '60H': [
      102.1587767, -0.18168641, -0.004992064, 0, 0.512782575, 0, 0.743358269, -5.085463592, -60.12364936, 1221.759747,
      0,
    ],
    '72H': [
      100.9610468, -0.149754365, -0.003853444, -0.000772756, 0.528178939, 0.014084843, 0.372371772, -4.989244456,
      -33.01155081, 618.59477, 0,
    ],
    '96H': [
      101.7111386, -0.182644367, -0.002888981, 0, 0.628931925, 0.028958249, -0.035398301, -6.164538644, -19.66023031,
      187.9271182, 0,
    ],
  };

  const dataValues = Object.values(data);

  const result = dataValues.map(
    (data) =>
      data[0] +
      data[1] * DScur +
      data[2] * (DScur - DSgns) ** 2 +
      data[3] * (DScur - DSgns) ** 3 +
      data[4] +
      data[5] * (DScur - DSgns) +
      data[6] * (DOSEcur - DoseGns) * (DScur - DSgns) +
      data[7] * (DOSEcur - DoseGns) +
      data[8] * (DOSEcur - DoseGns) ** 2 +
      data[9] * (DOSEcur - DoseGns) ** 3 +
      data[10] * (DOSEcur - DoseGns) ** 4
  );

  return result;
};

export const ExtendaPeak25Extra: EnzymeModel = (input) => {
  const multiFactor = 2.5 / 1.5;

  return ExtendaPeak15Extra({ enzymeDs: input.enzymeDs, dosage: input.dosage * multiFactor });
};
