import PageTitle from 'components/PageTitle';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import MassBalanceProcessInput from 'sections/MassBalanceProcessInput';
import MassBalanceTable from 'sections/MassbalanceTable';

const DMH = (): ReactElement => {
  const { t } = useTranslation('pageTitles');
  return (
    <>
      <PageTitle title={t('3a')} docsLinkLabel={t('3aDocs')} docsLink="dmh" />
      <MassBalanceProcessInput />
      <MassBalanceTable />
    </>
  );
};

export default DMH;
