import { Box, Grid, Typography } from '@material-ui/core';
import ValueHighlight from 'components/ValueHighlight';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { currency } from 'state/selectors';
import useInputWithState from 'state/useInputWithState';

const MassBalanceProcessInput = (): ReactElement => {
  const activeCurrency = useRecoilValue(currency);
  const { t } = useTranslation('massBalance');

  const [, yieldCentriInput] = useInputWithState({
    key: 'dmhYieldCentri',
    unit: '% DS (w/w)',
  });

  const [, dmhHydrolInput] = useInputWithState({
    key: 'dmhHydrol',
    unit: `${activeCurrency} / t`,
  });

  const [, dmhDe95Input] = useInputWithState({
    key: 'dmhDe95',
    unit: `${activeCurrency} / t`,
  });

  return (
    <>
      <Typography variant="h2">{t('refBalanceTitle')}</Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={2}>
          <Typography variant="body1">{t('hydrol')}</Typography>
        </Grid>
        <Grid item xs={2}>
          {dmhHydrolInput}
        </Grid>
        <Grid item xs={2}>
          <ValueHighlight value={100} unit="%" />
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body1">{t('yieldCentrifuge')}</Typography>
        </Grid>
        <Grid item xs={2}>
          {yieldCentriInput}
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={2}>
          <Typography variant="body1">{t('de95')}</Typography>
        </Grid>
        <Grid item xs={2}>
          {dmhDe95Input}
        </Grid>
        <Grid item xs={2}>
          <ValueHighlight value={100} unit="%" />
        </Grid>
      </Grid>
      <Box mb={8} />
    </>
  );
};

export default MassBalanceProcessInput;
