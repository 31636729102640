import React, { ReactElement } from 'react';
import ReactPDF, { Text, View } from '@react-pdf/renderer';
import PDFValueHighlight from './PDFValueHighlight';

export type GridRow = Array<string | number | undefined>;

const PDFMassBalanceTable = ({
  rowData,
  colLabels,
  styles,
}: {
  rowData: GridRow[];
  colLabels: { label: string; unit?: string }[];
  styles: ReactPDF.Styles;
}): ReactElement => {
  return (
    <>
      <View style={styles.gridRow}>
        <View style={styles.gridItem} />
        {colLabels.map((lab) => (
          <View style={styles.gridItem} key={`${lab.label}`}>
            <Text>{`${lab.label} ${lab.unit || ''}`}</Text>
          </View>
        ))}
      </View>

      {rowData.map((gridRow, index) => {
        return (
          <View style={styles.gridRow} key={`GridRow${index}`}>
            {gridRow.map((data, columnIndex) => (
              <View style={styles.gridItem} key={`GridItem${columnIndex}`}>
                {columnIndex === 0 ? <Text>{data}</Text> : <PDFValueHighlight value={data} />}
              </View>
            ))}
          </View>
        );
      })}
    </>
  );
};

export default PDFMassBalanceTable;
