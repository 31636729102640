/* eslint-disable max-len */
import React, { ReactElement } from 'react';
import ReactPDF, { Document, Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import { Theme } from '@material-ui/core';
import { Scenario } from 'scenario/ScenarioType';
import PDFValueHighlight from './PDFValueHighlight';
import F55PDF from './F55PDF';
import PageWithHeader from './PageWithHeader';
import { renderIfNotUndefined } from './helpers';
import DMHPDF from './DMHPDF';

const useStyles = (theme: Theme): ReactPDF.Styles =>
  StyleSheet.create({
    h1: {
      fontSize: 18,
      lineHeight: 1.6,
      marginBottom: theme.spacing(2),
    },
    h2: {
      fontSize: 16,
      lineHeight: 1.4,
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    h3: {
      fontSize: 14,
      lineHeight: 1.2,
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(2),
    },
    h4: {
      fontSize: 12,
      lineHeight: 1.2,
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    gridRow: {
      display: 'flex',
      flexDirection: 'row',
      fontSize: 10,
      paddingVertical: theme.spacing(0.5),
    },
    gridItem: {
      flex: 1,
      paddingRight: theme.spacing(1),
      justifyContent: 'center',
    },
    tableHeading: {
      fontSize: 9,
      marginBottom: theme.spacing(0.5),
    },
    spacer: {
      marginVertical: theme.spacing(1),
    },
    imageWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: theme.spacing(3),
    },
    DXChartImage: {
      height: '400px',
      width: '400px',
    },
    savingsChartImage: {
      height: '400px',
      width: '400px',
    },
  });

const PDFDocument = ({
  theme,
  currentScenario,
  calculationResult,
  DXChartImage,
  SavingsChartImage,
  currency,
}: {
  theme: Theme;
  currentScenario: Scenario;
  calculationResult: Record<string, any>;
  DXChartImage: string;
  SavingsChartImage: string;
  currency: string;
}): ReactElement => {
  const styles = useStyles(theme);

  const DMHEnabled =
    currentScenario.data.productionSteps === 'Only DMH' || currentScenario.data.productionSteps === 'Both DMH & F55';
  const F55Enabled =
    currentScenario.data.productionSteps === 'Only F55' || currentScenario.data.productionSteps === 'Both DMH & F55';

  return (
    <Document>
      <PageWithHeader theme={theme}>
        <View>
          <Text style={styles.h1}>{currentScenario.title}</Text>
        </View>
        <View style={styles.gridRow}>
          <View style={styles.gridItem}>
            <Text>Saccharification capacity </Text>
          </View>
          <View style={styles.gridItem}>
            <PDFValueHighlight value={currentScenario.data.saccCapacity} unit="tons DS/day" />
          </View>
          <View style={styles.gridItem}>
            <Text>Production steps </Text>
          </View>
          <View style={styles.gridItem}>
            <PDFValueHighlight value={currentScenario.data.productionSteps} />
          </View>
        </View>
        <View>
          <Text style={styles.h2}>1. Select comparison scenario</Text>
        </View>
        {renderIfNotUndefined(1, [
          currentScenario.title,
          currentScenario.data.refEnzymeName,
          currentScenario.data.newEnzymeName,
          currentScenario.data.refEnzymeDosing,
          currentScenario.data.newEnzymeDosing,
          currentScenario.data.refEnzymeDS,
          currentScenario.data.newEnzymeDS,
          currentScenario.data.refTime,
          currentScenario.data.newTime,
          currentScenario.data.refDX,
          currentScenario.data.newDX,
        ])(
          <>
            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <Text>Enzyme type </Text>
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.refEnzymeName} />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.newEnzymeName} />
              </View>
            </View>
            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <Text>Dosage </Text>
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.refEnzymeDosing} unit="kg/tDS" decimals={4} />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.newEnzymeDosing} unit="kg/tDS" decimals={4} />
              </View>
            </View>
            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <Text>DS </Text>
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.refEnzymeDS} unit="%" />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.newEnzymeDS} unit="%" />
              </View>
            </View>
            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <Text>Target time </Text>
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.refTime} unit="hours" />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.newTime} unit="hours" />
              </View>
            </View>
            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <Text>Target DX </Text>
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.refDX} unit="%" />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.newDX} unit="%" />
              </View>
            </View>
          </>
        )}

        {DXChartImage && (
          <View style={styles.imageWrapper}>
            <Image src={DXChartImage} style={styles.DXChartImage} />
          </View>
        )}
      </PageWithHeader>
      <PageWithHeader theme={theme}>
        <View>
          <Text style={styles.h2}>2. Evaporation</Text>
        </View>
        {renderIfNotUndefined(2, [
          currentScenario.data.evapDSFinal,
          currentScenario.data.refEnzymeDS,
          currentScenario.data.newEnzymeDS,
          calculationResult.refDSOutlet,
          calculationResult.newDSOutlet,
          calculationResult.refDensity,
          calculationResult.newDensity,
          calculationResult.refEvaporation,
          calculationResult.newEvaporation,
        ])(
          <>
            <View style={styles.gridRow}>
              <View style={styles.gridItem} />
              <View style={styles.gridItem}>
                <Text style={styles.tableHeading}>DS in</Text>
              </View>
              <View style={styles.gridItem}>
                <Text style={styles.tableHeading}>DS out</Text>
              </View>
              <View style={styles.gridItem}>
                <Text style={styles.tableHeading}>Density</Text>
              </View>
              <View style={styles.gridItem}>
                <Text style={styles.tableHeading}>DS final</Text>
              </View>
              <View style={styles.gridItem}>
                <Text style={styles.tableHeading}>Evaporation</Text>
              </View>
            </View>
            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <Text>Reference</Text>
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.refEnzymeDS} unit="% (w/w)" />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={calculationResult.refDSOutlet} unit="% (w/w)" />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={calculationResult.refDensity} unit="kg/l" />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.evapDSFinal} unit="% (w/w)" />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={calculationResult.refEvaporation} unit="t water/day" />
              </View>
            </View>
            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <Text>New solution</Text>
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.newEnzymeDS} unit="% (w/w)" />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={calculationResult.newDSOutlet} unit="% (w/w)" />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={calculationResult.newDensity} unit="kg/l" />
              </View>
              <View style={styles.gridItem} />
              <View style={styles.gridItem}>
                <PDFValueHighlight value={calculationResult.newEvaporation} unit="t water/day" />
              </View>
            </View>
          </>
        )}
        <View>
          <Text style={styles.h3}>Evaporator data</Text>
        </View>
        {renderIfNotUndefined(2, [currentScenario.data.evaporatorType])(
          currentScenario.data.evaporatorType === 'MVR' ? (
            <>
              <View style={styles.gridRow}>
                <View style={styles.gridItem}>
                  <Text style={styles.tableHeading}>Evaporation type</Text>
                </View>
                <View style={styles.gridItem}>
                  <Text style={styles.tableHeading}>Electricity consumption</Text>
                </View>
                <View style={styles.gridItem}>
                  <Text style={styles.tableHeading}>Electricity price</Text>
                </View>
                <View style={styles.gridItem}>
                  <Text style={styles.tableHeading}>Cost</Text>
                </View>
                <View style={styles.gridItem}>
                  <Text style={styles.tableHeading}>Savings</Text>
                </View>
              </View>
              <View style={styles.gridRow}>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={currentScenario.data.evaporatorType} />
                </View>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={currentScenario.data.electricityConsumption} unit="kWh/t evap" />
                </View>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={currentScenario.data.electricityPrice} unit={`${currency}/kWh`} />
                </View>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={calculationResult.evaporatonCost} unit={`${currency}/t evap`} />
                </View>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={calculationResult.evaporatonSavings} unit={`${currency}/day`} />
                </View>
              </View>
            </>
          ) : (
            <>
              <View style={styles.gridRow}>
                <View style={styles.gridItem}>
                  <Text style={styles.tableHeading}>Evaporation type</Text>
                </View>
                <View style={styles.gridItem}>
                  <Text style={styles.tableHeading}>Effects</Text>
                </View>
                <View style={styles.gridItem}>
                  <Text style={styles.tableHeading}>T steam/t evap</Text>
                </View>
                <View style={styles.gridItem}>
                  <Text style={styles.tableHeading}>Steam price</Text>
                </View>
                <View style={styles.gridItem}>
                  <Text style={styles.tableHeading}>Cost</Text>
                </View>
                <View style={styles.gridItem}>
                  <Text style={styles.tableHeading}>Savings</Text>
                </View>
              </View>
              <View style={styles.gridRow}>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={currentScenario.data.evaporatorType} />
                </View>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={currentScenario.data.effects} />
                </View>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={calculationResult.steamConsumption} unit="t steam/t evap" />
                </View>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={currentScenario.data.steamPrice} unit={`${currency}/t steam`} />
                </View>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={calculationResult.evaporationCost} unit={`${currency}/t evap`} />
                </View>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={calculationResult.evaporatonSavings} unit={`${currency}/day`} />
                </View>
              </View>
            </>
          )
        )}
      </PageWithHeader>
      {DMHEnabled && (
        <DMHPDF
          currency={currency}
          currentScenario={currentScenario}
          calculationResult={calculationResult}
          styles={styles}
          theme={theme}
        />
      )}
      {F55Enabled && (
        <F55PDF
          currency={currency}
          currentScenario={currentScenario}
          calculationResult={calculationResult}
          styles={styles}
          theme={theme}
        />
      )}
      <PageWithHeader theme={theme}>
        <View>
          <Text style={styles.h2}>4. Your savings</Text>
        </View>
        {!!SavingsChartImage && (
          <View style={styles.imageWrapper}>
            <Image src={SavingsChartImage} style={styles.savingsChartImage} />
          </View>
        )}
        <View>
          <Text style={styles.h3}>Savings without enzyme cost</Text>
        </View>
        {renderIfNotUndefined(1, [
          calculationResult.yearlySavingsWithoutEnzyme,
          calculationResult.dailySavingsWithoutEnzyme,
          calculationResult.savingsPerDSWithoutEnzyme,
        ])(
          <>
            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={calculationResult.yearlySavingsWithoutEnzyme} unit={`${currency}/ year`} />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={calculationResult.dailySavingsWithoutEnzyme} unit={`${currency}/ day`} />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={calculationResult.savingsPerDSWithoutEnzyme} unit={`${currency}/ tDS`} />
              </View>
              <View style={styles.gridItem} />
            </View>
            <View>
              <Text style={styles.h3}>Total savings including enzyme cost</Text>
            </View>
          </>
        )}
        {renderIfNotUndefined(1, [
          calculationResult.totalYearlySavigs,
          calculationResult.totalDailySavigs,
          currentScenario.data.refEnzymeName,
          currentScenario.data.refEnzymeDosing,
          currentScenario.data.refEnzymePrice,
          currentScenario.data.newEnzymeName,
          currentScenario.data.newEnzymeDosing,
          currentScenario.data.newEnzymePrice,
        ])(
          <>
            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={calculationResult.totalYearlySavigs} unit={`${currency}/ year`} />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={calculationResult.totalDailySavigs} unit={`${currency}/ day`} />
              </View>
              <View style={styles.gridItem} />
            </View>

            <View>
              <Text>Reference</Text>
            </View>
            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.refEnzymeName} />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.refEnzymeDosing} unit="kg/tDS" />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.refEnzymePrice} unit={`${currency}/ kg`} />
              </View>
              <View style={styles.gridItem} />
            </View>
            <View>
              <Text>New solution</Text>
            </View>
            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.newEnzymeName} />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.newEnzymeDosing} unit="kg/tDS" />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.newEnzymePrice} unit={`${currency}/ kg`} />
              </View>
              <View style={styles.gridItem} />
            </View>
          </>
        )}
      </PageWithHeader>
      <PageWithHeader theme={theme}>
        <View>
          <Text style={styles.h3}>Saving details</Text>
        </View>
        <View style={styles.gridRow}>
          <View style={styles.gridItem}>
            <Text>New saccharification enzyme</Text>
          </View>
          <View style={styles.gridItem}>
            <PDFValueHighlight value={calculationResult.enzymeSavings} unit={`${currency}/ day`} decimals={4} />
          </View>
          <View style={styles.gridItem}>{F55Enabled && <Text>F55: Separation water</Text>}</View>
          <View style={styles.gridItem}>
            {F55Enabled && (
              <PDFValueHighlight value={calculationResult.F55WaterSavings} unit={`${currency}/ day`} decimals={4} />
            )}
          </View>
          <View style={styles.gridItem} />
        </View>
        <View style={styles.gridRow}>
          <View style={styles.gridItem}>
            <Text>Evaporation</Text>
          </View>
          <View style={styles.gridItem}>
            <PDFValueHighlight value={calculationResult.evaporatonSavings} unit={`${currency}/ day`} decimals={4} />
          </View>
          <View style={styles.gridItem}>{F55Enabled && <Text>F55: Ion exch. & isomerisation</Text>}</View>
          <View style={styles.gridItem}>
            {F55Enabled && (
              <PDFValueHighlight value={calculationResult.F55IXSavings} unit={`${currency}/ day`} decimals={4} />
            )}
          </View>
          <View style={styles.gridItem} />
        </View>
        <View style={styles.gridRow}>
          <View style={styles.gridItem}>{DMHEnabled && <Text>DMH: DE95</Text>}</View>
          <View style={styles.gridItem}>
            {DMHEnabled && (
              <PDFValueHighlight value={calculationResult.DMHDailySavings} unit={`${currency}/ day`} decimals={4} />
            )}
          </View>
          <View style={styles.gridItem} />
          <View style={styles.gridItem} />
          <View style={styles.gridItem} />
        </View>

        <View>
          <Text style={styles.h3}>Detailed savings on Ion exchange</Text>
        </View>
        <View style={styles.gridRow}>
          <View
            style={{
              flex: 1,
            }}
          >
            <View>
              <Text style={styles.h4}>Regeneration costs</Text>
            </View>
            {renderIfNotUndefined(4, [
              calculationResult.regenSavings?.chemCatSavings,
              calculationResult.regenSavings?.chemAnSavings,
              calculationResult.regenSavings?.wasteWaterCatSavings,
              calculationResult.regenSavings?.wasteWaterAnSavings,
              calculationResult.regenSavings?.waterCatSavings,
              calculationResult.regenSavings?.waterAnSavings,
              calculationResult.regenSavings?.resinCatSavings,
              calculationResult.regenSavings?.resinAnSavings,
              calculationResult.regenSavings?.sweatWaterCatSavings,
              calculationResult.regenSavings?.sweatWaterAnSavings,
              calculationResult.regenSavings?.totalCatSavings,
              calculationResult.regenSavings?.totalAnSavings,
            ])(
              <>
                <View style={styles.gridRow}>
                  <View style={styles.gridItem} />
                  <View style={styles.gridItem}>
                    <Text style={styles.tableHeading}>Cation</Text>
                  </View>
                  <View style={styles.gridItem}>
                    <Text style={styles.tableHeading}>Anion</Text>
                  </View>
                </View>

                <View style={styles.gridRow}>
                  <View style={styles.gridItem}>
                    <Text>Chemical</Text>
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight
                      value={calculationResult.regenSavings?.chemCatSavings}
                      unit={`${currency}/ eq`}
                      decimals={4}
                    />
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight
                      value={calculationResult.regenSavings?.chemAnSavings}
                      unit={`${currency}/ eq`}
                      decimals={4}
                    />
                  </View>
                </View>
                <View style={styles.gridRow}>
                  <View style={styles.gridItem}>
                    <Text>Waste water</Text>
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight
                      value={calculationResult.regenSavings?.wasteWaterCatSavings}
                      unit={`${currency}/ eq`}
                      decimals={4}
                    />
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight
                      value={calculationResult.regenSavings?.wasteWaterAnSavings}
                      unit={`${currency}/ eq`}
                      decimals={4}
                    />
                  </View>
                </View>
                <View style={styles.gridRow}>
                  <View style={styles.gridItem}>
                    <Text>Water</Text>
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight
                      value={calculationResult.regenSavings?.waterCatSavings}
                      unit={`${currency}/ eq`}
                      decimals={4}
                    />
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight
                      value={calculationResult.regenSavings?.waterAnSavings}
                      unit={`${currency}/ eq`}
                      decimals={4}
                    />
                  </View>
                </View>
                <View style={styles.gridRow}>
                  <View style={styles.gridItem}>
                    <Text>Resin</Text>
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight
                      value={calculationResult.regenSavings?.resinCatSavings}
                      unit={`${currency}/ eq`}
                      decimals={4}
                    />
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight
                      value={calculationResult.regenSavings?.resinAnSavings}
                      unit={`${currency}/ eq`}
                      decimals={4}
                    />
                  </View>
                </View>
                <View style={styles.gridRow}>
                  <View style={styles.gridItem}>
                    <Text>Sweet water evaporation</Text>
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight
                      value={calculationResult.regenSavings?.sweatWaterCatSavings}
                      unit={`${currency}/ eq`}
                      decimals={4}
                    />
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight
                      value={calculationResult.regenSavings?.sweatWaterAnSavings}
                      unit={`${currency}/ eq`}
                      decimals={4}
                    />
                  </View>
                </View>
                <View style={styles.gridRow}>
                  <View style={styles.gridItem}>
                    <Text>Total</Text>
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight
                      value={calculationResult.regenSavings?.totalCatSavings}
                      unit={`${currency}/ eq`}
                      highlight
                      decimals={4}
                    />
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight
                      value={calculationResult.regenSavings?.totalAnSavings}
                      unit={`${currency}/ eq`}
                      highlight
                      decimals={4}
                    />
                  </View>
                </View>
              </>
            )}
          </View>
          <View
            style={{
              flex: 1,
            }}
          >
            <View>
              <Text style={styles.h4}>Reduction in IX load</Text>
            </View>
            {renderIfNotUndefined(4, [
              calculationResult.reducSavings?.magnesiumCatReduction,
              calculationResult.reducSavings?.magnesiumAnReduction,
              calculationResult.reducSavings?.so2CatReduction,
              calculationResult.reducSavings?.so2AnReduction,
              calculationResult.reducSavings?.baseCatEqReduction,
              calculationResult.reducSavings?.baseAnEqReduction,
              calculationResult.reducSavings?.acidCatEqReduction,
              calculationResult.reducSavings?.acidAnEqReduction,
              calculationResult.reducSavings?.totalCatEqReduction,
              calculationResult.reducSavings?.totalAnEqReduction,
            ])(
              <>
                <View style={styles.gridRow}>
                  <View style={styles.gridItem} />
                  <View style={styles.gridItem}>
                    <Text style={styles.tableHeading}>Cation</Text>
                  </View>
                  <View style={styles.gridItem}>
                    <Text style={styles.tableHeading}>Anion</Text>
                  </View>
                </View>
                <View style={styles.gridRow}>
                  <View style={styles.gridItem}>
                    <Text>Magnesium sulphate</Text>
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight
                      value={calculationResult.reducSavings?.magnesiumCatReduction}
                      unit={`${currency}/ day`}
                    />
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight
                      value={calculationResult.reducSavings?.magnesiumAnReduction}
                      unit={`${currency}/ day`}
                    />
                  </View>
                </View>
                <View style={styles.gridRow}>
                  <View style={styles.gridItem}>
                    <Text>Sodium bisulfite</Text>
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight
                      value={calculationResult.reducSavings?.so2CatReduction}
                      unit={`${currency}/ day`}
                    />
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight
                      value={calculationResult.reducSavings?.so2AnReduction}
                      unit={`${currency}/ day`}
                    />
                  </View>
                </View>
                <View style={styles.gridRow}>
                  <View style={styles.gridItem}>
                    <Text>Base, PH adjustment</Text>
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight
                      value={calculationResult.reducSavings?.baseCatEqReduction}
                      unit={`${currency}/ day`}
                    />
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight
                      value={calculationResult.reducSavings?.baseAnEqReduction}
                      unit={`${currency}/ day`}
                    />
                  </View>
                </View>
                <View style={styles.gridRow}>
                  <View style={styles.gridItem}>
                    <Text>Acid, PH adjustment</Text>
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight
                      value={calculationResult.reducSavings?.acidCatEqReduction}
                      unit={`${currency}/ day`}
                    />
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight
                      value={calculationResult.reducSavings?.acidAnEqReduction}
                      unit={`${currency}/ day`}
                    />
                  </View>
                </View>
                <View style={styles.gridRow}>
                  <View style={styles.gridItem}>
                    <Text>Total</Text>
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight
                      value={calculationResult.reducSavings?.totalCatEqReduction}
                      unit={`${currency}/ day`}
                      highlight
                    />
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight
                      value={calculationResult.reducSavings?.totalAnEqReduction}
                      unit={`${currency}/ day`}
                      highlight
                    />
                  </View>
                </View>
              </>
            )}
          </View>
        </View>
      </PageWithHeader>
    </Document>
  );
};

export default PDFDocument;
