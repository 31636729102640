import EnzymeModel from './enzymeModelType';

export const ExtendaPro: EnzymeModel = (input) => {
  const DSgns = 34;
  const DScur = input.enzymeDs;
  const DoseGns = 0.21856;
  const scaleFactor = 642 / 1000;
  const DOSEcur = input.dosage * scaleFactor;

  const data = {
    '6H': [
      67.71941258,
      -0.414954206,
      0,
      0,
      -3.223868427,
      -0.078185814,
      -1.470414158,
      155.8869031,
      -286.7613775,
      -182.9755923,
      0,
    ],
    '12H': [
      92.84435391,
      -0.438069529,
      -0.014917213,
      0,
      -3.155237299,
      -0.086302112,
      -0.409269407,
      161.9708096,
      -482.8839844,
      275.6237722,
      0,
    ],
    '18H': [
      101.2914611,
      -0.358439037,
      -0.011547516,
      0,
      -1.735606092,
      -0.069723734,
      0.75659749,
      119.4460106,
      -669.5937706,
      1246.967529,
      0,
    ],
    '24H': [
      103.2763855,
      -0.291964151,
      -0.01202878,
      0,
      -0.237442682,
      -0.051075482,
      1.364557175,
      64.08529909,
      -641.7477637,
      2783.133686,
      0,
    ],
    '36H': [
      103.4805374,
      -0.229539959,
      -0.005678585,
      0,
      0.271384991,
      -0.020450353,
      1.410812372,
      10.52798853,
      -159.8050923,
      2499.383967,
      -17006.02193,
    ],
    '48H': [
      100.586776,
      -0.135530419,
      -0.003877294,
      -0.001911249,
      0.275635395,
      -0.009472148,
      1.081000251,
      1.136602243,
      -19.15239287,
      1184.560851,
      -12419.24965,
    ],
    '60H': [
      102.1587767,
      -0.18168641,
      -0.004992064,
      0,
      0.374827426,
      0,
      0.571399397,
      -2.106173736,
      -73.38316411,
      819.5887455,
      0,
    ],
    '72H': [
      100.9610468,
      -0.149754365,
      -0.003853444,
      -0.000772756,
      0.388541221,
      0.012588924,
      0.251101176,
      -3.068248665,
      -40.01975967,
      407.3179312,
      0,
    ],
    '96H': [
      101.7111386,
      -0.182644367,
      -0.002888981,
      0,
      0.487270423,
      0.027701431,
      -0.070701805,
      -5.37846889,
      -23.01972695,
      146.0946584,
      0,
    ],
  };

  const dataValues = Object.values(data);

  const result = dataValues.map(
    (data) =>
      data[0] +
      data[1] * DScur +
      data[2] * (DScur - DSgns) ** 2 +
      data[3] * (DScur - DSgns) ** 3 +
      data[4] +
      data[5] * (DScur - DSgns) +
      data[6] * (DOSEcur - DoseGns) * (DScur - DSgns) +
      data[7] * (DOSEcur - DoseGns) +
      data[8] * (DOSEcur - DoseGns) ** 2 +
      data[9] * (DOSEcur - DoseGns) ** 3 +
      data[10] * (DOSEcur - DoseGns) ** 4
  );

  return result;
};

export const ExtendaPrime: EnzymeModel = (input) => {
  return ExtendaPro({ enzymeDs: input.enzymeDs, dosage: input.dosage });
};
