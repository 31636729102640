/* eslint-disable max-len */
/* eslint-disable no-tabs */
import React, { ReactElement } from 'react';
import { Link, Typography } from '@material-ui/core';
import { getHomeLink } from 'utils/navigateHome';

const Cookies = (): ReactElement => (
  <>
    <Typography variant="h1">Cookie policy</Typography>
    <Typography variant="body1">
      To read more about the Refinery Optimizers cookies policy go to <Link href={getHomeLink('cookies')}>Cookies</Link>
    </Typography>
  </>
);

export default Cookies;
