import { Grid, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import useInputWithState from 'state/useInputWithState';
import { Acid, Base, Magnesium, So2Source } from 'scenario/ScenarioType';
import { useRecoilValue } from 'recoil';
import { currency } from 'state/selectors';
import { useTranslation } from 'react-i18next';

const F55Chemicals = (): ReactElement => {
  const activeCurrency = useRecoilValue(currency);

  const [, F55MgTypeInput] = useInputWithState({
    key: 'F55MgType',
    type: 'Select',
    selectOptions: Object.keys(Magnesium).map((enumKey) => ({
      value: enumKey,
      label: Magnesium[enumKey as keyof typeof Magnesium],
    })),
  });
  const [, F55MgPriceInput] = useInputWithState({
    key: 'F55MgPrice',
    unit: `${activeCurrency}/kg`,
  });
  const [, F55MgConcInput] = useInputWithState({ key: 'F55MgConc', unit: '%' });
  const [, F55MgDosageInput] = useInputWithState({
    key: 'F55MgDosage',
    unit: 'ppm',
  });

  const [, F55SO2TypeInput] = useInputWithState({
    key: 'F55SO2Type',
    type: 'Select',
    selectOptions: Object.keys(So2Source).map((enumKey) => ({
      value: enumKey,
      label: So2Source[enumKey as keyof typeof So2Source],
    })),
  });
  const [, F55SO2PriceInput] = useInputWithState({
    key: 'F55SO2Price',
    unit: `${activeCurrency}/kg`,
  });
  const [, F55SO2ConcInput] = useInputWithState({
    key: 'F55SO2Conc',
    unit: '%',
  });
  const [, F55SO2DosageInput] = useInputWithState({
    key: 'F55SO2Dosage',
    unit: 'ppm',
  });

  const [, F55BaseTypeInput] = useInputWithState({
    key: 'F55BaseType',
    type: 'Select',
    selectOptions: Object.keys(Base).map((enumKey) => ({
      value: enumKey,
      label: Base[enumKey as keyof typeof Base],
    })),
  });
  const [, F55BasePriceInput] = useInputWithState({
    key: 'F55BasePrice',
    unit: `${activeCurrency}/kg`,
  });
  const [, F55BaseConcInput] = useInputWithState({
    key: 'F55BaseConc',
    unit: '%',
  });

  const [, F55AcidTypeInput] = useInputWithState({
    key: 'F55AcidType',
    type: 'Select',
    selectOptions: Object.keys(Acid).map((enumKey) => ({
      value: enumKey,
      label: Acid[enumKey as keyof typeof Acid],
    })),
  });
  const [, F55AcidPriceInput] = useInputWithState({
    key: 'F55AcidPrice',
    unit: `${activeCurrency}/kg`,
  });
  const [, F55AcidConcInput] = useInputWithState({
    key: 'F55AcidConc',
    unit: '%',
  });

  const { t } = useTranslation('F55Chemicals');

  return (
    <>
      <Typography variant="h3">{t('title')}</Typography>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={2} />
        <Grid item xs={2}>
          <Typography variant="body1">{t('typeLabel')}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body1">{t('priceLabel')}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body1">{t('concLabel')}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body1">{t('dosageLabel')}</Typography>
        </Grid>
        <Grid item xs={2} />

        <Grid item xs={2}>
          <Typography variant="body1">{t('mgLabel')}</Typography>
        </Grid>
        <Grid item xs={2}>
          {F55MgTypeInput}
        </Grid>
        <Grid item xs={2}>
          {F55MgPriceInput}
        </Grid>
        <Grid item xs={2}>
          {F55MgConcInput}
        </Grid>
        <Grid item xs={2}>
          {F55MgDosageInput}
        </Grid>
        <Grid item xs={2} />

        <Grid item xs={2}>
          <Typography variant="body1">{t('so2Label')}</Typography>
        </Grid>
        <Grid item xs={2}>
          {F55SO2TypeInput}
        </Grid>
        <Grid item xs={2}>
          {F55SO2PriceInput}
        </Grid>
        <Grid item xs={2}>
          {F55SO2ConcInput}
        </Grid>
        <Grid item xs={2}>
          {F55SO2DosageInput}
        </Grid>
        <Grid item xs={2} />

        <Grid item xs={2}>
          <Typography variant="body1">{t('baseLabel')}</Typography>
        </Grid>
        <Grid item xs={2}>
          {F55BaseTypeInput}
        </Grid>
        <Grid item xs={2}>
          {F55BasePriceInput}
        </Grid>
        <Grid item xs={2}>
          {F55BaseConcInput}
        </Grid>

        <Grid item xs={4} />

        <Grid item xs={2}>
          <Typography variant="body1">{t('acidLabel')}</Typography>
        </Grid>
        <Grid item xs={2}>
          {F55AcidTypeInput}
        </Grid>
        <Grid item xs={2}>
          {F55AcidPriceInput}
        </Grid>
        <Grid item xs={2}>
          {F55AcidConcInput}
        </Grid>

        <Grid item xs={4} />
      </Grid>
    </>
  );
};

export default F55Chemicals;
