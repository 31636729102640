import _ from 'lodash';
import F55SeparationRefMassBalance from './F55SeparationRefMassBalance';
import { F55SeparationNewMassBalanceOutput, F55SeparationNewMassBalanceInput } from './F55Types';
import K from './helpers/K';
import XE from './helpers/XE';

const F55SeparationNewMassBalance = ({
  refIsoInletTemp,
  refProcentageFxIso,
  refSaccDx,
  refDP1F55,
  refFlowIsofeedF55,
  refLNF55,
  saccDx,
  fluctoseRecovery,
  DP2PlusRecovery,
  dailyDSF55,
  procentageFxF55,
  dailyDSSacc,
  procentageFxMixing2,
}: F55SeparationNewMassBalanceInput): F55SeparationNewMassBalanceOutput | undefined => {
  const endFxConc = saccDx >= refSaccDx ? XE(K(refIsoInletTemp)) * 100 - 0.5 : 30;
  const stepValue = saccDx >= refSaccDx ? 0.0005 : -0.0005;
  const stepsToTry = _.range(refProcentageFxIso, endFxConc, stepValue);
  let finalOutput;
  stepsToTry.every((procentageFxIso) => {
    const output = F55SeparationRefMassBalance({
      refIsoInletTemp,
      saccDx,
      procentageFxIso,
      fluctoseRecovery,
      DP2PlusRecovery,
      dailyDSF55,
      procentageFxF55,
      dailyDSSacc,
      procentageFxMixing2,
    });

    finalOutput = { ...output, procentageFxIso };

    if (
      Math.abs(refFlowIsofeedF55 * refDP1F55 * refLNF55 - output.FlowIsofeedF55 * output.DP1F55 * output.LNF55) < 10
    ) {
      return false;
    }
    return true;
  });

  return finalOutput;
};

export default F55SeparationNewMassBalance;
