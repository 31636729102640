import { Box, Grid, Typography } from '@material-ui/core';
import ValueHighlight from 'components/ValueHighlight';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { currency, F55ChangeFlowState, F55DilWaterSavingsState } from 'state/selectors';
import useInputWithState from 'state/useInputWithState';
import F55MassBalance from './F55MassBalance';

const F55ChromSep = (): ReactElement => {
  const activeCurrency = useRecoilValue(currency);

  const [, F55DP2PlusRecoveryInput] = useInputWithState({
    key: 'F55DP2PlusRecovery',
    unit: '% DS w/w',
  });
  const [, F55FructoseRecoveryInput] = useInputWithState({
    key: 'F55FructoseRecovery',
    unit: '% DS w/w',
  });
  const [, F55DilutionWaterInput] = useInputWithState({
    key: 'F55DilutionWater',
    unit: 't /tDS',
  });

  const savingDilWaterEvap = useRecoilValue(F55DilWaterSavingsState);
  const changeInletFlow = useRecoilValue(F55ChangeFlowState);

  const { t } = useTranslation('F55chromSep');

  return (
    <>
      <Box mb={3}>
        <Typography variant="h3">{t('title')}</Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Typography variant="body1">{t('DP2PlusRecovLabel')}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body1">{t('fructoseRecovLabel')}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body1">{t('dilutionWaterLabel')}</Typography>
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={2}>
          {F55DP2PlusRecoveryInput}
        </Grid>
        <Grid item xs={2}>
          {F55FructoseRecoveryInput}
        </Grid>
        <Grid item xs={2}>
          {F55DilutionWaterInput}
        </Grid>
        <Grid item xs={6} />
      </Grid>
      <Box py={4}>
        <F55MassBalance />
      </Box>

      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={8} />
        <Grid item xs={2}>
          <Typography variant="body1">{t('dilutionWaterSavingLabel')}</Typography>
        </Grid>
        <Grid item xs={2}>
          <ValueHighlight value={savingDilWaterEvap} unit={`${activeCurrency} / day`} highlight />
        </Grid>
        <Grid item xs={8} />
        <Grid item xs={2}>
          <Typography variant="body1">{t('changeInInletFlow')}</Typography>
        </Grid>
        <Grid item xs={2}>
          <ValueHighlight value={changeInletFlow} unit="%" highlight />
        </Grid>
      </Grid>
    </>
  );
};

export default F55ChromSep;
