interface EvaporationMVRCostInput {
  electricityPrice: number;
  electricityConsumption: number;
}

const evaporationMVRCost = ({ electricityPrice, electricityConsumption }: EvaporationMVRCostInput): number => {
  return electricityPrice * electricityConsumption;
};

export default evaporationMVRCost;
