import React, { ReactElement } from 'react';
import Page from 'components/layout/Page';
import { PROCESS_STEPS } from './processNavigation';

const StepRouter = ({ match }: { match: any }): ReactElement | null => {
  const {
    params: { stepUrl },
  } = match;
  const currentStep = PROCESS_STEPS.find((step) => step.url === stepUrl);
  if (!currentStep) {
    return null;
  }
  return <Page useMarketSelector>{currentStep.component}</Page>;
};

export default StepRouter;
