import React, { ReactElement } from 'react';
import { BarChart, Bar, XAxis, YAxis, ReferenceLine, Tooltip, CartesianGrid, Cell } from 'recharts';
import { formatNumber } from '@novozymes/components';

export type SavingsChartEntry = {
  savingType: string;
  savings: number;
  disable?: boolean;
  highlight?: boolean;
};

export interface SavingsChartProps {
  data: SavingsChartEntry[];
  useRef?: (ref: any) => void;
  toolTipColor: string;
  barColor: string;
  barHighlightColor: string;
  showToolTip?: boolean;
  currency: string;
}

function SavingsChart({
  data,
  useRef,
  showToolTip,
  toolTipColor,
  barColor,
  barHighlightColor,
  currency,
  ...restProps
}: SavingsChartProps): ReactElement {
  const getValueForKey = (key: string): string | number | undefined => {
    const value = data.find(({ savingType }) => key === savingType)?.savings;
    return value ? formatNumber(value) : value;
  };

  const getDisabledForKey = (key: string): boolean => {
    const disabled = data.find(({ savingType }) => key === savingType)?.disable;
    return !!disabled;
  };

  const TickComponent = (props: any): ReactElement => {
    const { payload, y } = props;
    const disabled = getDisabledForKey(payload.value);
    return (
      <g textAnchor="left">
        <text
          fill={`rgba(0,0,0,${disabled ? '0.47' : '0.8'})`}
          x={0}
          y={y - 5}
          fontWeight="bold"
          fontSize="14px"
        >{`${payload.value}`}</text>
        <text fill={`rgba(0,0,0,${disabled ? '0.47' : '0.8'})`} x={0} y={y + 12} fontSize="14px">
          {disabled ? '- Not given -' : `${getValueForKey(payload.value)} ${currency} / day`}
        </text>
      </g>
    );
  };
  return (
    <BarChart data={data} layout="vertical" barSize={24} ref={useRef} {...restProps}>
      <XAxis
        type="number"
        dataKey="savings"
        height={60}
        label={{
          value: `${currency} / day`,
          position: 'center',
          dy: 20,
          fontSize: 14,
        }}
        tickFormatter={(value): string | undefined => formatNumber(value)}
        tick={{ dy: 10 }}
      />
      <YAxis
        dataKey="savingType"
        type="category"
        width={150}
        tickSize={0}
        tick={TickComponent}
        minTickGap={20}
        padding={{ bottom: 20, top: 0 }}
      />

      {showToolTip && (
        <Tooltip
          itemStyle={{ color: toolTipColor }}
          cursor={{ fill: 'rgba(245,245,245,0.7)' }}
          contentStyle={{
            borderRadius: '4px',
            WebkitBoxShadow:
              '0px 3px 4px rgba(0,0,0,0.14), 0px 3px 3px rgba(0,0,0,0.12),  0px 1px 8px rgba(0,0,0,0.20)',
            MozBoxShadow: '0px 3px 4px rgba(0,0,0,0.14), 0px 3px 3px rgba(0,0,0,0.12),  0px 1px 8px rgba(0,0,0,0.20)',
            boxShadow: '0px 3px 4px rgba(0,0,0,0.14), 0px 3px 3px rgba(0,0,0,0.12),  0px 1px 8px rgba(0,0,0,0.20)',
          }}
          formatter={(value, name, entry): string[] => [
            `${
              entry.payload.disable
                ? '- Not given yet -'
                : `Savings: ${formatNumber(value as number | string)} ${currency} / day`
            }`,
          ]}
        />
      )}
      <CartesianGrid horizontal={false} />
      <Bar dataKey="savings" isAnimationActive={false}>
        {data.map((entry) => (
          <Cell fill={entry.highlight ? barHighlightColor : barColor} key={`cell-${entry.savingType}`} />
        ))}
      </Bar>
      <ReferenceLine x={0} stroke="#000" isFront />
    </BarChart>
  );
}

export default SavingsChart;
