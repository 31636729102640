import React, { ReactElement } from 'react';

import { F55SweetWaterEvaporationCostState } from 'state/selectors';

import EvaporationComponent, { EvaporationComponentProps } from 'components/Evaporation';

const F55EvapSW = (): ReactElement => {
  const props: EvaporationComponentProps = {
    translationKey: 'F55EvapSweetWater',
    evaporatorTypeKey: 'F55SweetWaterEvaporatorType',
    electricityPriceKey: 'F55SweetWaterElectricityPrice',
    electricityConsumptionKey: 'F55SweetWaterElectricityConsumption',
    effectsKey: 'F55SweetWaterEffects',
    steamPriceKey: 'F55SweetWaterSteamPrice',
    evaporationCostState: F55SweetWaterEvaporationCostState,
  };

  return <EvaporationComponent {...props} />;
};

export default F55EvapSW;
