import { ConcPreset, PricePreset, Scenario, ScenarioDataKey } from 'scenario/ScenarioType';
import getDefaultPrices from 'scenario/defaultPrices';
import defaultConcentrations from 'scenario/defaultConc';

export type ScenarioEffect = (activeScenario: Scenario) => Scenario;

export type ScenarioEffects = Partial<Record<ScenarioDataKey, ScenarioEffect>>;

const setPriceAndConc = (
  scenario: Scenario,
  valueKey: ScenarioDataKey,
  priceKey: ScenarioDataKey,
  concKey: ScenarioDataKey
): Scenario => {
  const defaultPrices = getDefaultPrices(scenario.region);
  const value = scenario.data[valueKey];

  if (value === undefined) return scenario;

  return {
    ...scenario,
    data: {
      ...scenario.data,
      [priceKey]: defaultPrices[value as keyof PricePreset],
      [concKey]: defaultConcentrations[value as keyof ConcPreset],
    },
  };
};

const F55MgType: ScenarioEffect = (activeScenario) => {
  return setPriceAndConc(activeScenario, 'F55MgType', 'F55MgPrice', 'F55MgConc');
};

const F55SO2Type: ScenarioEffect = (activeScenario) => {
  return setPriceAndConc(activeScenario, 'F55SO2Type', 'F55SO2Price', 'F55SO2Conc');
};

const F55BaseType: ScenarioEffect = (activeScenario) => {
  return setPriceAndConc(activeScenario, 'F55BaseType', 'F55BasePrice', 'F55BaseConc');
};

const F55AcidType: ScenarioEffect = (activeScenario) => {
  return setPriceAndConc(activeScenario, 'F55AcidType', 'F55AcidPrice', 'F55AcidConc');
};

const F55IXRegenChemCat: ScenarioEffect = (activeScenario) => {
  return setPriceAndConc(activeScenario, 'F55IXRegenChemCat', 'F55IXChemPriceCat', 'F55IXChemConcentrationCat');
};

const F55IXRegenChemAn: ScenarioEffect = (activeScenario) => {
  return setPriceAndConc(activeScenario, 'F55IXRegenChemAn', 'F55IXChemPriceAn', 'F55IXChemConcentrationAn');
};

const updaters: ScenarioEffects = {
  F55MgType,
  F55SO2Type,
  F55BaseType,
  F55AcidType,
  F55IXRegenChemCat,
  F55IXRegenChemAn,
};

const defaultEffect: ScenarioEffect = (activeScenario) => activeScenario;

const getScenarioEffect = (key: ScenarioDataKey): ScenarioEffect => {
  return Object.entries(updaters).find(([selectorKey]) => selectorKey === key)?.[1] || defaultEffect;
};

export default getScenarioEffect;
