import React, { ChangeEvent, ReactElement, useState } from 'react';
import { useRecoilState, useRecoilTransactionObserver_UNSTABLE, useRecoilValue, useSetRecoilState } from 'recoil';
import { OutlinedInputWithLabel, SelectWithLabel } from '@novozymes/components';
import { withStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import { FormControl, Box, FormControlLabel, Typography, Fade } from '@material-ui/core';
import RadioGroup from '@material-ui/core/RadioGroup';
import { useTranslation } from 'react-i18next';
import getAtomState, { scenarioErrorState, shouldShowPreviousState } from './atomState';
import { ScenarioDataKey } from '../scenario/ScenarioType';

type useInputWithStateProps<T> = {
  key: ScenarioDataKey;
  label?: string;
  type?: 'TextField' | 'Select' | 'Radio';
  selectOptions?: {
    value?: string | number;
    label: string;
  }[];
  placeholder?: string;
  unit?: string;
  enableShowPrevious?: boolean;
};

const ColoredRadio = withStyles({
  root: {
    color: 'rgba(0, 0, 0, 0.67)',
    '&$checked': {
      color: 'rgba(0, 0, 0, 0.67)',
    },
  },
  checked: {},
})((props: RadioProps) => <Radio size="small" color="default" {...props} />);

const useStyles = makeStyles((theme: Theme) => ({
  radioButtonHeading: {
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: theme.spacing(0.5),
  },
  radioButtonLabel: {
    fontSize: '14px',
    lineHeight: '20px',
  },
}));

function useInputWithState<T extends string | number | string[] | undefined>({
  key,
  label,
  type = 'TextField',
  selectOptions,
  placeholder = '',
  enableShowPrevious = false,
  unit,
}: useInputWithStateProps<T>): [T | undefined, ReactElement | null] {
  const state = getAtomState<T>(key);
  const [value, setValue] = useRecoilState(state);
  const errorState = useRecoilValue(scenarioErrorState);
  const error = errorState[key];
  const classes = useStyles();

  const [previousValue, setPreviousValue] = useState<T | undefined>(undefined);
  const [showPrevious, setShowPrevious] = useState(false);
  const [timer, setTimer] = useState<NodeJS.Timeout | undefined>(undefined);
  const setShouldShowPrevious = useSetRecoilState(shouldShowPreviousState);

  const { t } = useTranslation('inputField');

  const getValueAsType = (changeValue: any): T => {
    switch (typeof value) {
      case 'number':
        return Number(changeValue) as T;
      case 'string':
        return `${changeValue}` as T;
      default:
        return changeValue;
    }
  };

  useRecoilTransactionObserver_UNSTABLE(({ snapshot, previousSnapshot }) => {
    const prevState = previousSnapshot.getLoadable(state);
    const curState = snapshot.getLoadable(state);
    const curShouldShow = snapshot.getLoadable(shouldShowPreviousState);

    if (prevState.state === 'hasValue' && getValueAsType(curState.contents) !== getValueAsType(prevState.contents)) {
      setPreviousValue(prevState.contents);

      if (curShouldShow.state === 'hasValue' && !curShouldShow.contents) {
        return;
      }

      if (timer) {
        clearTimeout(timer);
      }
      setShowPrevious(true);

      const newTimer = setTimeout(() => {
        setShowPrevious(false);
        setShouldShowPrevious(false);
      }, 3000);
      setTimer(newTimer);
    } else {
      setShouldShowPrevious(false);
    }
  });

  const getInput = (): ReactElement | null => {
    if (type === 'TextField') {
      return (
        <>
          <OutlinedInputWithLabel
            key={label}
            label={label}
            value={value !== undefined ? (value as string | number) : ''}
            error={error?.hasError}
            errorMessage={error?.message}
            placeholder={placeholder}
            adornment={unit}
            handleChange={(event: ChangeEvent<HTMLInputElement>): void => {
              const { value: newValue } = event.target;
              setValue(newValue as unknown as T);
            }}
            formatInput
            decimalSeparator="."
          />
          {enableShowPrevious && (
            <Box ml={2} mb="-14px">
              <Fade in={showPrevious && !!previousValue} timeout={500}>
                <Typography variant="caption">{`${t('previous')}: ${previousValue}`}</Typography>
              </Fade>
            </Box>
          )}
        </>
      );
    }
    if (type === 'Select' && selectOptions) {
      return (
        <SelectWithLabel
          value={placeholder ? (value as string | number) : (value as string | number) || ' '}
          label={label}
          options={selectOptions}
          placeholder={placeholder}
          error={error?.hasError}
          errorMessage={error?.message}
          handleChange={(
            event: ChangeEvent<{
              name?: string | undefined;
              value: unknown;
            }>
          ): void => {
            setValue(getValueAsType(event.target.value));
          }}
        />
      );
    }

    if (type === 'Radio' && selectOptions) {
      return (
        <FormControl component="fieldset">
          {label && <Box className={classes.radioButtonHeading}>{label}</Box>}
          <RadioGroup
            aria-label={label}
            name={label}
            value={value ? `${value}` : ' '}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
              setValue(getValueAsType(event.target.value));
            }}
          >
            {selectOptions.map((option) => (
              <FormControlLabel
                key={option.value as string}
                value={option.value}
                control={<ColoredRadio />}
                label={option.label}
                labelPlacement="end"
                classes={{
                  label: classes.radioButtonLabel,
                }}
              />
            ))}
          </RadioGroup>
        </FormControl>
      );
    }
    return null;
  };

  const input = getInput();

  return [value, input];
}
export default useInputWithState;
