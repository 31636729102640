import { ENZYME_RANGES } from 'scenario/enzymeData';
import { ModelName } from 'scenario/ScenarioType';
import { DosageState } from 'state/tempAtoms';
import roundNumber from 'utils/roundNumber';
import { TIMESTEPS } from './enzymeConstants';
import models from './enzymeModels/Models';

interface FitDosageInput {
  modelName: ModelName;
  enzymeDs: number;
  targetDX: number;
  targetTime: number;
}

const FitDosage = ({ modelName, enzymeDs, targetDX, targetTime }: FitDosageInput): number | DosageState => {
  const model = models[modelName];
  const dosageRange = ENZYME_RANGES[modelName].dosage;

  if (targetTime < TIMESTEPS[0] || TIMESTEPS[TIMESTEPS.length - 1] < targetTime) {
    return 'out of range';
  }

  if (targetDX < 0 || targetDX > 100) {
    return 'out of range';
  }

  const timeLowerIndex = TIMESTEPS.findIndex((time, index) => time <= targetTime && targetTime <= TIMESTEPS[index + 1]);
  const timeHigherIndex = timeLowerIndex + 1;

  const timeLower = TIMESTEPS[timeLowerIndex];
  const timeHigher = TIMESTEPS[timeHigherIndex];

  for (let dosage = dosageRange.min; dosage <= dosageRange.max; dosage += 0.0001) {
    const modelOutput = model({ dosage, enzymeDs });

    const modelDataPointLow = modelOutput[timeLowerIndex];
    const modelDataPointHigh = modelOutput[timeHigherIndex];

    if (
      dosage === dosageRange.min &&
      modelDataPointLow +
        (modelDataPointHigh - modelDataPointLow) * ((targetTime - timeLower) / (timeHigher - timeLower)) >
        targetDX
    ) {
      return 'low';
    }

    if (
      modelDataPointLow +
        (modelDataPointHigh - modelDataPointLow) * ((targetTime - timeLower) / (timeHigher - timeLower)) >
      targetDX
    ) {
      return roundNumber(dosage, 4);
    }
  }

  return 'high';
};

export default FitDosage;
