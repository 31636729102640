import React, { ReactElement } from 'react';
import { Box, Link, Typography, makeStyles, Theme } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { colors } from '@novozymes/components';
import zIndexes from 'theme/zIndexes';

// TODO: Add feedback form
const FEEDBACK_URL =
  // eslint-disable-next-line max-len
  'https://forms.office.com/Pages/ResponsePage.aspx?id=nvTVQzrgIk2iKFaEGWuwAcKUW-qTMKxDr4iWpa8wTKhUQTVKSzZUNEJGVEtDWjkyUjlZS1hWUUZFTS4u';

const useStyles = makeStyles((theme: Theme) => ({
  banner: {
    backgroundColor: colors.primaryGreen,
    display: 'inline-flex',
    alignItems: 'center',
    borderTop: '1px solid #888',
    zIndex: zIndexes.high,
  },
  bannerText: {
    fontSize: '0.95em',
    fontWeight: 700,
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  link: {
    color: colors.black,
    fontWeight: 700,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const FeedbackBanner = (): ReactElement => {
  const classes = useStyles();
  return (
    <Box px={2} py={1} className={classes.banner}>
      <InfoIcon fontSize="small" htmlColor={colors.black} className={classes.icon} />
      <Typography className={classes.bannerText}>
        This is a BETA version. We&apos;d love to get your{' '}
        <Link
          href={FEEDBACK_URL}
          classes={{
            root: classes.link,
          }}
          target="_blank"
          underline="always"
        >
          feedback
        </Link>
        .
      </Typography>
    </Box>
  );
};

export default FeedbackBanner;
