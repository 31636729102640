export const getHomeLink = (page?: string): string => {
  return `${process.env.REACT_APP_HOME_URL}${page || ''}`;
};

const navigateHome = (): void => {
  // TODO only show the confirm if there are any changes
  // eslint-disable-next-line no-undef
  if (
    process.env.REACT_APP_HOME_URL &&
    window.confirm('Are you sure you want leave? Any unsaved changes will be lost.')
  ) {
    // eslint-disable-next-line no-undef
    window.location.assign(process.env.REACT_APP_HOME_URL);
  }
};

export default navigateHome;
