import { ProductionSteps, Scenario, ScenarioData, Operation, Base, Acid } from './ScenarioType';

const defaultData: Partial<ScenarioData> = {
  productionSteps: ProductionSteps.none,
  evaporatorType: 'MVR',
  electricityConsumption: 35,
  effects: '4+',
  F55SepWaterEvaporatorType: 'TVR',
  F55SepWaterElectricityConsumption: 35,
  F55SepWaterElectricityPrice: 0.09,
  F55SepWaterEffects: '3',
  F55SweetWaterEvaporatorType: 'MVR',
  F55SweetWaterElectricityConsumption: 35,
  F55SweetWaterElectricityPrice: 0.09,
  F55SweetWaterEffects: '3',
  evapDSFinal: 70,
  dmhYieldCentri: 50,
  dmhEvapDsProcent: 78,
  dmhEvapDxProcent: 85,
  dmhDsDay: 115,
  dmhDsProcent: 87,
  dmhDxProcent: 99.5,
  F55Capacity: 250,
  F55RefDS: 50,
  F55DP2PlusRecovery: 5,
  F55FructoseRecovery: 71,
  F55DilutionWater: 1.8,
  refFxProcentageMixing2: 90,
  refFxProcentageIso: 45,
  refFxProcentageF55: 55,
  F55RefTemperature: 55,
  F55MgDosage: 45,
  F55SO2Dosage: 200,
  F55IXOperationCat: Operation['Counter current'],
  F55IXOperationAn: Operation['Co-current'],
  F55IXResinCapCat: 1.2,
  F55IXResinCapAn: 0.8,
  F55IXResinLifeCat: 700,
  F55IXResinLifeAn: 450,
  F55IXResinPriceCat: 2,
  F55IXResinPriceAn: 4,
  F55IXRegenChemCat: Acid.HCl,
  F55IXRegenChemAn: Base.NaOH,
  F55IXChemConcentrationCat: 32,
  F55IXChemConcentrationAn: 50,
  F55IXChemPriceCat: 0.115,
  F55IXChemPriceAn: 0.355,
  F55IXChemConsumpCat: 2.5,
  F55IXChemConsumpAn: 1.5,
  F55IXWaterCat: 2,
  F55IXWaterAn: 2,
  F55IXSweetWaterDilCat: 2,
  F55IXSweetWaterDilAn: 2,
  F55IXWasteWaterCat: 6,
  F55IXWasteWaterAn: 6,
  F55IXCrossRegenCat: 1,
  F55IXCrossRegenAn: 1,
  F55IXWaterPrice: 1,
  F55IXWasteWaterCost: 0.7,
};

const defaultScenario: Scenario = {
  title: 'New Scenario',
  data: defaultData,
  updatedAt: Date.now(),
};

export default defaultScenario;
