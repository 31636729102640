export interface CalcDMHInput {
  DMHYield: number;
  dailyDSDMH: number;
  procentageDMHDS: number;
  procentageDMHDX: number;
  procentageDSEvap: number;
  procentageDXEvap: number;
  procentageDXSacc: number;
  procentageDSSacc: number;
}

export interface CalcDMHOutput {
  totalDMH: number;
  dailyDXDMH: number;
  totalEvap: number;
  dailyDSEvap: number;
  dailyDXEvap: number;
  totalCrystal: number;
  dailyDSCrystal: number;
  procentageDSCrystal: number;
  dailyDXCrystal: number;
  procentageDXCrystal: number;
  totalCentrif: number;
  dailyDSCentrif: number;
  procentageDSCentrif: number;
  dailyDXCentrif: number;
  procentageDXCentrif: number;
  totalHydrolTot: number;
  dailyDSHydrolTot: number;
  procentageDSHydrolTot: number;
  dailyDXHydrolTot: number;
  procentageDXHydrolTot: number;
  dailyDSSacc: number;
  totalSacc: number;
  dailyDXSacc: number;
  totalHydrolRec: number;
  dailyDSHydrolRec: number;
  procentageDSHydrolRec: number;
  dailyDXHydrolRec: number;
  procentageDXHydrolRec: number;
  totalHydrolOut: number;
  dailyDSHydrolOut: number;
  procentageDSHydrolOut: number;
  dailyDXHydrolOut: number;
  procentageDXHydrolOut: number;
}

const calcDMH = ({
  DMHYield,
  dailyDSDMH,
  procentageDMHDS,
  procentageDMHDX,
  procentageDSEvap,
  procentageDXEvap,
  procentageDXSacc,
  procentageDSSacc,
}: CalcDMHInput): CalcDMHOutput => {
  const totalDMH = (dailyDSDMH * 100) / procentageDMHDS;
  const dailyDXDMH = (dailyDSDMH * procentageDMHDX) / 100;

  const dailyDSEvap = (dailyDSDMH * 100) / DMHYield;
  const totalEvap = (dailyDSEvap * 100) / procentageDSEvap;
  const dailyDXEvap = (dailyDSEvap * procentageDXEvap) / 100;

  const totalCrystal = totalEvap;
  const dailyDSCrystal = dailyDSEvap;
  const procentageDSCrystal = procentageDSEvap;
  const dailyDXCrystal = dailyDXEvap;
  const procentageDXCrystal = procentageDXEvap;

  const totalCentrif = totalCrystal;
  const dailyDSCentrif = dailyDSCrystal;
  const procentageDSCentrif = procentageDSCrystal;
  const dailyDXCentrif = dailyDXCrystal;
  const procentageDXCentrif = procentageDXCrystal;

  const totalHydrolTot = totalCentrif - totalDMH;
  const dailyDSHydrolTot = dailyDSCentrif - dailyDSDMH;
  const procentageDSHydrolTot = (dailyDSHydrolTot * 100) / totalHydrolTot;
  const dailyDXHydrolTot = dailyDXCentrif - dailyDXDMH;
  const procentageDXHydrolTot = (dailyDXHydrolTot * 100) / dailyDSHydrolTot;

  const dailyDSSacc =
    (dailyDSEvap * (procentageDXEvap - procentageDXHydrolTot)) / (procentageDXSacc - procentageDXHydrolTot);
  const totalSacc = (dailyDSSacc * 100) / procentageDSSacc;
  const dailyDXSacc = (dailyDSSacc * procentageDXSacc) / 100;

  const dailyDSHydrolRec = dailyDSEvap - dailyDSSacc;
  const dailyDXHydrolRec = dailyDXEvap - dailyDXSacc;
  const procentageDSHydrolRec = procentageDSHydrolTot;
  const totalHydrolRec = (dailyDSHydrolRec * 100) / procentageDSHydrolRec;
  const procentageDXHydrolRec = procentageDXHydrolTot;

  const totalHydrolOut = totalHydrolTot - totalHydrolRec;
  const dailyDSHydrolOut = dailyDSHydrolTot - dailyDSHydrolRec;
  const procentageDSHydrolOut = procentageDSHydrolTot;
  const dailyDXHydrolOut = dailyDXHydrolTot - dailyDXHydrolRec;
  const procentageDXHydrolOut = procentageDXHydrolTot;

  return {
    totalDMH,
    dailyDXDMH,

    totalEvap,
    dailyDSEvap,
    dailyDXEvap,

    totalCrystal,
    dailyDSCrystal,
    procentageDSCrystal,
    dailyDXCrystal,
    procentageDXCrystal,

    totalCentrif,
    dailyDSCentrif,
    procentageDSCentrif,
    dailyDXCentrif,
    procentageDXCentrif,

    totalHydrolTot,
    dailyDSHydrolTot,
    procentageDSHydrolTot,
    dailyDXHydrolTot,
    procentageDXHydrolTot,

    dailyDSSacc,
    totalSacc,
    dailyDXSacc,

    totalHydrolRec,
    dailyDSHydrolRec,
    procentageDSHydrolRec,
    dailyDXHydrolRec,
    procentageDXHydrolRec,

    totalHydrolOut,
    dailyDSHydrolOut,
    procentageDSHydrolOut,
    dailyDXHydrolOut,
    procentageDXHydrolOut,
  };
};
export default calcDMH;
