/* eslint-disable react/display-name */
import React, { ReactElement } from 'react';
import { View, Text } from '@react-pdf/renderer';

export const renderIfNotUndefined =
  (step: number, values: any[]) =>
  (Component: ReactElement): ReactElement => {
    if (values.findIndex((val) => val === undefined) !== -1) {
      return (
        <View>
          <Text style={{ color: '#BC0000' }}>Missing data from step {`${step}`}</Text>
        </View>
      );
    }

    return Component;
  };
