interface EnzymeSavingsInput {
  newEnzPrice: number;
  refEnzPrice: number;
  newEnzDose: number;
  refEnzDose: number;
  capacity: number;
}
const enzymeSavings = ({ newEnzPrice, refEnzPrice, newEnzDose, refEnzDose, capacity }: EnzymeSavingsInput): number => {
  return capacity * (refEnzDose * refEnzPrice - newEnzDose * newEnzPrice);
};

export default enzymeSavings;
