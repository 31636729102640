import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import ValueHighlight from 'components/ValueHighlight';
import {
  currency,
  DmhMassBalanceSavingsState,
  DmhNewMassBalanceState,
  DmhRefMassBalanceState,
  DSOutletNew,
  DSOutletRef,
} from 'state/selectors';
import { useRecoilValue } from 'recoil';
import useInputWithState from 'state/useInputWithState';
import { TFunction } from 'i18next';
import { CalcDMHOutput } from 'calc/DMH';
import getAtomState from 'state/atomState';
import { colors } from '@novozymes/components';

type GridRow = {
  label: string;
  totalValue: number | ReactNode;
  dsDayValue: number | ReactNode;
  dxDayValue: number | ReactNode;
  dsProValue: number | ReactNode;
  dxProValue: number | ReactNode;
};

const useStyles = makeStyles(() => ({
  unit: {
    color: colors.black47,
  },
}));

const ValueHighlightOrInput = (props: any): ReactElement => {
  const { value } = props;
  return <>{typeof value !== 'object' ? <ValueHighlight decimals={1} {...props} /> : value}</>;
};

const massBalanceToGridRows = (
  massBal: CalcDMHOutput | undefined,
  t: TFunction,
  saccDsPro: number,
  saccDxPro: number | undefined,
  evapDs: number | ReactNode,
  evapDx: number | ReactNode,
  dmhDsDay: number | ReactNode,
  dmhDsPro: number | ReactNode,
  dmhDxPro: number | ReactNode
): GridRow[] => {
  return [
    {
      label: t('sacch'),
      totalValue: massBal?.totalSacc,
      dsDayValue: massBal?.dailyDSSacc,
      dxDayValue: massBal?.dailyDXSacc,
      dsProValue: saccDsPro,
      dxProValue: saccDxPro,
    },
    {
      label: t('hydrolRtn'),
      totalValue: massBal?.totalHydrolRec,
      dsDayValue: massBal?.dailyDSHydrolRec,
      dxDayValue: massBal?.dailyDXHydrolRec,
      dsProValue: massBal?.procentageDSHydrolRec,
      dxProValue: massBal?.procentageDXHydrolRec,
    },
    {
      label: t('evap'),
      totalValue: massBal?.totalEvap,
      dsDayValue: massBal?.dailyDSEvap,
      dxDayValue: massBal?.dailyDXEvap,
      dsProValue: evapDs,
      dxProValue: evapDx,
    },
    {
      label: t('cryst'),
      totalValue: massBal?.totalCrystal,
      dsDayValue: massBal?.dailyDSCrystal,
      dxDayValue: massBal?.dailyDXCrystal,
      dsProValue: massBal?.procentageDSCrystal,
      dxProValue: massBal?.procentageDXCrystal,
    },
    {
      label: t('centri'),
      totalValue: massBal?.totalCentrif,
      dsDayValue: massBal?.dailyDSCentrif,
      dxDayValue: massBal?.dailyDXCentrif,
      dsProValue: massBal?.procentageDSCentrif,
      dxProValue: massBal?.procentageDXCentrif,
    },
    {
      label: t('hydrolTotal'),
      totalValue: massBal?.totalHydrolTot,
      dsDayValue: massBal?.dailyDSHydrolTot,
      dxDayValue: massBal?.dailyDXHydrolTot,
      dsProValue: massBal?.procentageDSHydrolTot,
      dxProValue: massBal?.procentageDXHydrolTot,
    },
    {
      label: t('hydrolOut'),
      totalValue: massBal?.totalHydrolOut,
      dsDayValue: massBal?.dailyDSHydrolOut,
      dxDayValue: massBal?.dailyDSHydrolOut,
      dsProValue: massBal?.procentageDSHydrolOut,
      dxProValue: massBal?.procentageDXHydrolOut,
    },
    {
      label: t('dmh'),
      totalValue: massBal?.totalDMH,
      dsDayValue: dmhDsDay,
      dxDayValue: massBal?.dailyDXDMH,
      dsProValue: dmhDsPro,
      dxProValue: dmhDxPro,
    },
  ];
};

const MassBalanceGrid = ({
  rowData,
  colLabels,
}: {
  rowData: GridRow[];
  colLabels: { label: string; unit: string }[];
}): ReactElement => {
  const classes = useStyles();

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={2} />
      {colLabels.map((lab) => (
        <Grid item xs={2} key={lab.label}>
          <Typography variant="body1">
            {lab.label} <span className={classes.unit}>{lab.unit}</span>
          </Typography>
        </Grid>
      ))}

      {rowData.map((gridRow, index) => {
        return (
          <>
            <Grid item xs={2}>
              <Typography variant="body1">{gridRow.label}</Typography>
            </Grid>
            <Grid item xs={2}>
              <ValueHighlightOrInput value={gridRow.totalValue} />
            </Grid>
            <Grid item xs={2}>
              <ValueHighlightOrInput value={gridRow.dsDayValue} />
            </Grid>
            <Grid item xs={2}>
              <ValueHighlightOrInput value={gridRow.dxDayValue} />
            </Grid>
            <Grid item xs={2}>
              <ValueHighlightOrInput value={gridRow.dsProValue} unit="%" highlight={index === 0} />
            </Grid>
            <Grid item xs={2}>
              <ValueHighlightOrInput value={gridRow.dxProValue} unit="%" highlight={index === 0} />
            </Grid>
          </>
        );
      })}
    </Grid>
  );
};

const MassBalanceTable = (): ReactElement => {
  const activeCurrency = useRecoilValue(currency);

  const { t } = useTranslation('massBalance');

  const [evapDsValue, evapDsInput] = useInputWithState({
    key: 'dmhEvapDsProcent',
    unit: '%',
  });

  const [evapDxValue, evapDxInput] = useInputWithState({
    key: 'dmhEvapDxProcent',
    unit: '%',
  });

  const [dmhDsDayValue, dmhDsDayInput] = useInputWithState({
    key: 'dmhDsDay',
  });

  const [dmhDsProcentValue, dmhDsProcentInput] = useInputWithState({
    key: 'dmhDsProcent',
    unit: '%',
  });

  const [dmhDxProcentValue, dmhDxProcentInput] = useInputWithState({
    key: 'dmhDxProcent',
    unit: '%',
  });

  const refDsPro = useRecoilValue(DSOutletRef);
  const refDxPro = useRecoilValue(getAtomState<number>('refDX'));

  const newDsPro = useRecoilValue(DSOutletNew);
  const newDxPro = useRecoilValue(getAtomState<number>('newDX'));

  const refMass = useRecoilValue(DmhRefMassBalanceState);
  const newMass = useRecoilValue(DmhNewMassBalanceState);

  const refMassRows = massBalanceToGridRows(
    refMass,
    t,
    refDsPro,
    refDxPro,
    evapDsInput,
    evapDxInput,
    dmhDsDayInput,
    dmhDsProcentInput,
    dmhDxProcentInput
  );

  const newMassRows = massBalanceToGridRows(
    newMass,
    t,
    newDsPro,
    newDxPro,
    evapDsValue,
    evapDxValue,
    dmhDsDayValue,
    dmhDsProcentValue,
    dmhDxProcentValue
  );

  const colLabels = [
    { label: t('total'), unit: `t/${t('day')}` },
    { label: 'DS', unit: `t/${t('day')}` },
    { label: 'DX', unit: `t/${t('day')}` },
    { label: 'DS', unit: `%` },
    { label: 'DX', unit: `%` },
  ];

  const dmhSavings = useRecoilValue(DmhMassBalanceSavingsState);

  return (
    <>
      <Grid container spacing={6} alignItems="center">
        <Grid item xs={6}>
          <Typography variant="h2">{t('refBalanceTitle')}</Typography>
          <Box mb={6} />
          <MassBalanceGrid rowData={refMassRows} colLabels={colLabels} />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h2">{t('newBalanceTitle')}</Typography>
          <Box mb={6} />
          <MassBalanceGrid rowData={newMassRows} colLabels={colLabels} />
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={6}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={2} />
            <Grid item xs={2}>
              <Typography variant="body1">{t('saving')}</Typography>
            </Grid>
            <Grid item xs={4}>
              <ValueHighlight
                value={dmhSavings?.DMHDailySavings}
                highlight
                decimals={1}
                unit={`${activeCurrency} / day`}
              />
            </Grid>
            <Grid item xs={4}>
              <ValueHighlight
                value={dmhSavings?.DMHDailySavingsPerTonDS}
                highlight
                decimals={1}
                unit={`${activeCurrency} / t DS`}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default MassBalanceTable;
