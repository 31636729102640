import { Grid, Typography } from '@material-ui/core';
import ValueHighlight from 'components/ValueHighlight';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { ProductionSteps } from 'scenario/ScenarioType';
import getAtomState from 'state/atomState';
import {
  currency,
  DmhMassBalanceSavingsState,
  enzymeSavingsState,
  evaporationSavingsState,
  F55DilWaterSavingsState,
  ionExchangeSavingsState,
} from 'state/selectors';
import { isDMHDisabled, isF55Disabled } from 'utils/isProcessStepDisabled';

const SavingsDetails = (): ReactElement => {
  const activeCurrency = useRecoilValue(currency);
  const { t } = useTranslation('savingsDetail');

  const productionSteps = useRecoilValue(getAtomState<ProductionSteps>('productionSteps'));
  const dmhDisabled = isDMHDisabled(productionSteps);
  const f55Disabled = isF55Disabled(productionSteps);

  const DMHSavings = useRecoilValue(DmhMassBalanceSavingsState);
  const DMHDailySavings = DMHSavings?.DMHDailySavings;
  const evaporatonSavings = useRecoilValue(evaporationSavingsState);
  const F55WaterSavings = useRecoilValue(F55DilWaterSavingsState);
  const enzymeSavings = useRecoilValue(enzymeSavingsState);

  const F55IXSavings = useRecoilValue(ionExchangeSavingsState)?.totalDailySavingsF55;

  const unit = `${activeCurrency} / day`;
  return (
    <>
      <Typography variant="h2">{t('title')}</Typography>

      <Grid container>
        <Grid item xs={8}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={3}>
              <Typography variant="body1">{t('newEnzyme')}</Typography>
            </Grid>
            <Grid item xs={3}>
              <ValueHighlight value={enzymeSavings} unit={unit} />
            </Grid>
            {!f55Disabled && (
              <>
                <Grid item xs={3}>
                  <Typography variant="body1">{t('f55Water')}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <ValueHighlight value={F55WaterSavings} unit={unit} />
                </Grid>
              </>
            )}

            <Grid item xs={3}>
              <Typography variant="body1">{t('evap')}</Typography>
            </Grid>
            <Grid item xs={3}>
              <ValueHighlight value={evaporatonSavings} unit={unit} />
            </Grid>
            {!f55Disabled && (
              <>
                <Grid item xs={3}>
                  <Typography variant="body1">{t('f55Ion')}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <ValueHighlight value={F55IXSavings} unit={unit} />
                </Grid>
              </>
            )}
            {!dmhDisabled && (
              <>
                <Grid item xs={3}>
                  <Typography variant="body1">{t('de95')}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <ValueHighlight value={DMHDailySavings} unit={unit} />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SavingsDetails;
