import EnzymeModel from './enzymeModelType';

export const ExtendaStandard: EnzymeModel = (input) => {
  // Also known as NZ280404
  const DSgns = 34;
  const DScur = input.enzymeDs;
  const DoseGns = 0.21856;
  const scaleFactor = 725 / 1000;
  const DOSEcur = input.dosage * scaleFactor;

  const data = {
    '6H': [
      67.71941258, -0.414954206, 0, 0, -5.181214667, -0.028303673, -0.515130599, 147.7777643, -189.3121196,
      -23.60238303, 0,
    ],
    '12H': [
      92.84435391, -0.438069529, -0.014917213, 0, -6.550487317, -0.03940512, -0.339182672, 148.9309686, -360.9151662,
      316.158512, 0,
    ],
    '18H': [
      101.2914611, -0.358439037, -0.011547516, 0, -5.678014065, -0.096557622, 0.493528616, 121.5018614, -495.4343776,
      779.2976223, 0,
    ],
    '24H': [
      103.2763855, -0.291964151, -0.01202878, 0, -3.69787054, -0.110931272, 0.866799544, 85.65345491, -469.3236554,
      924.0927625, 0,
    ],
    '36H': [
      103.4805374, -0.229539959, -0.005678585, 0, -1.227832698, -0.085643559, 0.87743708, 31.63849436, -301.3325,
      1467.554797, -2693.125177,
    ],
    '48H': [
      100.586776, -0.135530419, -0.003877294, -0.001911249, -0.414181459, -0.040649092, 0.499382669, 12.04212417,
      -157.5466801, 815.1913651, -1468.513033,
    ],
    '60H': [
      102.1587767, -0.18168641, -0.004992064, 0, -0.227540652, 0, 0.173024211, 6.630482689, -72.23837013, 167.2044682,
      0,
    ],
    '72H': [
      100.9610468, -0.149754365, -0.003853444, -0.000772756, -0.010706482, 0.004359024, 0.054462264, 1.807513919,
      -42.85850553, 96.8242904, 0,
    ],
    '96H': [
      101.7111386, -0.182644367, -0.002888981, 0, 0.215312362, 0.026756126, -0.12455462, -2.854071375, -22.6053956,
      44.60827566, 0,
    ],
  };

  const dataValues = Object.values(data);

  const result = dataValues.map(
    (data) =>
      data[0] +
      data[1] * DScur +
      data[2] * (DScur - DSgns) ** 2 +
      data[3] * (DScur - DSgns) ** 3 +
      data[4] +
      data[5] * (DScur - DSgns) +
      data[6] * (DOSEcur - DoseGns) * (DScur - DSgns) +
      data[7] * (DOSEcur - DoseGns) +
      data[8] * (DOSEcur - DoseGns) ** 2 +
      data[9] * (DOSEcur - DoseGns) ** 3 +
      data[10] * (DOSEcur - DoseGns) ** 4
  );

  return result;
};
