interface F55DilutionOfWaterSavingsInput {
  newDailyDSIso: number;
  refDailyDSIso: number;
  dilutionOfWater: number;
  evaporationCost: number;
}

const F55DilutionOfWaterSavings = ({
  newDailyDSIso,
  refDailyDSIso,
  dilutionOfWater,
  evaporationCost,
}: F55DilutionOfWaterSavingsInput): number => {
  return (refDailyDSIso - newDailyDSIso) * dilutionOfWater * evaporationCost;
};

export default F55DilutionOfWaterSavings;
