interface CalcEvaporationInput {
  capacity: number;
  enzDX: number;
  DSOutlet: number;
  finalDS: number;
}
const calcEvaporation = ({ capacity, enzDX, DSOutlet, finalDS }: CalcEvaporationInput): number => {
  return capacity * (1 + 0.11 * ((100 - (100 - enzDX) / 2) / 100)) * 100 * (1 / DSOutlet - 1 / finalDS);
};

export default calcEvaporation;

/*
Original calculations: 

    EvapWatEvap(0) = DM1(CapHDX * (1 + 0.11 * ((100 - (100 - DXHDX0) / 2) / 100)) * 100 *
            (1 / DSEndHDX(0) - 1 / DSOutEvap))
    EvapWatEvap(1) = DM1(CapHDX * (1 + 0.11 * ((100 - (100 - DXHDX1) / 2) / 100)) * 100 *
            (1 / DSEndHDX(1) - 1 / DSOutEvap))

*/
