/* eslint-disable no-undef */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './i18n';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import App from './App';
import starchTheme from './theme/StarchTheme';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={starchTheme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
