/* eslint-disable react/display-name */
import React, { ReactElement, memo, useEffect, useState, useCallback } from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { CircularProgress, useTheme, Theme } from '@material-ui/core';
import { Download } from '@novozymes/components';
import { useRecoilValue } from 'recoil';
import getAtomState, { activeScenarioState } from 'state/atomState';
import calcSteamConsumption from 'calc/helpers/calcSteamConsumption';

import {
  currency,
  densityNew,
  densityRef,
  DmhMassBalanceSavingsState,
  DmhNewMassBalanceState,
  DmhRefMassBalanceState,
  DSOutletNew,
  DSOutletRef,
  DXDevelopmentState,
  enzymePricesGivenState,
  enzymeSavingsState,
  evaporationCostState,
  evaporationNew,
  evaporationRef,
  evaporationSavingsState,
  F55DilWaterSavingsState,
  F55IXReductionSavingsState,
  F55IXRegenSavingsState,
  F55NewMassBalanceState,
  F55RefMassBalanceState,
  F55SepWaterEvaporationCostState,
  F55SweetWaterEvaporationCostState,
  ionExchangeSavingsState,
} from 'state/selectors';
import SavingsChart, { SavingsChartEntry } from 'components/graphs/SavingsChart';
import ButtonWithIcon from 'components/ButtonWithIcon';
import { ProductionSteps, Scenario } from 'scenario/ScenarioType';
import DXChart from 'components/graphs/DXChart';
import { isDMHDisabled, isF55Disabled } from 'utils/isProcessStepDisabled';
import PDFDocument from './PDFDocument';
import GetGraphAsImage from './GetGraphAsImage';

const PDF_FILE_NAME = `Saccharification_report_${Date.now()}.pdf`;

const DownloadOnRender = ({ onDownloaded }: { onDownloaded?: () => void }): ReactElement => {
  useEffect(() => {
    // eslint-disable-next-line no-undef,no-unused-expressions
    document.getElementById('pdf_download')?.click();
    if (onDownloaded) {
      onDownloaded();
    }
  }, []);

  return (
    <div style={{ display: 'none' }} id="pdf_download">
      Download pdf
    </div>
  );
};

const DownloadLink = memo(
  ({
    theme,
    currentScenario,
    calculationResult,
    onDownloaded,
    DXChartImage,
    SavingsChartImage,
    activeCurrency,
  }: {
    linkText?: string;
    theme: Theme;
    currentScenario: Scenario;
    calculationResult: Record<string, any>;
    onDownloaded?: () => void;
    DXChartImage: string;
    SavingsChartImage: string;
    activeCurrency: string;
  }): ReactElement => {
    return (
      <PDFDownloadLink
        document={
          <PDFDocument
            theme={theme}
            currentScenario={currentScenario}
            calculationResult={calculationResult}
            DXChartImage={DXChartImage}
            SavingsChartImage={SavingsChartImage}
            currency={activeCurrency}
          />
        }
        fileName={PDF_FILE_NAME}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100px',
        }}
      >
        {({ loading }): ReactElement =>
          loading ? <CircularProgress color="primary" /> : <DownloadOnRender onDownloaded={onDownloaded} />
        }
      </PDFDownloadLink>
    );
  }
);

const PDFDataWrapper = ({ linkText, disabled }: { linkText?: string; disabled?: boolean }): ReactElement => {
  const theme = useTheme();

  const activeCurrency = useRecoilValue(currency);
  const currentScenario = useRecoilValue(activeScenarioState);

  const refDSOutlet = useRecoilValue(DSOutletRef);
  const newDSOutlet = useRecoilValue(DSOutletNew);
  const refDensity = useRecoilValue(densityRef);
  const newDensity = useRecoilValue(densityNew);
  const refEvaporation = useRecoilValue(evaporationRef);
  const newEvaporation = useRecoilValue(evaporationNew);
  const evaporatonCost = useRecoilValue(evaporationCostState);
  const evaporatonSavings = useRecoilValue(evaporationSavingsState);
  const steamConsumption = calcSteamConsumption(currentScenario.data.effects);
  const dmhSavings = useRecoilValue(DmhMassBalanceSavingsState);
  const F55SepWaterEvaporationCost = useRecoilValue(F55SepWaterEvaporationCostState);
  const F55SepWaterSteamCons = calcSteamConsumption(currentScenario.data.F55SepWaterEffects);
  const F55SweetWaterEvaporationCost = useRecoilValue(F55SweetWaterEvaporationCostState);
  const F55SweetWaterSteamCons = calcSteamConsumption(currentScenario.data.F55SweetWaterEffects);
  const DmhRefMassBalance = useRecoilValue(DmhRefMassBalanceState);
  const DmhNewMassBalance = useRecoilValue(DmhNewMassBalanceState);
  const F55RefMassBalance = useRecoilValue(F55RefMassBalanceState);
  const F55NewMassBalance = useRecoilValue(F55NewMassBalanceState);

  const enzymePricesGiven = useRecoilValue(enzymePricesGivenState);

  const DMHSavings = useRecoilValue(DmhMassBalanceSavingsState);
  const DMHDailySavings = DMHSavings?.DMHDailySavings;
  const F55WaterSavings = useRecoilValue(F55DilWaterSavingsState);

  const enzymeSavingsStateValue = useRecoilValue(enzymeSavingsState);
  const enzymeSavings = enzymePricesGiven ? enzymeSavingsStateValue : 0;

  const F55IXSavings = useRecoilValue(ionExchangeSavingsState)?.totalDailySavingsF55;

  const { newEnzymeName, refEnzymeName } = currentScenario.data;

  const [startDownload, setStartDownload] = useState(false);

  const [DXChartImage, setDXChartImage] = useState('');
  const [SavingsChartImage, setSavingsChartImage] = useState('');

  const DXGraphData = useRecoilValue(DXDevelopmentState);

  const newTime = useRecoilValue(getAtomState<string>('newTime'));
  const newDX = useRecoilValue(getAtomState<string>('newDX'));
  const refTime = useRecoilValue(getAtomState<string>('refTime'));
  const refDX = useRecoilValue(getAtomState<string>('refDX'));

  const newTarget = newTime && newDX ? { time: parseFloat(newTime), dx: parseFloat(newDX) } : undefined;
  const refTarget = refTime && refDX ? { time: parseFloat(refTime), dx: parseFloat(refDX) } : undefined;

  const evapSavings = useRecoilValue(evaporationSavingsState) || 0;
  const dailyDMHSavings = dmhSavings?.DMHDailySavings || 0;
  const f55WaterSepSavings = useRecoilValue(F55DilWaterSavingsState) || 0;

  const productionSteps = useRecoilValue(getAtomState<ProductionSteps>('productionSteps'));
  const dmhDisabled = isDMHDisabled(productionSteps);
  const f55Disabled = isF55Disabled(productionSteps);

  const regenSavings = useRecoilValue(F55IXRegenSavingsState);
  const reducSavings = useRecoilValue(F55IXReductionSavingsState);

  let totalDailySavigs = evapSavings + enzymeSavings;

  if (!dmhDisabled) {
    totalDailySavigs += dailyDMHSavings;
  }

  if (!f55Disabled) {
    totalDailySavigs += f55WaterSepSavings + F55IXSavings;
  }
  const dailySavingsWithoutEnzyme = totalDailySavigs - enzymeSavings;
  const yearlySavingsWithoutEnzyme = dailySavingsWithoutEnzyme * 365;
  const savingsPerDSWithoutEnzyme = dailySavingsWithoutEnzyme / (currentScenario.data.saccCapacity || 1);

  const savingsData: SavingsChartEntry[] = [
    {
      savingType: 'Total savings Σ',
      savings: totalDailySavigs,
      highlight: true,
    },
    { savingType: 'Evaporation', savings: evapSavings },
  ];

  if (!dmhDisabled) {
    totalDailySavigs += dailyDMHSavings;
    savingsData.push({
      savingType: 'DMH',
      savings: dailyDMHSavings,
    });
  }

  if (!f55Disabled) {
    totalDailySavigs += f55WaterSepSavings + F55IXSavings;
    savingsData.push({
      savingType: 'F55 separ. water',
      savings: f55WaterSepSavings,
    });
    savingsData.push({
      savingType: 'F55 Ion ex. & iso.',
      savings: F55IXSavings,
    });
  }

  savingsData.push({
    savingType: 'Sacch. enzyme',
    savings: enzymeSavings,
    disable: !enzymePricesGiven,
  });

  useEffect(() => {
    if (startDownload) {
      GetGraphAsImage(DXChart, {
        data: DXGraphData,
        modelAName: newEnzymeName,
        modelBName: refEnzymeName,
        height: 700,
        width: 700,
        modelAColor: theme.palette.primary.main,
        modelBColor: theme.palette.secondary.main,
        modelATarget: newTarget,
        modelBTarget: refTarget,
      })
        .then((res) => {
          setDXChartImage(res);
        })
        .catch((err) => {
          console.error('Failed to save graph', err);
        });
    }
  }, [startDownload, DXGraphData, newEnzymeName, refEnzymeName, theme, newTarget, refTarget]);

  useEffect(() => {
    if (startDownload) {
      GetGraphAsImage(SavingsChart, {
        data: savingsData,
        toolTipColor: theme.palette.primary.dark,
        barColor: theme.palette.primary.main,
        barHighlightColor: theme.palette.primary.main,
        showToolTip: false,
        currency: activeCurrency,
        height: 700,
        width: 700,
      })
        .then((res) => {
          setSavingsChartImage(res);
        })
        .catch((err) => {
          console.error('Failed to save graph', err);
        });
    }
  }, [startDownload, savingsData]);

  const totalYearlySavigs = totalDailySavigs * 365;

  const calculationResult = {
    refDSOutlet,
    newDSOutlet,
    refDensity,
    newDensity,
    refEvaporation,
    newEvaporation,
    evaporatonCost,
    evaporatonSavings,
    steamConsumption,
    dmhSavings,
    F55SepWaterEvaporationCost,
    F55SepWaterSteamCons,
    F55SweetWaterEvaporationCost,
    F55SweetWaterSteamCons,
    DmhRefMassBalance,
    DmhNewMassBalance,
    F55RefMassBalance,
    F55NewMassBalance,
    dailySavingsWithoutEnzyme,
    yearlySavingsWithoutEnzyme,
    savingsPerDSWithoutEnzyme,
    totalDailySavigs,
    totalYearlySavigs,
    DMHSavings,
    DMHDailySavings,
    F55WaterSavings,
    enzymeSavings,
    regenSavings,
    reducSavings,
    F55IXSavings,
  };

  const handleDownloadClick = useCallback(() => {
    setStartDownload(true);
  }, []);

  const onDownloadedCallback = useCallback(() => {
    setStartDownload(false);
  }, []);

  if (!startDownload || !DXChartImage || !SavingsChartImage) {
    return (
      <ButtonWithIcon
        id="btn-export-pdf"
        icon={<Download />}
        title={linkText || 'Export results'}
        onClick={handleDownloadClick}
        disabled={disabled}
        tooltipTitle="Please save before exporting"
      />
    );
  }

  return (
    <DownloadLink
      onDownloaded={onDownloadedCallback}
      theme={theme}
      currentScenario={currentScenario}
      calculationResult={calculationResult}
      DXChartImage={DXChartImage}
      SavingsChartImage={SavingsChartImage}
      activeCurrency={activeCurrency}
    />
  );
};

export default PDFDataWrapper;
