import React, { ReactElement } from 'react';
import PageTitle from 'components/PageTitle';
import { useTranslation } from 'react-i18next';
import F55Process from 'sections/F55Process';
import DividerWithSpacing from 'components/layout/DividerWithSpacing';
import F55Chemicals from 'sections/F55Chemicals';
import F55EvapSeparationWater from 'sections/F55EvapSeparationWater';
import F55EvapSweetWater from 'sections/F55EvapSweetWater';
import F55ChromSep from 'sections/F55ChromSep';
import F55IonExchange from 'sections/F55IonExchange';

const F55Page = (): ReactElement => {
  const { t } = useTranslation('pageTitles');
  return (
    <>
      <PageTitle title={t('3b')} />
      <F55Process />
      <DividerWithSpacing />
      <F55Chemicals />
      <DividerWithSpacing />
      <F55EvapSeparationWater />
      <DividerWithSpacing />
      <F55ChromSep />
      <DividerWithSpacing />
      <F55EvapSweetWater />
      <DividerWithSpacing />
      <F55IonExchange />
    </>
  );
};

export default F55Page;
