import React, { ReactElement } from 'react';
import { Box, Typography, Grid } from '@material-ui/core';
import useInputWithState from 'state/useInputWithState';
import ValueHighlight from 'components/ValueHighlight';
import { useRecoilValue } from 'recoil';
import { currency, evaporationCostState } from 'state/selectors';
import calcSteamConsumption from 'calc/helpers/calcSteamConsumption';
import { Effects, EvaporatorType } from 'scenario/ScenarioType';
import { useTranslation } from 'react-i18next';

const Evaporation = (): ReactElement => {
  const activeCurrency = useRecoilValue(currency);

  const { t } = useTranslation('evaporationSection');

  const evaporatonCost = useRecoilValue(evaporationCostState);

  const [evaporatorType, evaporatorTypeInput] = useInputWithState<EvaporatorType>({
    key: 'evaporatorType',
    type: 'Radio',
    selectOptions: [
      {
        value: 'MVR',
        label: 'MVR',
      },
      {
        value: 'TVR',
        label: 'TVR',
      },
    ],
  });

  const [, electricityPriceInput] = useInputWithState({
    key: 'electricityPrice',
    unit: `${activeCurrency} / kWh`,
  });
  const [, electricityConsumptionInput] = useInputWithState({
    key: 'electricityConsumption',
    unit: 'kWh / t evap',
  });

  const [effects, effectsInput] = useInputWithState<Effects>({
    key: 'effects',
    type: 'Select',
    selectOptions: [
      {
        value: '1',
        label: '1',
      },
      {
        value: '2',
        label: '2',
      },
      {
        value: '3',
        label: '3',
      },
      {
        value: '4+',
        label: '4+',
      },
    ],
  });
  const [, steamPriceInput] = useInputWithState({
    key: 'steamPrice',
    unit: `${activeCurrency} / t steam`,
  });

  return (
    <Box my={2}>
      <Typography variant="h1">{t('title')}</Typography>
      {evaporatorType === 'TVR' ? (
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Typography variant="body1">{t('evaporatonType')}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body1">{t('TVR.effectsHeading')}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body1">{t('TVR.steamConsumptionHeading')}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body1">{t('TVR.steamPriceHeading')}</Typography>
          </Grid>

          <Grid item xs={2}>
            <Typography variant="body1">{t('cost')}</Typography>
          </Grid>
          <Grid item xs={2} />

          <Grid item xs={2}>
            {evaporatorTypeInput}
          </Grid>
          <Grid item xs={2}>
            <Box pt={2}>{effectsInput}</Box>
          </Grid>
          <Grid item xs={2}>
            <Box pt={2}>
              <ValueHighlight value={calcSteamConsumption(effects)} unit="t steam / t evap" />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box pt={2}>{steamPriceInput}</Box>
          </Grid>
          <Grid item xs={2}>
            <Box pt={2}>
              <ValueHighlight value={evaporatonCost} unit={`${activeCurrency} / kWh`} />
            </Box>
          </Grid>
          <Grid item xs={2} />
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Typography variant="body1">{t('evaporatonType')}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body1">{t('MVR.electricityConsumptionHeading')}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body1">{t('MVR.electricityPriceHeading')}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body1">{t('cost')}</Typography>
          </Grid>
          <Grid item xs={2} />

          <Grid item xs={2} />
          <Grid item xs={2}>
            {evaporatorTypeInput}
          </Grid>
          <Grid item xs={2}>
            <Box pt={2}>{electricityConsumptionInput}</Box>
          </Grid>
          <Grid item xs={2}>
            <Box pt={2}>{electricityPriceInput}</Box>
          </Grid>
          <Grid item xs={2}>
            <Box pt={2}>
              <ValueHighlight value={evaporatonCost} unit={`${activeCurrency} / kWh`} />
            </Box>
          </Grid>
          <Grid item xs={2} />
        </Grid>
      )}
    </Box>
  );
};

export default Evaporation;
