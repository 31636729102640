import React, { ReactElement, useCallback, useMemo } from 'react';
import { Box, makeStyles, Theme, Container } from '@material-ui/core';
import { Button } from '@novozymes/components';
import { useLocation, useHistory } from 'react-router-dom';
import { getRouteIndex, nextRouteState, prevRouteState } from 'navigation/processNavigation';
import { activeScenarioState, scenarioErrorState } from 'state/atomState';
import { useRecoilCallback, useSetRecoilState, useRecoilValue } from 'recoil';
import getScenarioValidationErrors, { checkIfValidationErrors } from 'scenario/scenarioValidation';
import { useTranslation } from 'react-i18next';
import zIndexes from 'theme/zIndexes';

const useStyles = makeStyles((theme: Theme) => ({
  footerContainer: {
    position: 'absolute',
    zIndex: zIndexes.medium,
    bottom: 0,
    height: '56px',
    display: 'flex',
    width: '100%',
  },
  footerContent: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
  },
  leftButtons: {
    display: 'inline-flex',
  },
  rightButtons: {
    display: 'inline-flex',
  },
}));

const BottomNav = (): ReactElement => {
  const location = useLocation();
  const history = useHistory();
  const setErrorState = useSetRecoilState(scenarioErrorState);

  const nextRoute = useRecoilValue(nextRouteState(location.pathname));
  const prevRoute = useRecoilValue(prevRouteState(location.pathname));

  const routeIndex = useMemo(() => getRouteIndex(location.pathname), [location.pathname]);
  const validateCurrentScenario = useRecoilCallback(
    ({ snapshot }) =>
      (): boolean | undefined => {
        const snapshotLoadable = snapshot.getLoadable(activeScenarioState);
        if (snapshotLoadable.state === 'hasValue' && routeIndex !== undefined) {
          const currentScenario = snapshotLoadable.contents;
          const errors = getScenarioValidationErrors(routeIndex)(currentScenario.data);
          setErrorState(errors);
          return checkIfValidationErrors(errors);
        }
      },
    [routeIndex]
  );

  const handleNextClick = useCallback(() => {
    const hasErrors = validateCurrentScenario();
    if (!hasErrors && nextRoute) {
      history.push(nextRoute);
    }
  }, [nextRoute]);

  const handleBackClick = useCallback(() => {
    if (prevRoute) {
      history.push(prevRoute);
    }
  }, [prevRoute]);

  const { t } = useTranslation('bottomNav');
  const classes = useStyles();
  return (
    <>
      <Box className={classes.footerContainer}>
        <Container className={classes.footerContent}>
          <Box className={classes.leftButtons}>
            {prevRoute && (
              <>
                <Button id="btn-footer-back" type="secondary" variant="outlined" shadow onClick={handleBackClick} small>
                  {t('back')}
                </Button>
                <Box mr={2} />
              </>
            )}
          </Box>
          <Box className={classes.rightButtons}>
            {nextRoute && (
              <Button id="btn-footer-next" type="primary" onClick={handleNextClick} small shadow>
                {t('next')}
              </Button>
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default BottomNav;
