import { Box, makeStyles } from '@material-ui/core';
import { colors } from '@novozymes/components';
import ErrorPage from 'pages/ErrorPage';
import React, { ReactElement, ReactNode } from 'react';
import Header from './layout/Header';
import ProcessHeader from './layout/ProcessHeader';

type ErrorProps = {
  children?: ReactNode;
};

type ErrorState = {
  hasError: boolean;
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: colors.bg1,
  },
}));

const Root = (props: { children?: ReactNode }): ReactElement => {
  const classes = useStyles();
  const { children } = props;

  return <Box className={classes.root}>{children}</Box>;
};

class ErrorBoundary extends React.Component<ErrorProps, ErrorState> {
  constructor(props: ErrorProps) {
    super(props);
    this.state = { hasError: false };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static getDerivedStateFromError(error: any): ErrorState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render(): ReactNode {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <Root>
            <Header />
            <ProcessHeader />
            <ErrorPage />
          </Root>
        </>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
