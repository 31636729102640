import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';
import { Toolbar, makeStyles, Theme, Box } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import { PROCESS_STEPS, getRouteIndex, isCurrentRouteActive, getCurrentRoute } from 'navigation/processNavigation';
import getAtomState, { activeScenarioState, scenarioErrorState } from 'state/atomState';
import getScenarioValidationErrors, { checkIfValidationErrors } from 'scenario/scenarioValidation';
import { colors, Share, FolderAdd } from '@novozymes/components';
import DownloadLink from 'pdf/DownloadLink';
import ButtonWithIcon from 'components/ButtonWithIcon';
import ShareScenarioDialog from 'components/dialogs/ShareScenarioDialog';
import { isShareEnabled } from 'utils/processUtils';
import SaveDialog from 'components/dialogs/SaveDialog';

const useStyles = makeStyles((theme: Theme) => ({
  processHeaderContainer: {
    paddingLeft: theme.spacing(6),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    borderTop: '1px solid rgba(255, 255, 255, 0.12)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  inlineMenu: {
    display: 'inline-flex',
  },
}));

const useLinkStyles = makeStyles((theme: Theme) => ({
  linkWrapper: {
    marginRight: theme.spacing(1),
  },
  processStepLink: ({
    active,
    disabled,
  }: {
    active: boolean;
    disabled?: boolean;
  }): CreateCSSProperties<{ active: boolean }> => ({
    fontSize: '14px',
    lineHeight: '20px',
    color: disabled ? colors.white65 : theme.palette.common.white,
    fontWeight: active ? 'bold' : 'normal',
    textDecoration: 'none',
    padding: theme.spacing(2),
    '&:hover': disabled
      ? { fontWeight: 'normal', cursor: 'default' }
      : {
          fontWeight: 'bold',
          cursor: 'pointer',
        },
  }),
  underline: {
    height: '2px',
    backgroundColor: fade(theme.palette.common.white, 0.8),
  },
  underlinePlaceholder: {
    height: '2px',
  },
}));

const MenuLink = ({ title, url, disabled }: { title: string; url: string; disabled?: boolean }): ReactElement => {
  const location = useLocation();
  const history = useHistory();
  const urlWithStep = useMemo(() => `/step/${url}`, [url]);
  const active = isCurrentRouteActive(urlWithStep)(location.pathname);

  const setErrorState = useSetRecoilState(scenarioErrorState);

  const menuRoute = useMemo(() => getCurrentRoute(urlWithStep), [location.pathname]);
  const currentRouteIndex = useMemo(() => getRouteIndex(location.pathname), [location.pathname]);
  const menuRouteIndex = useMemo(() => getRouteIndex(urlWithStep), [urlWithStep]);
  const routeIndex = useMemo(() => getRouteIndex(location.pathname), [location.pathname]);

  const classes = useLinkStyles({ active, disabled });

  const validateCurrentScenario = useRecoilCallback(
    ({ snapshot }) =>
      (): boolean | undefined => {
        const snapshotLoadable = snapshot.getLoadable(activeScenarioState);
        if (snapshotLoadable.state === 'hasValue' && routeIndex !== undefined) {
          const currentScenario = snapshotLoadable.contents;
          const errors = getScenarioValidationErrors(routeIndex)(currentScenario.data);
          setErrorState(errors);
          return checkIfValidationErrors(errors);
        }
      },
    [routeIndex]
  );

  const handleLinkClick = useCallback(() => {
    const goingBack =
      menuRouteIndex !== undefined && currentRouteIndex !== undefined ? menuRouteIndex < currentRouteIndex : false;
    const errors = goingBack ? false : validateCurrentScenario();
    if (!errors) {
      if (menuRoute) history.push(menuRoute);
    }
  }, [history, location, url]);

  return (
    <Box className={classes.linkWrapper}>
      <Box key={title} onClick={!disabled ? handleLinkClick : undefined} className={classes.processStepLink}>
        {title}
        {active ? <Box className={classes.underline} /> : <Box className={classes.underlinePlaceholder} />}
      </Box>
    </Box>
  );
};

const ProcessHeader = (): ReactElement => {
  const classes = useStyles();
  const processSteps = useRecoilValue(getAtomState('productionSteps'));
  const activeScenario = useRecoilValue(activeScenarioState);
  const location = useLocation();
  const isStartRoute = useMemo(() => location.pathname === '/', [location.pathname]);
  const [showShare, setShowShare] = useState(false);
  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const handleShareClick = useCallback(() => {
    setShowShare(true);
  }, []);

  const handleShareClose = useCallback(() => {
    setShowShare(false);
  }, []);

  const handleSaveClick = useCallback(() => {
    setSaveModalOpen(true);
  }, []);

  const handleSaveClose = useCallback(() => {
    setSaveModalOpen(false);
  }, []);
  return (
    <>
      <SaveDialog show={saveModalOpen} onClose={handleSaveClose} scenario={activeScenario} />
      <ShareScenarioDialog show={showShare} scenario={activeScenario} onClose={handleShareClose} />
      <Toolbar component="nav" variant="dense" className={classes.processHeaderContainer}>
        <Box className={classes.inlineMenu}>
          {PROCESS_STEPS.map((step) => (
            <MenuLink key={step.title} title={step.title} url={step.url} disabled={step.checkDisabled(processSteps)} />
          ))}
        </Box>
        <Box className={classes.inlineMenu}>
          <DownloadLink linkText="Export PDF" disabled={!activeScenario.id} />
          <Box mx={1} />
          {!isStartRoute && (
            <ButtonWithIcon id="btn-save" title="Save" icon={<FolderAdd />} onClick={handleSaveClick} />
          )}
          <Box mx={1} />
          {!isStartRoute && (
            <ButtonWithIcon
              id="btn-share"
              disabled={!isShareEnabled()}
              title="Share"
              icon={<Share />}
              onClick={handleShareClick}
            />
          )}
        </Box>
      </Toolbar>
    </>
  );
};

export default ProcessHeader;
