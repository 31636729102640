import React, { ComponentType } from 'react';
import ReactDOM from 'react-dom';

const svgToPng = (svg: any, width: number, height: number): Promise<string> => {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');

    if (ctx) {
      ctx.fillStyle = '#ffffff';
      ctx.fillRect(0, 0, width, height);
    }

    // eslint-disable-next-line no-undef
    const xml = new XMLSerializer().serializeToString(svg);
    const dataUrl = `data:image/svg+xml;utf8,${encodeURIComponent(xml)}`;

    // eslint-disable-next-line no-undef
    const img = new Image(width, height);

    img.onload = (): any => {
      if (ctx) {
        ctx.drawImage(img, 0, 0);
        const imageData = canvas.toDataURL('image/png', 1.0);
        resolve(imageData);
      }
    };

    img.onerror = (): any => reject();

    img.src = dataUrl;

    return canvas.toDataURL('image/png');
  });
};

const GetGraphAsImage = (GraphComponent: ComponentType<any>, graphProps: Record<string, any>): Promise<string> =>
  new Promise((resolve, reject) => {
    try {
      const convertChart = async (ref: any): Promise<void> => {
        const width = graphProps.width || 900;
        const height = graphProps.height || 900;

        if (ref && ref.container) {
          const svg = ref.container.children[0];
          const pngData = await svgToPng(svg, width, height);
          resolve(pngData);
        } else {
          reject(new Error("Can't find ref or ref container"));
        }
      };

      const chart = (
        <GraphComponent
          {...graphProps}
          useRef={(ref: any): void => {
            convertChart(ref);
          }}
        />
      );

      // eslint-disable-next-line no-undef
      const helperDiv = document.createElement('tmp');

      ReactDOM.render(chart, helperDiv);
    } catch (err) {
      reject(err);
    }
  });
export default GetGraphAsImage;
