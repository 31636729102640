import { atom } from 'recoil';

export type DosageState = 'high' | 'low' | 'out of range' | undefined;

export const fitDosageStateRef = atom<DosageState>({
  key: 'fitDosageStateRef',
  default: undefined,
});

export const fitDosageStateNew = atom<DosageState>({
  key: 'fitDosageStateNew',
  default: undefined,
});
