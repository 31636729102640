import EnzymeModel from './enzymeModelType';

export const DextrozymeHighDXPlus: EnzymeModel = (input) => {
  const DSgns = 34;
  const DScur = input.enzymeDs;
  const DoseGns = 0.21856;
  const scaleFactor = 360 / 1000;
  const DOSEcur = input.dosage * scaleFactor;

  const data = {
    '6H': [
      67.71941258, -0.414954206, 0, 0, 5.909134719, 0.041431193, -0.871673788, 157.2148674, -669.4152485, -1284.444444,
      0,
    ],
    '12H': [
      92.84435391, -0.438069529, -0.014917213, 0, 6.016227438, 0.063990097, -0.45114069, 151.376746, -803.5634994,
      -582.2222222, 0,
    ],
    '18H': [
      101.2914611, -0.358439037, -0.011547516, 0, 4.211112532, 0.08298106, 0.442428694, 67.93871616, -768.2728389,
      2555.555556, 0,
    ],
    '24H': [
      103.2763855, -0.291964151, -0.01202878, 0, 2.076082992, 0.088609997, 0.777208165, 7.971413461, -277.6031687,
      6202.222222, 0,
    ],
    '36H': [
      103.4805374, -0.229539959, -0.005678585, 0, 0.383818362, 0.056029984, 0.829496027, 8.868211325, 106.9644554,
      -1716.544011, -42316.64992,
    ],
    '48H': [
      100.586776, -0.135530419, -0.003877294, -0.001911249, -0.03191594, 0.025709825, 0.591580599, 3.273828443,
      77.67281845, -1302.692333, -22718.2406,
    ],
    '60H': [
      102.1587767, -0.18168641, -0.004992064, 0, -0.152347625, 0, 0.182689899, -7.742006176, -3.379852351, 895.5555556,
      0,
    ],
    '72H': [
      100.9610468, -0.149754365, -0.003853444, -0.000772756, -0.272238775, -0.0104098, -0.125951194, -9.215353555,
      0.973283581, 573.3333333, 0,
    ],
    '96H': [
      101.7111386, -0.182644367, -0.002888981, 0, -0.467315699, -0.030515333, -0.409945813, -12.5983251, 3.993461323,
      357.7777778, 0,
    ],
  };

  const dataValues = Object.values(data);

  const result = dataValues.map(
    (data) =>
      data[0] +
      data[1] * DScur +
      data[2] * (DScur - DSgns) ** 2 +
      data[3] * (DScur - DSgns) ** 3 +
      data[4] +
      data[5] * (DScur - DSgns) +
      data[6] * (DOSEcur - DoseGns) * (DScur - DSgns) +
      data[7] * (DOSEcur - DoseGns) +
      data[8] * (DOSEcur - DoseGns) ** 2 +
      data[9] * (DOSEcur - DoseGns) ** 3 +
      data[10] * (DOSEcur - DoseGns) ** 4
  );

  return result;
};
