import React, { ReactElement, ReactNode } from 'react';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import ValueHighlight from 'components/ValueHighlight';
import { useTranslation } from 'react-i18next';
import useInputWithState from 'state/useInputWithState';
import { useRecoilValue } from 'recoil';
import { F55NewMassBalanceState, F55RefMassBalanceState } from 'state/selectors';
import getAtomState from 'state/atomState';

interface RowData {
  label: ReactNode;
  DS: ReactNode;
  DP2PlusDaily: ReactNode;
  FxDaily: ReactNode;
  DP1Procentage: ReactNode;
  DP2PlusProcentage: ReactNode;
  FxProcentage: ReactNode;
}

const useGridRowStyles = makeStyles(() => ({
  label: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const GridRow = ({ rowData }: { rowData: RowData }): ReactElement => {
  const classes = useGridRowStyles();
  return (
    <Grid container spacing={2}>
      <Grid item xs={2} className={classes.label}>
        {rowData.label}
      </Grid>
      <Grid item xs={10}>
        <Grid container spacing={1}>
          <Grid item xs={2}>
            {rowData.DS}
          </Grid>
          <Grid item xs={2}>
            {rowData.DP2PlusDaily}
          </Grid>
          <Grid item xs={2}>
            {rowData.FxDaily}
          </Grid>
          <Grid item xs={2}>
            {rowData.DP1Procentage}
          </Grid>
          <Grid item xs={2}>
            {rowData.DP2PlusProcentage}
          </Grid>
          <Grid item xs={2}>
            {rowData.FxProcentage}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
const F55MassBalance = (): ReactElement => {
  const { t } = useTranslation('F55MassBalance');

  const [, refFxProcentageIsoInput] = useInputWithState<number>({
    key: 'refFxProcentageIso',
    unit: '%',
  });
  const [refFxProcentageMixing2, refFxProcentageMixing2Input] = useInputWithState<number>({
    key: 'refFxProcentageMixing2',
    unit: '%',
  });
  const [refFxProcentageF55, refFxProcentageF55Input] = useInputWithState<number>({
    key: 'refFxProcentageF55',
    unit: '%',
  });

  const F55Capacity = useRecoilValue(getAtomState<number>('F55Capacity'));
  const refDX = useRecoilValue(getAtomState<number>('refDX'));
  const newDX = useRecoilValue(getAtomState<number>('newDX'));
  const F55RefMassBalance = useRecoilValue(F55RefMassBalanceState);
  const F55NewMassBalance = useRecoilValue(F55NewMassBalanceState);

  const decimals = 1;
  const refRows: RowData[] = [
    {
      label: <Typography variant="body1">{t('saccharificationLabel')}</Typography>,
      DS: <ValueHighlight decimals={decimals} value={F55Capacity} />,
      DP2PlusDaily: <ValueHighlight decimals={decimals} value={F55RefMassBalance?.dailyDP2PlusSacc} />,
      FxDaily: <ValueHighlight decimals={decimals} value={F55RefMassBalance?.dailyFxSacc} />,
      DP1Procentage: <ValueHighlight decimals={decimals} value={refDX} unit="%" />,
      DP2PlusProcentage: (
        <ValueHighlight decimals={decimals} value={F55RefMassBalance?.procentageDP2PlusSacc} unit="%" />
      ),
      FxProcentage: <ValueHighlight decimals={decimals} value={F55RefMassBalance?.procentageFxSacc} unit="%" />,
    },
    {
      label: <Typography variant="body1">{t('mixing1Label')}</Typography>,
      DS: <ValueHighlight decimals={decimals} value={F55RefMassBalance?.dailyDSMixing1} />,
      DP2PlusDaily: <ValueHighlight decimals={decimals} value={F55RefMassBalance?.dailyDP2PlusMixing1} />,
      FxDaily: <ValueHighlight decimals={decimals} value={F55RefMassBalance?.dailyFxMixing1} />,
      DP1Procentage: <ValueHighlight decimals={decimals} value={F55RefMassBalance?.procentageDP1Mixing1} unit="%" />,
      DP2PlusProcentage: (
        <ValueHighlight decimals={decimals} value={F55RefMassBalance?.procentageDP2PlusMixing1} unit="%" />
      ),
      FxProcentage: <ValueHighlight decimals={decimals} value={F55RefMassBalance?.procentageFxMixing1} unit="%" />,
    },
    {
      label: <Typography variant="body1">{t('isomerisationLabel')}</Typography>,
      DS: <ValueHighlight decimals={decimals} value={F55RefMassBalance?.dailyDSIso} />,
      DP2PlusDaily: <ValueHighlight decimals={decimals} value={F55RefMassBalance?.dailyDP2PlusIso} />,
      FxDaily: <ValueHighlight decimals={decimals} value={F55RefMassBalance?.dailyFxIso} />,
      DP1Procentage: <ValueHighlight decimals={decimals} value={F55RefMassBalance?.procentageDP1Iso} unit="%" />,
      DP2PlusProcentage: (
        <ValueHighlight decimals={decimals} value={F55RefMassBalance?.procentageDP2PlusIso} unit="%" />
      ),
      FxProcentage: refFxProcentageIsoInput,
    },
    {
      label: <Typography variant="body1">{t('chromatographyLabel')}</Typography>,
      DS: <ValueHighlight decimals={decimals} value={F55RefMassBalance?.dailyDSChrom} />,
      DP2PlusDaily: <ValueHighlight decimals={decimals} value={F55RefMassBalance?.dailyDP2PlusChrom} />,
      FxDaily: <ValueHighlight decimals={decimals} value={F55RefMassBalance?.dailyFxChrom} />,
      DP1Procentage: <ValueHighlight decimals={decimals} value={F55RefMassBalance?.procentageDP1Chrom} unit="%" />,
      DP2PlusProcentage: (
        <ValueHighlight decimals={decimals} value={F55RefMassBalance?.procentageDP2PlusChrom} unit="%" />
      ),
      FxProcentage: <ValueHighlight decimals={decimals} value={F55RefMassBalance?.procentageFxChrom} unit="%" />,
    },
    {
      label: <Typography variant="body1">{t('toMixing2Label')}</Typography>,
      DS: <ValueHighlight decimals={decimals} value={F55RefMassBalance?.dailyDSMixing2} />,
      DP2PlusDaily: <ValueHighlight decimals={decimals} value={F55RefMassBalance?.dailyDP2PlusMixing2} />,
      FxDaily: <ValueHighlight decimals={decimals} value={F55RefMassBalance?.dailyFxMixing2} />,
      DP1Procentage: <ValueHighlight decimals={decimals} value={F55RefMassBalance?.procentageDP1Mixing2} unit="%" />,
      DP2PlusProcentage: (
        <ValueHighlight decimals={decimals} value={F55RefMassBalance?.procentageDP2PlusMixing2} unit="%" />
      ),
      FxProcentage: refFxProcentageMixing2Input,
    },
    {
      label: <Typography variant="body1">{t('F42Label')}</Typography>,
      DS: <ValueHighlight decimals={decimals} value={F55RefMassBalance?.dailyDSF42} />,
      DP2PlusDaily: <ValueHighlight decimals={decimals} value={F55RefMassBalance?.dailyDP2PlusF42} />,
      FxDaily: <ValueHighlight decimals={decimals} value={F55RefMassBalance?.dailyFxF42} />,
      DP1Procentage: <ValueHighlight decimals={decimals} value={F55RefMassBalance?.procentageDP1F42} unit="%" />,
      DP2PlusProcentage: (
        <ValueHighlight decimals={decimals} value={F55RefMassBalance?.procentageDP2PlusF42} unit="%" />
      ),
      FxProcentage: <ValueHighlight decimals={decimals} value={F55RefMassBalance?.procentageFxF42} unit="%" />,
    },
    {
      label: <Typography variant="body1">{t('F55Label')}</Typography>,
      DS: <ValueHighlight decimals={decimals} value={F55Capacity} highlight />,
      DP2PlusDaily: <ValueHighlight decimals={decimals} value={F55RefMassBalance?.dailyDP2PlusF55} />,
      FxDaily: <ValueHighlight decimals={decimals} value={F55RefMassBalance?.dailyFxF55} />,
      DP1Procentage: <ValueHighlight decimals={decimals} value={refDX} unit="%" highlight />,
      DP2PlusProcentage: (
        <ValueHighlight decimals={decimals} value={F55RefMassBalance?.procentageDP2PlusF55} unit="%" />
      ),
      FxProcentage: refFxProcentageF55Input,
    },
  ];

  const newRows: RowData[] = [
    {
      label: <Typography variant="body1">{t('saccharificationLabel')}</Typography>,
      DS: <ValueHighlight decimals={decimals} value={F55Capacity} />,
      DP2PlusDaily: <ValueHighlight decimals={decimals} value={F55NewMassBalance?.dailyDP2PlusSacc} />,
      FxDaily: <ValueHighlight decimals={decimals} value={F55NewMassBalance?.dailyFxSacc} />,
      DP1Procentage: <ValueHighlight decimals={decimals} value={newDX} unit="%" />,
      DP2PlusProcentage: (
        <ValueHighlight decimals={decimals} value={F55NewMassBalance?.procentageDP2PlusSacc} unit="%" />
      ),
      FxProcentage: <ValueHighlight decimals={decimals} value={F55NewMassBalance?.procentageFxSacc} unit="%" />,
    },
    {
      label: <Typography variant="body1">{t('mixing1Label')}</Typography>,
      DS: <ValueHighlight decimals={decimals} value={F55NewMassBalance?.dailyDSMixing1} />,
      DP2PlusDaily: <ValueHighlight decimals={decimals} value={F55NewMassBalance?.dailyDP2PlusMixing1} />,
      FxDaily: <ValueHighlight decimals={decimals} value={F55NewMassBalance?.dailyFxMixing1} />,
      DP1Procentage: <ValueHighlight decimals={decimals} value={F55NewMassBalance?.procentageDP1Mixing1} unit="%" />,
      DP2PlusProcentage: (
        <ValueHighlight decimals={decimals} value={F55NewMassBalance?.procentageDP2PlusMixing1} unit="%" />
      ),
      FxProcentage: <ValueHighlight decimals={decimals} value={F55NewMassBalance?.procentageFxMixing1} unit="%" />,
    },
    {
      label: <Typography variant="body1">{t('isomerisationLabel')}</Typography>,
      DS: <ValueHighlight decimals={decimals} value={F55NewMassBalance?.dailyDSIso} />,
      DP2PlusDaily: <ValueHighlight decimals={decimals} value={F55NewMassBalance?.dailyDP2PlusIso} />,
      FxDaily: <ValueHighlight decimals={decimals} value={F55NewMassBalance?.dailyFxIso} />,
      DP1Procentage: <ValueHighlight decimals={decimals} value={F55NewMassBalance?.procentageDP1Iso} unit="%" />,
      DP2PlusProcentage: (
        <ValueHighlight decimals={decimals} value={F55NewMassBalance?.procentageDP2PlusIso} unit="%" />
      ),
      FxProcentage: <ValueHighlight decimals={decimals} value={F55NewMassBalance?.procentageFxIso} />,
    },
    {
      label: <Typography variant="body1">{t('chromatographyLabel')}</Typography>,
      DS: <ValueHighlight decimals={decimals} value={F55NewMassBalance?.dailyDSChrom} />,
      DP2PlusDaily: <ValueHighlight decimals={decimals} value={F55NewMassBalance?.dailyDP2PlusChrom} />,
      FxDaily: <ValueHighlight decimals={decimals} value={F55NewMassBalance?.dailyFxChrom} />,
      DP1Procentage: <ValueHighlight decimals={decimals} value={F55NewMassBalance?.procentageDP1Chrom} unit="%" />,
      DP2PlusProcentage: (
        <ValueHighlight decimals={decimals} value={F55NewMassBalance?.procentageDP2PlusChrom} unit="%" />
      ),
      FxProcentage: <ValueHighlight decimals={decimals} value={F55NewMassBalance?.procentageFxChrom} unit="%" />,
    },
    {
      label: <Typography variant="body1">{t('toMixing2Label')}</Typography>,
      DS: <ValueHighlight decimals={decimals} value={F55NewMassBalance?.dailyDSMixing2} />,
      DP2PlusDaily: <ValueHighlight decimals={decimals} value={F55NewMassBalance?.dailyDP2PlusMixing2} />,
      FxDaily: <ValueHighlight decimals={decimals} value={F55NewMassBalance?.dailyFxChrom} />,
      DP1Procentage: <ValueHighlight decimals={decimals} value={F55NewMassBalance?.procentageDP1Mixing2} unit="%" />,
      DP2PlusProcentage: (
        <ValueHighlight decimals={decimals} value={F55NewMassBalance?.procentageDP2PlusMixing2} unit="%" />
      ),
      FxProcentage: <ValueHighlight decimals={decimals} value={refFxProcentageMixing2} />,
    },
    {
      label: <Typography variant="body1">{t('F42Label')}</Typography>,
      DS: <ValueHighlight decimals={decimals} value={F55NewMassBalance?.dailyDSF42} />,
      DP2PlusDaily: <ValueHighlight decimals={decimals} value={F55NewMassBalance?.dailyDP2PlusF42} />,
      FxDaily: <ValueHighlight decimals={decimals} value={F55NewMassBalance?.dailyFxF42} />,
      DP1Procentage: <ValueHighlight decimals={decimals} value={F55NewMassBalance?.procentageDP1F42} unit="%" />,
      DP2PlusProcentage: (
        <ValueHighlight decimals={decimals} value={F55NewMassBalance?.procentageDP2PlusF42} unit="%" />
      ),
      FxProcentage: <ValueHighlight decimals={decimals} value={F55NewMassBalance?.procentageFxF42} unit="%" />,
    },
    {
      label: <Typography variant="body1">{t('F55Label')}</Typography>,
      DS: <ValueHighlight decimals={decimals} value={F55Capacity} />,
      DP2PlusDaily: <ValueHighlight decimals={decimals} value={F55NewMassBalance?.dailyDP2PlusF55} />,
      FxDaily: <ValueHighlight decimals={decimals} value={F55NewMassBalance?.dailyFxF55} />,
      DP1Procentage: <ValueHighlight decimals={decimals} value={newDX} unit="%" highlight />,
      DP2PlusProcentage: (
        <ValueHighlight decimals={decimals} value={F55NewMassBalance?.procentageDP2PlusF55} unit="%" />
      ),
      FxProcentage: <ValueHighlight decimals={decimals} value={refFxProcentageF55} />,
    },
  ];

  const headings = (
    <Grid container>
      <Grid item xs={2} />

      <Grid item xs={10}>
        <Grid container>
          <Grid item xs={2}>
            <Typography variant="body1">{t('DSLabel')}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body1">{t('dailyDP2PlusLabel')}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body1">{t('dailyFxLabel')}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body1"> {t('percentageDP1Label')}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body1"> {t('percentageDP2PlusLabel')}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body1">{t('percentageFxLabel')}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Box pb={2}>
          <Typography variant="h3">{t('refMassBalanceHeading')}</Typography>
        </Box>
        {headings}
        {refRows.map((row, index) => (
          <GridRow rowData={row} key={'refRows' + index} />
        ))}
      </Grid>
      <Grid item xs={6}>
        <Box pb={2}>
          <Typography variant="h3">{t('newMassBalanceHeading')}</Typography>
        </Box>
        {headings}
        {newRows.map((row, index) => (
          <GridRow rowData={row} key={'newRows' + index} />
        ))}
      </Grid>
    </Grid>
  );
};

export default F55MassBalance;
