import React, { ReactElement } from 'react';
import { Box, makeStyles, Theme } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { colors, NewTab } from '@novozymes/components';

interface LinkToDocsProps {
  text: string;
  fileName: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'inline-flex',
  },
  linkIcon: {
    color: colors.black80,
    marginLeft: theme.spacing(0.5),
    height: '16px',
  },
  linkText: {
    fontSize: '14px',
    lineHeight: '20px',
    color: colors.black80,
    textDecoration: 'underline',
    textDecorationColor: colors.primaryGreen,
    '&:hover': {
      textDecorationColor: colors.primaryGreenHover,
    },
  },
}));

const LinkToDocs = (props: LinkToDocsProps): ReactElement => {
  const { text, fileName } = props;
  const classes = useStyles();
  return (
    <Box id={`link-docs-${fileName}`} className={classes.wrapper}>
      <Link to={`/docs/${fileName}`} target="_blank" className={classes.linkText}>
        {text}
      </Link>
      <NewTab className={classes.linkIcon} />
    </Box>
  );
};

export default LinkToDocs;
