import { Dialog, Typography, DialogTitle, DialogContent, Box, makeStyles, DialogActions } from '@material-ui/core';
import React, { useState, useCallback, useEffect, ReactElement, ChangeEvent } from 'react';
import { Region } from 'scenario/ScenarioType';
import { useRecoilState } from 'recoil';
import { colors, Button, SelectWithLabel } from '@novozymes/components';
import { activeScenarioRegion } from 'state/selectors';
import { useTranslation } from 'react-i18next';
import navigateHome from 'utils/navigateHome';

const dialogStyles = makeStyles(() => ({
  container: {
    backgroundColor: colors.black67,
  },
}));

const MarketSelector = (): ReactElement => {
  const [open, setOpen] = useState(false);
  const [activeRegion, setActiveRegion] = useRecoilState(activeScenarioRegion);
  const [region, setRegion] = useState<string>('');
  const [error, setError] = useState<string>('');

  const { t } = useTranslation('marketSelector');

  const dialogClasses = dialogStyles();
  const handleBack = useCallback(() => {
    setOpen(false);
    navigateHome();
  }, []);

  const handleContinue = useCallback(() => {
    if (region) {
      setOpen(false);
      setError('');
      const parsedRegion = Region[region as keyof typeof Region];
      setActiveRegion(parsedRegion);
    } else {
      setError(t('selectMarketError'));
    }
  }, [activeRegion, region]);

  useEffect(() => {
    if (!activeRegion) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [activeRegion]);

  return (
    <Dialog classes={dialogClasses} disableBackdropClick disableEscapeKeyDown onClose={handleBack} open={open}>
      <DialogTitle disableTypography={false}>
        <Box mt={2} mx={2}>
          <Typography variant="body1">{t('heading')}</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box mx={2}>
          <SelectWithLabel
            value={region}
            options={Object.entries(Region).map(([key, regionOption]) => {
              return { value: key, label: regionOption };
            })}
            placeholder={t('selectorPlaceHolder')}
            handleChange={(
              event: ChangeEvent<{
                name?: string | undefined;
                value: string;
              }>
            ): void => {
              setRegion(event.target.value);
            }}
            error={!!error}
            errorMessage={error}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box mb={2} mt={4} mr={2}>
          <Button id="btn-market-select-back" type="secondary" onClick={handleBack}>
            {t('back')}
          </Button>
          <Button id="btn-market-select-continue" type="primary" onClick={handleContinue}>
            {t('continue')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default MarketSelector;
