import { Button } from '@novozymes/components';
import FitDosage from 'calc/FitDosage';
import React, { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ENZYME_RANGES } from 'scenario/enzymeData';
import { ModelName } from 'scenario/ScenarioType';
import getAtomState, { shouldShowPreviousState } from 'state/atomState';
import { fitDosageStateNew, fitDosageStateRef } from 'state/tempAtoms';
import roundNumber from 'utils/roundNumber';

interface FitDSDXControllsProps {
  type: 'new' | 'ref';
}

const FitDSDXControlls = ({ type }: FitDSDXControllsProps): ReactElement => {
  const { t } = useTranslation('DXChart');

  const isNew = type === 'new';

  const modelName = useRecoilValue(getAtomState<ModelName>(isNew ? 'newEnzymeName' : 'refEnzymeName'));
  const targetTime = useRecoilValue(getAtomState<number>(isNew ? 'newTime' : 'refTime'));

  const [ds, setDS] = useRecoilState(getAtomState<number>(isNew ? 'newEnzymeDS' : 'refEnzymeDS'));
  const [dx, setDX] = useRecoilState(getAtomState<number>(isNew ? 'newDX' : 'refDX'));
  const setDosage = useSetRecoilState(getAtomState<number>(isNew ? 'newEnzymeDosing' : 'refEnzymeDosing'));

  const [dosageState, setDosageState] = useRecoilState(isNew ? fitDosageStateNew : fitDosageStateRef);
  const setShowPreviousState = useSetRecoilState(shouldShowPreviousState);

  const decreaseDS = useCallback(() => {
    if (!ds || !dx || !modelName || !targetTime || dosageState === null) {
      return;
    }
    const dsRange = ENZYME_RANGES[modelName].ds;

    for (let currentDS = Number(ds); currentDS >= dsRange.min; currentDS -= 0.025) {
      const result = FitDosage({
        modelName,
        targetTime,
        enzymeDs: currentDS,
        targetDX: dx,
      });

      if (typeof result === 'number') {
        setShowPreviousState(true);
        setDS(roundNumber(currentDS, 4));
        setDosage(result);
        setDosageState(undefined);
      }
    }
  }, [ds, setDS, dx, modelName, targetTime, dosageState]);

  const increaseDS = useCallback(() => {
    if (!ds || !dx || !modelName || !targetTime || dosageState === null) {
      return;
    }
    const dsRange = ENZYME_RANGES[modelName].ds;

    for (let currentDS = Number(ds); currentDS <= dsRange.max; currentDS += 0.025) {
      const result = FitDosage({
        modelName,
        targetTime,
        enzymeDs: currentDS,
        targetDX: dx,
      });

      if (typeof result === 'number') {
        setShowPreviousState(true);
        setDS(roundNumber(currentDS, 4));
        setDosage(result);
        setDosageState(undefined);
      }
    }
  }, [ds, setDS, dx, modelName, targetTime, dosageState]);

  const decreaseDX = useCallback(() => {
    const DX_BREAKPOINT = 40;

    if (!ds || !dx || !modelName || !targetTime || dosageState === null) {
      return;
    }

    for (let currentDX = Number(dx); currentDX >= DX_BREAKPOINT; currentDX -= 0.067) {
      const result = FitDosage({
        modelName,
        targetTime,
        targetDX: currentDX,
        enzymeDs: ds,
      });

      if (typeof result === 'number') {
        setShowPreviousState(true);
        setDX(roundNumber(currentDX, 4));
        setDosage(result);
        setDosageState(undefined);
        return;
      }
    }
  }, [ds, setDS, dx, setDX, modelName, targetTime, dosageState]);

  const increaseDX = useCallback(() => {
    const DX_BREAKPOINT = 100;

    if (!ds || !dx || !modelName || !targetTime || dosageState === null) {
      return;
    }

    for (let currentDX = Number(dx); currentDX <= DX_BREAKPOINT; currentDX += 0.067) {
      const result = FitDosage({
        modelName,
        targetTime,
        targetDX: currentDX,
        enzymeDs: ds,
      });

      if (typeof result === 'number') {
        setShowPreviousState(true);
        setDX(roundNumber(currentDX, 4));
        setDosage(result);
        setDosageState(undefined);
        return;
      }
    }
  }, [ds, setDS, dx, setDX, modelName, targetTime, dosageState]);

  const decrease = dosageState === 'high';

  return (
    <>
      <Button type="tertiary" onClick={decrease ? decreaseDX : increaseDX}>
        {decrease ? t('decreaseDX') : t('increaseDX')}
      </Button>
      <Button type="tertiary" onClick={decrease ? decreaseDS : increaseDS}>
        {decrease ? t('decreaseDS') : t('increaseDS')}
      </Button>
    </>
  );
};

export default FitDSDXControlls;
