import { DefaultValue, selector } from 'recoil';
import isEqualWith from 'lodash/isEqualWith';

import { ModelName, ProductionSteps, Region, ScenarioData } from 'scenario/ScenarioType';
import currencies from 'scenario/currencies';
import models from 'calc/enzymeModels/Models';
import { DXChartEntry } from 'components/graphs/DXChartTypes';
import calcDMH, { CalcDMHOutput } from 'calc/DMH';
import calcEvaporation from 'calc/evaporation';
import calcSaccDSEnd from 'calc/helpers/calcSaccDSEnd';
import undefinedInputChecker from 'utils/undefinedInputChecker';
import calcSaccDensEnd from 'calc/helpers/calcSaccDensEnd';
import calcSaccDEEnd from 'calc/helpers/calcSaccDEEnd';
import evaporationMVRCost from 'calc/evaporationMVRCost';
import evaporationTVRCost from 'calc/evaporationTVRCost';
import evaporationSavings from 'calc/evaporationSavings';
import DMHSavings, { DMHSavingsOutput } from 'calc/DMHSavings';
import F55SeparationRefMassBalance from 'calc/F55SeparationRefMassBalance';
import F55SeparationNewMassBalance from 'calc/F55SeparationNewMassBalance';
import F55DilutionOfWaterSavings from 'calc/F55DilutionOfWaterSavings';
import getDefaultPrices from 'scenario/defaultPrices';
import { IonExReductionIXReduction, IonExRegenerationSavings, IonExSavingsIso } from 'calc/IonExchange';
import enzymeSavings from 'calc/enzymeSavings';
import { ENZYME_RANGES } from 'scenario/enzymeData';
import { TIMESTEPS } from 'calc/enzymeConstants';
import { isDMHDisabled, isF55Disabled } from 'utils/isProcessStepDisabled';
import getAtomState, { activeScenarioState, remoteScenarioState } from './atomState';

export const isActiveScenarioEdited = selector<boolean>({
  key: 'isActiveScenarioEdited',
  get: ({ get }) => {
    const activeScenario = get(activeScenarioState);
    const savedVersion = get(remoteScenarioState);

    if (activeScenario.id === undefined) {
      return true;
    }

    if (savedVersion === undefined) {
      return true;
    }

    const customizer = (valueA: any, valueB: any): boolean | undefined => {
      const aEmptyUndefined = valueA === undefined || valueA === '';
      const bEmptyUndefined = valueB === undefined || valueB === '';

      return aEmptyUndefined && bEmptyUndefined ? true : undefined;
    };

    // Check if the saved version is different from the active one
    return !isEqualWith(savedVersion, activeScenario, customizer);
  },
});

export const activeScenarioRegion = selector<Region | undefined>({
  key: 'activeScenarioRegion',
  get: ({ get }) => {
    const currentScenario = get(activeScenarioState);

    return currentScenario ? currentScenario.region : undefined;
  },
  set: ({ get, set }, newRegion) => {
    const currentScenario = get(activeScenarioState);

    if (!(newRegion instanceof DefaultValue) && newRegion) {
      const parsedRegion = newRegion as Region;
      const defaultPrices = getDefaultPrices(parsedRegion);

      const currentData = currentScenario.data;

      const scenarioData: Partial<ScenarioData> = {
        ...currentData,
        electricityPrice: defaultPrices.electricity,
        steamPrice: defaultPrices.steam,
        dmhHydrol: defaultPrices.hydrol,
        dmhDe95: defaultPrices.DE95,
        F55SepWaterElectricityPrice: defaultPrices.electricity,
        F55SepWaterSteamPrice: defaultPrices.steam,
        F55IXWasteWaterCost: defaultPrices.wasteWater,
        F55IXWaterPrice: defaultPrices.processWater,
        F55IXResinPriceCat: defaultPrices.catResin,
        F55IXResinPriceAn: defaultPrices.anResin,
        F55IXChemPriceCat: currentData.F55IXRegenChemCat && defaultPrices[currentData.F55IXRegenChemCat],
        F55IXChemPriceAn: currentData.F55IXRegenChemAn && defaultPrices[currentData.F55IXRegenChemAn],
      };

      set(activeScenarioState, {
        ...currentScenario,
        region: parsedRegion,
        data: scenarioData,
      });
    }
  },
});

export const currency = selector({
  key: 'currency',
  get: ({ get }) => {
    const activeRegion = get(activeScenarioRegion);
    if (!activeRegion) {
      return '';
    }

    return currencies[activeRegion];
  },
});

export const enzymePricesGivenState = selector({
  key: 'enzymePricesGiven',
  get: ({ get }) => {
    const refPrice = get(getAtomState<number>('refEnzymePrice'));
    const newPrice = get(getAtomState<number>('newEnzymePrice'));
    return !!refPrice && !!newPrice;
  },
});

export const DXDevelopmentState = selector<DXChartEntry[]>({
  key: 'newDXDevelopment',
  get: ({ get }) => {
    const newEnzModelName = get(getAtomState<ModelName>('newEnzymeName'));
    const refEnzModelName = get(getAtomState<ModelName>('refEnzymeName'));
    if (!newEnzModelName || !refEnzModelName) {
      return [];
    }

    const newEnzymeDS = get(getAtomState<number>('newEnzymeDS'));
    const newEnzymeDosing = get(getAtomState<number>('newEnzymeDosing'));
    const refEnzymeDS = get(getAtomState<number>('refEnzymeDS'));
    const refEnzymeDosing = get(getAtomState<number>('refEnzymeDosing'));

    const newModel = models[newEnzModelName];
    const refModel = models[refEnzModelName];

    if (!newModel || !newEnzymeDS || !newEnzymeDosing) {
      return [];
    }
    if (!refModel || !refEnzymeDS || !refEnzymeDosing) {
      return [];
    }

    const newResult = newModel({
      enzymeDs: newEnzymeDS,
      dosage: newEnzymeDosing,
    });
    const refResult = refModel({
      enzymeDs: refEnzymeDS,
      dosage: refEnzymeDosing,
    });

    if (newResult.length !== refResult.length) {
      return [];
    }

    return newResult.map((newRes, index) => ({
      time: TIMESTEPS[index],
      modelA: newRes,
      modelB: refResult[index],
    }));
  },
});

export const DSOutletRef = selector({
  key: 'DSOutletRef',
  get: ({ get }) => {
    const DX = get(getAtomState<number>('refDX'));
    const DS = get(getAtomState<number>('refEnzymeDS'));

    const checkedFunction = undefinedInputChecker(calcSaccDSEnd);

    return checkedFunction({ DX, DS });
  },
});

export const DSOutletNew = selector({
  key: 'DSOutletNew',
  get: ({ get }) => {
    const DX = get(getAtomState<number>('newDX'));
    const DS = get(getAtomState<number>('newEnzymeDS'));

    const checkedFunction = undefinedInputChecker(calcSaccDSEnd);

    return checkedFunction({ DX, DS });
  },
});

export const DEOutletRef = selector({
  key: 'DEOutletRef',
  get: ({ get }) => {
    const DX = get(getAtomState<number>('refDX'));

    const checkedFunction = undefinedInputChecker(calcSaccDEEnd);

    return checkedFunction({ DX });
  },
});

export const DEOutletNew = selector({
  key: 'DEOutletNew',
  get: ({ get }) => {
    const DX = get(getAtomState<number>('newDX'));

    const checkedFunction = undefinedInputChecker(calcSaccDEEnd);

    return checkedFunction({ DX });
  },
});

export const densityRef = selector({
  key: 'densityRef',
  get: ({ get }) => {
    const checkedFunction = undefinedInputChecker(calcSaccDensEnd);

    return checkedFunction({
      DSEnd: get(DSOutletRef),
      DEEnd: get(DEOutletRef),
    });
  },
});
export const densityNew = selector({
  key: 'densityNew',
  get: ({ get }) => {
    const checkedFunction = undefinedInputChecker(calcSaccDensEnd);

    return checkedFunction({
      DSEnd: get(DSOutletNew),
      DEEnd: get(DEOutletNew),
    });
  },
});

export const evaporationRef = selector({
  key: 'evaporationRef',
  get: ({ get }) => {
    const input = {
      capacity: get(getAtomState('saccCapacity')),
      enzDX: get(getAtomState('refDX')),
      DSOutlet: get(DSOutletRef),
      finalDS: get(getAtomState<number>('evapDSFinal')),
    };
    const checkedFunction = undefinedInputChecker(calcEvaporation);
    return checkedFunction(input);
  },
});

export const evaporationNew = selector({
  key: 'evaporationNew',
  get: ({ get }) => {
    const input = {
      capacity: get(getAtomState('saccCapacity')),
      enzDX: get(getAtomState('newDX')),
      DSOutlet: get(DSOutletNew),
      finalDS: get(getAtomState<number>('evapDSFinal')),
    };
    const checkedFunction = undefinedInputChecker(calcEvaporation);
    return checkedFunction(input);
  },
});

export const evaporationCostState = selector({
  key: 'evaporationCost',
  get: ({ get }) => {
    const evapType = get(getAtomState('evaporatorType'));

    if (evapType === 'MVR') {
      const MVRInput = {
        electricityPrice: get(getAtomState<number>('electricityPrice')),
        electricityConsumption: get(getAtomState<number>('electricityConsumption')),
      };

      return undefinedInputChecker(evaporationMVRCost)(MVRInput);
    }
    if (evapType === 'TVR') {
      const TVRInput = {
        steamPrice: get(getAtomState('steamPrice')),
        evapEffect: get(getAtomState('effects')),
      };
      return undefinedInputChecker(evaporationTVRCost)(TVRInput);
    }
  },
});

export const evaporationSavingsState = selector({
  key: 'evaporationSavings',
  get: ({ get }) => {
    const input = {
      evaporationCost: get(evaporationCostState),
      evaporationRef: get(evaporationRef),
      evaporationNew: get(evaporationNew),
    };
    return undefinedInputChecker(evaporationSavings)(input);
  },
});

export const DmhRefMassBalanceState = selector<CalcDMHOutput | undefined>({
  key: 'dmhRefMassBalance',
  get: ({ get }) => {
    const dmhYield = get(getAtomState<number>('dmhYieldCentri'));

    const evapDs = get(getAtomState<number>('dmhEvapDsProcent'));
    const evapDx = get(getAtomState<number>('dmhEvapDxProcent'));

    const dmhDsDay = get(getAtomState<number>('dmhDsDay'));
    const dmhDsProcent = get(getAtomState<number>('dmhDsProcent'));
    const dmhDxProcent = get(getAtomState<number>('dmhDxProcent'));

    const enzymeDx = get(getAtomState<number>('refDX'));
    const saccDs = get(DSOutletRef);

    const input = {
      DMHYield: dmhYield,
      dailyDSDMH: dmhDsDay,
      procentageDMHDS: dmhDsProcent,
      procentageDMHDX: dmhDxProcent,
      procentageDSEvap: evapDs,
      procentageDXEvap: evapDx,
      procentageDSSacc: saccDs,
      procentageDXSacc: enzymeDx,
    };

    return undefinedInputChecker(calcDMH)(input);
  },
});

export const DmhNewMassBalanceState = selector<CalcDMHOutput | undefined>({
  key: 'dmhNewMassBalance',
  get: ({ get }) => {
    const dmhYield = get(getAtomState<number>('dmhYieldCentri'));

    const evapDs = get(getAtomState<number>('dmhEvapDsProcent'));
    const evapDx = get(getAtomState<number>('dmhEvapDxProcent'));

    const dmhDsDay = get(getAtomState<number>('dmhDsDay'));
    const dmhDsProcent = get(getAtomState<number>('dmhDsProcent'));
    const dmhDxProcent = get(getAtomState<number>('dmhDxProcent'));

    const enzymeDx = get(getAtomState<number>('newDX'));
    const saccDs = get(DSOutletNew);

    const input = {
      DMHYield: dmhYield,
      dailyDSDMH: dmhDsDay,
      procentageDMHDS: dmhDsProcent,
      procentageDMHDX: dmhDxProcent,
      procentageDSEvap: evapDs,
      procentageDXEvap: evapDx,
      procentageDSSacc: saccDs,
      procentageDXSacc: enzymeDx,
    };

    return undefinedInputChecker(calcDMH)(input);
  },
});

export const DmhMassBalanceSavingsState = selector<DMHSavingsOutput | undefined>({
  key: 'dmhMassBalanceSavings',
  get: ({ get }) => {
    const hydrolPrice = get(getAtomState<number>('dmhHydrol'));
    const de95Price = get(getAtomState<number>('dmhDe95'));
    const dmhDsDay = get(getAtomState<number>('dmhDsDay'));

    const refMassBal = get(DmhRefMassBalanceState);
    const newMassBal = get(DmhNewMassBalanceState);
    const input = {
      newDailyDSHydrolOut: newMassBal?.dailyDSHydrolOut,
      refDailyDSHydrolOut: refMassBal?.dailyDSHydrolOut,
      newDailyDSSacc: newMassBal?.dailyDSSacc,
      refDailyDSSacc: refMassBal?.dailyDSSacc,
      DMHHydrolPrice: Number(hydrolPrice),
      DMHDE95Price: Number(de95Price),
      dailyDSDMH: dmhDsDay,
    };

    return undefinedInputChecker(DMHSavings)(input);
  },
});

export const F55SepWaterEvaporationCostState = selector({
  key: 'F55SepWaterEvaporationCost',
  get: ({ get }) => {
    const evapType = get(getAtomState('F55SepWaterEvaporatorType'));

    if (evapType === 'MVR') {
      const MVRInput = {
        electricityPrice: get(getAtomState<number>('F55SepWaterElectricityPrice')),
        electricityConsumption: get(getAtomState<number>('F55SepWaterElectricityConsumption')),
      };

      return undefinedInputChecker(evaporationMVRCost)(MVRInput);
    }
    if (evapType === 'TVR') {
      const TVRInput = {
        steamPrice: get(getAtomState('F55SepWaterSteamPrice')),
        evapEffect: get(getAtomState('F55SepWaterEffects')),
      };
      return undefinedInputChecker(evaporationTVRCost)(TVRInput);
    }
  },
});

export const F55SweetWaterEvaporationCostState = selector({
  key: 'F55SweetWaterEvaporationCost',
  get: ({ get }) => {
    const evapType = get(getAtomState('F55SweetWaterEvaporatorType'));

    if (evapType === 'MVR') {
      const MVRInput = {
        electricityPrice: get(getAtomState<number>('F55SweetWaterElectricityPrice')),
        electricityConsumption: get(getAtomState<number>('F55SweetWaterElectricityConsumption')),
      };

      return undefinedInputChecker(evaporationMVRCost)(MVRInput);
    }
    if (evapType === 'TVR') {
      const TVRInput = {
        steamPrice: get(getAtomState('F55SweetWaterSteamPrice')),
        evapEffect: get(getAtomState('F55SweetWaterEffects')),
      };
      return undefinedInputChecker(evaporationTVRCost)(TVRInput);
    }
  },
});

export const F55RefMassBalanceState = selector({
  key: 'F55RefMassBalance',
  get: ({ get }) => {
    const input = {
      saccDx: Number(get(getAtomState<number>('refDX'))),
      fluctoseRecovery: get(getAtomState('F55FructoseRecovery')),
      refIsoInletTemp: get(getAtomState('F55RefTemperature')),
      DP2PlusRecovery: get(getAtomState('F55DP2PlusRecovery')),
      dailyDSF55: get(getAtomState('F55Capacity')),
      procentageFxF55: get(getAtomState('refFxProcentageF55')),
      dailyDSSacc: get(getAtomState('F55Capacity')),
      procentageFxIso: get(getAtomState('refFxProcentageIso')),
      procentageFxMixing2: get(getAtomState('refFxProcentageMixing2')),
    };

    return undefinedInputChecker(F55SeparationRefMassBalance)(input);
  },
});

export const F55NewMassBalanceState = selector({
  key: 'F55NewMassBalance',
  get: ({ get }) => {
    const refOutput = get(F55RefMassBalanceState);

    const input = {
      saccDx: Number(get(getAtomState<number>('newDX'))),
      fluctoseRecovery: get(getAtomState('F55FructoseRecovery')),
      refIsoInletTemp: get(getAtomState('F55RefTemperature')),
      DP2PlusRecovery: get(getAtomState('F55DP2PlusRecovery')),
      dailyDSF55: get(getAtomState('F55Capacity')),
      procentageFxF55: get(getAtomState('refFxProcentageF55')),
      dailyDSSacc: get(getAtomState('F55Capacity')),
      refProcentageFxIso: get(getAtomState('refFxProcentageIso')),
      procentageFxMixing2: Number(get(getAtomState<number>('refFxProcentageMixing2'))),
      refSaccDx: Number(get(getAtomState<number>('refDX'))),
      refDP1F55: refOutput?.DP1F55,
      refFlowIsofeedF55: refOutput?.FlowIsofeedF55,
      refLNF55: refOutput?.LNF55,
    };

    return undefinedInputChecker(F55SeparationNewMassBalance)(input);
  },
});

export const F55DilWaterSavingsState = selector({
  key: 'F55DilWaterSavings',
  get: ({ get }) => {
    const refOutput = get(F55RefMassBalanceState);
    const newOutput = get(F55NewMassBalanceState);

    const input = {
      newDailyDSIso: newOutput?.dailyDSIso,
      refDailyDSIso: refOutput?.dailyDSIso,
      dilutionOfWater: get(getAtomState('F55DilutionWater')),
      evaporationCost: get(F55SepWaterEvaporationCostState),
    };

    return undefinedInputChecker(F55DilutionOfWaterSavings)(input);
  },
});

export const F55ChangeFlowState = selector({
  key: 'F55ChangeFlow',
  get: ({ get }) => {
    const refOutput = get(F55RefMassBalanceState);
    const newOutput = get(F55NewMassBalanceState);

    const x = newOutput?.FlowIsofeedF55;
    const y = refOutput?.FlowIsofeedF55;

    if (x === undefined || y === undefined) {
      return undefined;
    }

    const flowChange = ((x - y) * 100) / y;

    return flowChange;
  },
});

export const F55IXRegenSavingsState = selector({
  key: 'F55IXRegenSavings',
  get: ({ get }) => {
    const input = {
      resinCatExchangeCapacity: get(getAtomState('F55IXResinCapCat')),
      resinAnExchangeCapacity: get(getAtomState('F55IXResinCapAn')),
      resinCatLifetime: get(getAtomState('F55IXResinLifeCat')),
      resinAnLifetime: get(getAtomState('F55IXResinLifeAn')),
      resinCatPrice: get(getAtomState('F55IXResinPriceCat')),
      resinAnPrice: get(getAtomState('F55IXResinPriceAn')),
      acidName: get(getAtomState('F55IXRegenChemCat')),
      baseName: get(getAtomState('F55IXRegenChemAn')),
      acidConcentration: get(getAtomState('F55IXChemConcentrationCat')),
      baseConcentration: get(getAtomState('F55IXChemConcentrationAn')),
      acidPrice: get(getAtomState('F55IXChemPriceCat')),
      basePrice: get(getAtomState('F55IXChemPriceAn')),
      acidConsumption: get(getAtomState('F55IXChemConsumpCat')),
      baseConsumption: get(getAtomState('F55IXChemConsumpAn')),
      waterCat: get(getAtomState('F55IXWaterCat')),
      waterAn: get(getAtomState('F55IXWaterAn')),
      sweatWaterDilutionCat: get(getAtomState('F55IXSweetWaterDilCat')),
      sweatWaterDilutionAn: get(getAtomState('F55IXSweetWaterDilAn')),
      wasteWaterCat: get(getAtomState('F55IXWasteWaterCat')),
      wasteWaterAn: get(getAtomState('F55IXWasteWaterAn')),
      waterPrice: get(getAtomState('F55IXWaterPrice')),
      wasteWaterPrice: get(getAtomState('F55IXWasteWaterCost')),
      sweatWaterEvaporationCost: get(F55SweetWaterEvaporationCostState),
    };

    const output = undefinedInputChecker(IonExRegenerationSavings)(input);
    return output;
  },
});

export const F55IXReductionSavingsState = selector({
  key: 'F55IXReductionSavings',
  get: ({ get }) => {
    const refOutput = get(F55RefMassBalanceState);
    const newOutput = get(F55NewMassBalanceState);

    const input = {
      drySolidsref: get(getAtomState('F55RefDS')),
      magnesiumName: get(getAtomState('F55MgType')),
      magnesiumDosage: get(getAtomState('F55MgDosage')),
      so2SourceName: get(getAtomState('F55SO2Type')),
      so2SourceDosage: get(getAtomState('F55SO2Dosage')),
      dailyDSMixing1Ref: refOutput?.dailyDSMixing1,
      dailyDSMixing1New: newOutput?.dailyDSMixing1,
    };

    const output = undefinedInputChecker(IonExReductionIXReduction)(input);
    return output;
  },
});

export const enzymeSavingsState = selector({
  key: 'enzymeSavings',
  get: ({ get }) => {
    const input = {
      newEnzPrice: get(getAtomState('newEnzymePrice')),
      refEnzPrice: get(getAtomState('refEnzymePrice')),
      newEnzDose: get(getAtomState('newEnzymeDosing')),
      refEnzDose: get(getAtomState('refEnzymeDosing')),
      capacity: get(getAtomState('saccCapacity')),
    };

    return undefinedInputChecker(enzymeSavings)(input);
  },
});

export const ionExchangeSavingsState = selector({
  key: 'ionExchangeSavings',
  get: ({ get }) => {
    const outputIXReductionSavings = get(F55IXReductionSavingsState);
    const outputIXRegenSavings = get(F55IXRegenSavingsState);
    const refMassBalanceOutput = get(F55RefMassBalanceState);
    const newMassBalanceOutput = get(F55NewMassBalanceState);

    const { totalCatEqReduction, totalAnEqReduction, baseCatEqReduction, baseAnEqReduction } =
      outputIXReductionSavings || {};
    const {
      resinCatSavings,
      resinAnSavings,
      chemCatSavings,
      chemAnSavings,
      wasteWaterCatSavings,
      wasteWaterAnSavings,
      waterCatSavings,
      waterAnSavings,
      sweatWaterCatSavings,
      sweatWaterAnSavings,
    } = outputIXRegenSavings || {};

    const input = {
      totalCatEqReduction,
      totalAnEqReduction,
      resinCatSavings,
      resinAnSavings,
      chemCatSavings,
      chemAnSavings,
      wasteWaterCatSavings,
      wasteWaterAnSavings,
      waterCatSavings,
      waterAnSavings,
      sweatWaterCatSavings,
      sweatWaterAnSavings,
      refDailyDSMixing1: refMassBalanceOutput?.dailyDSMixing1,
      newDailyDSMixing1: newMassBalanceOutput?.dailyDSMixing1,
      mgDosage: get(getAtomState('F55MgDosage')),
      mgPrice: get(getAtomState('F55MgPrice')),
      mgConc: get(getAtomState('F55MgConc')),
      mgType: get(getAtomState('F55MgType')),
      F55RefDS: get(getAtomState('F55RefDS')),
      so2SourceDosage: get(getAtomState('F55SO2Dosage')),
      so2SourcePrice: get(getAtomState('F55SO2Price')),
      so2SourceConc: get(getAtomState('F55SO2Conc')),
      so2Type: get(getAtomState('F55SO2Type')),
      baseCatEqReduction,
      basePrice: get(getAtomState('F55BasePrice')),
      baseConc: get(getAtomState('F55BaseConc')),
      baseType: get(getAtomState('F55BaseType')),
      baseAnEqReduction,
      acidPrice: get(getAtomState('F55AcidPrice')),
      acidConc: get(getAtomState('F55AcidConc')),
      acidType: get(getAtomState('F55AcidType')),
    };

    const output = undefinedInputChecker(IonExSavingsIso)(input);
    return output;
  },
});

const checkIfInRange = (min: number, max: number, value: number): boolean => max >= value && min <= value;

export const isEnzymesOutOfRangeState = selector<boolean>({
  key: 'isEnzymesOutOfRange',
  get: ({ get }) => {
    const newEnzymeName = get(getAtomState<ModelName>('newEnzymeName'));
    const refEnzymeName = get(getAtomState<ModelName>('refEnzymeName'));

    const newEnzymeDosage = get(getAtomState<number>('newEnzymeDosing'));
    const refEnzymeDosage = get(getAtomState<number>('refEnzymeDosing'));

    const newEnzymeDS = get(getAtomState<number>('newEnzymeDS'));
    const refEnzymeDS = get(getAtomState<number>('refEnzymeDS'));

    const newEnzymeTime = get(getAtomState<number>('newTime'));
    const refEnzymeTime = get(getAtomState<number>('refTime'));

    const newTargetDs = get(getAtomState<number>('newDX'));
    const refTargetDs = get(getAtomState<number>('refDX'));

    if (
      !newEnzymeName ||
      !refEnzymeName ||
      !newEnzymeDosage ||
      !refEnzymeDosage ||
      !newEnzymeDS ||
      !refEnzymeDS ||
      !newEnzymeTime ||
      !refEnzymeTime ||
      !newTargetDs ||
      !refTargetDs
    ) {
      return false;
    }

    const newRanges = ENZYME_RANGES[newEnzymeName];
    const refRanges = ENZYME_RANGES[refEnzymeName];

    if (!newRanges || !refRanges) {
      return false;
    }

    const isDosageInRange =
      checkIfInRange(newRanges.dosage.min, newRanges.dosage.max, newEnzymeDosage) &&
      checkIfInRange(refRanges.dosage.min, refRanges.dosage.max, refEnzymeDosage);

    const isDSInRange =
      checkIfInRange(newRanges.ds.min, newRanges.ds.max, newEnzymeDS) &&
      checkIfInRange(refRanges.ds.min, refRanges.ds.max, refEnzymeDS);

    const isTimeInRange =
      checkIfInRange(TIMESTEPS[0], TIMESTEPS[TIMESTEPS.length - 1], newEnzymeTime) &&
      checkIfInRange(TIMESTEPS[0], TIMESTEPS[TIMESTEPS.length - 1], refEnzymeTime);

    const isTargetInRange = checkIfInRange(0, 100, newTargetDs) && checkIfInRange(0, 100, refTargetDs);

    return !isDosageInRange || !isDSInRange || !isTimeInRange || !isTargetInRange;
  },
});

export const totalDailySavingsState = selector<number>({
  key: 'totalDailySavings',
  get: ({ get }) => {
    const productionSteps = get(getAtomState<ProductionSteps>('productionSteps'));
    const dmhDisabled = isDMHDisabled(productionSteps);
    const f55Disabled = isF55Disabled(productionSteps);

    const evapSavings = get(evaporationSavingsState) || 0;
    const dmhSavings = get(DmhMassBalanceSavingsState);
    const dailyDMHSavings = !dmhDisabled ? dmhSavings?.DMHDailySavings || 0 : 0;
    const f55WaterSepSavings = !f55Disabled ? get(F55DilWaterSavingsState) || 0 : 0;

    const F55IXSavings = !f55Disabled ? get(ionExchangeSavingsState)?.totalDailySavingsF55 || 0 : 0;

    const totEnzymeSavings = get(enzymeSavingsState) || 0;

    return evapSavings + totEnzymeSavings + dailyDMHSavings + f55WaterSepSavings + F55IXSavings;
  },
});
