import { Effects } from 'scenario/ScenarioType';
import getSteamConsumption from './helpers/calcSteamConsumption';

interface EvaporationTVRCostInput {
  steamPrice: number;
  evapEffect: Effects;
}

const evaporationTVRCost = ({ steamPrice, evapEffect }: EvaporationTVRCostInput): number => {
  return steamPrice * getSteamConsumption(evapEffect);
};

export default evaporationTVRCost;
