import React, { ReactElement } from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';

interface LegendEntryProps {
  name?: string;
  color: string;
  type?: 'dot' | 'line';
}

const legendStyles = makeStyles((theme) => ({
  legendIcon: (props: LegendEntryProps): CreateCSSProperties<LegendEntryProps> =>
    props.type === 'dot'
      ? {
          width: '8px',
          height: '8px',
          borderRadius: '4px',
          marginRight: theme.spacing(1),
          background: props.color,
        }
      : {
          width: '16px',
          height: '4px',
          marginRight: theme.spacing(1),
          background: props.color,
        },
  legendWrapper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const LegendEntry = (props: LegendEntryProps): ReactElement => {
  const { name } = props;

  const classes = legendStyles(props);

  return (
    <Grid item xs={12} container alignItems="center" className={classes.legendWrapper}>
      <Grid item container justify="center" xs={3}>
        <Box className={classes.legendIcon} />
      </Grid>
      <Grid item xs={9}>
        {name}
      </Grid>
    </Grid>
  );
};
export default LegendEntry;
