import { Auth } from '@aws-amplify/auth';

export const getUserEmail = async (): Promise<string> => {
  const currentUserInfo = await Auth.currentUserInfo();
  return currentUserInfo ? currentUserInfo.attributes.email : '';
};

export const getUserName = async (): Promise<string> => {
  const currentUserInfo = await Auth.currentUserInfo();
  return currentUserInfo ? currentUserInfo?.attributes.name : '';
};

export const getUserType = async (): Promise<string> => {
  const currentUserInfo = await Auth.currentUserInfo();
  const profile = currentUserInfo?.attributes?.profile;
  let userType = 'unknown';
  try {
    const parsedProfile = JSON.parse(profile);
    userType = parsedProfile.isInternal ? 'zymer' : 'customer';
    // eslint-disable-next-line no-empty
  } catch (error) {}
  return userType;
};
