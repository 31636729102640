import React, { ReactNode, ReactElement } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { colors } from '@novozymes/components';

export interface PlaceholderProps {
  children: ReactNode;
}

const useStyles = makeStyles(() => ({
  style: {
    backgroundColor: colors.bg3,
    textAlign: 'center',
    borderRadius: '16px',
  },
}));

const Placeholder = ({ children }: PlaceholderProps): ReactElement => {
  const classes = useStyles();

  return (
    <Box py={3} className={classes.style}>
      {children}
    </Box>
  );
};

export default Placeholder;
