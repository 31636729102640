import { nextRouteState } from 'navigation/processNavigation';
import React, { ReactElement, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

const Home = (): ReactElement => {
  const location = useLocation();
  const history = useHistory();

  const nextRoute = useRecoilValue(nextRouteState(location.pathname));

  useEffect(() => {
    if (nextRoute) {
      history.replace(nextRoute);
    }
  }, [location.pathname, history]);

  return <></>;
};

export default Home;
