import { Acid, Base } from '../scenario/ScenarioType';

export const AcidEq = (acid: Acid): number | undefined => {
  switch (acid) {
    case Acid.HCl:
      return 27.4;
    case Acid.H2SO4:
      return 20.4;
    default:
      // Todo add datadog logging
      console.error('Unreconized acid');
      return undefined;
  }
};

export const BaseEq = (base: Base): number | undefined => {
  switch (base) {
    case Base.NaOH:
      return 25;
    case Base.Na2CO3:
      return 18.9;
    case Base.NH4OH:
      return 28.57;
    case Base['Ca(OH)2']:
      return 27.0;
    default:
      console.error('unreconized base');
      return undefined;
  }
};

export const MAGNESIUM_WEIGHT_EQ = 0.012;

export const SO2_WEIGHT_EQ = 0.064;
