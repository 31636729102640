import React, { ReactElement } from 'react';
import { Box, Typography, Divider, Grid } from '@material-ui/core';
import LinkToDocs from './LinkToDocs';

type PageTitleProps = {
  title: string;
  subTitle?: string;
  docsLinkLabel?: string;
  docsLink?: string;
};

const PageTitle = ({ title, subTitle, docsLink, docsLinkLabel }: PageTitleProps): ReactElement => {
  return (
    <>
      <Box pt={6}>
        <Grid container justify="space-between">
          <Grid item>
            <Typography variant="h1">{title}</Typography>
            {subTitle && <Typography variant="subtitle1">{subTitle}</Typography>}
          </Grid>

          <Grid item>{docsLink && docsLinkLabel && <LinkToDocs fileName={docsLink} text={docsLinkLabel} />}</Grid>
        </Grid>
      </Box>
      <Box mt={4} mb={3}>
        <Divider />
      </Box>
    </>
  );
};

export default PageTitle;
