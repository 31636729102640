interface CalcSaccDensEndInput {
  DSEnd: number;
  DEEnd: number;
}
const calcSaccDensEnd = ({ DEEnd, DSEnd }: CalcSaccDensEndInput): number => {
  return (
    0.9669 -
    0.001642 * DEEnd +
    0.00003031 * DEEnd ** 2 -
    0.0000001652 * DEEnd ** 3 +
    (0.005447 + 0.00003583 * DEEnd - 0.0000007326 * DEEnd ** 2 + 0.000000004006 * DEEnd ** 3) * DSEnd
  );
};

export default calcSaccDensEnd;
