import { TIMESTEPS } from 'calc/enzymeConstants';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { XAxis, YAxis, Line, CartesianGrid, ComposedChart, Scatter } from 'recharts';
import { DXChartBaseProps } from './DXChartTypes';

export interface DXChartProps extends DXChartBaseProps {
  modelAColor: string;
  modelBColor: string;
  zoom?: number;
  useRef?: (ref: any) => void;
}

const getYDomain = (zoomLevel: number): [number, number] => {
  switch (zoomLevel) {
    case 5:
      return [80, 100];
    case 10:
      return [90, 100];
    default:
      return [0, 100];
  }
};

const DXChart = ({
  data,
  modelAName,
  modelBName,
  modelAColor,
  modelBColor,
  modelATarget,
  modelBTarget,
  useRef,
  zoom = 1,
  ...restProps
}: DXChartProps): ReactElement => {
  const { t } = useTranslation('DXChart');
  const targetsDataPoints = [];
  if (modelATarget) {
    const modelADot = { time: modelATarget.time, targetADX: modelATarget.dx };
    targetsDataPoints.push(modelADot);
  }
  if (modelBTarget) {
    const modelBDot = { time: modelBTarget.time, targetBDX: modelBTarget.dx };
    targetsDataPoints.push(modelBDot);
  }

  const chartData = [...data, ...targetsDataPoints];
  return (
    <ComposedChart data={chartData} ref={useRef} {...restProps}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        dataKey="time"
        type="number"
        label={{
          value: t('xLabel') as string,
          position: 'center',
          dy: 10,
          fill: 'rgba(0, 0, 0, 0.47)',
          fontSize: 16,
          transform: 'translate(0,20)',
        }}
        height={80}
        tick={{ dy: 10 }}
        tickLine={{ stroke: '#cccccc' }}
        tickFormatter={(value: number): number => Math.round(value)}
        ticks={Array.from({ length: 9 }, (v, index) => (index === 0 ? 6 : index * 12))}
        domain={['dataMin', 'dataMax']}
      />
      <YAxis
        tick={{ dx: -10 }}
        width={100}
        type="number"
        tickFormatter={(value: number): number => Math.round(value)}
        label={{
          value: t('yLabel') as string,
          transform: 'rotate(-90),translate(-200,-120)',
          fill: 'rgba(0, 0, 0, 0.47)',
          fontSize: 16,
        }}
        tickLine={{ stroke: '#cccccc' }}
        domain={getYDomain(zoom)}
        allowDataOverflow
      />

      <Scatter name="modelAName" dataKey="targetADX" fill={modelAColor} />
      <Scatter name="modelBName" dataKey="targetBDX" fill={modelBColor} />
      <Line strokeWidth={4} dot={false} type="monotone" dataKey="modelA" stroke={modelAColor} name={modelAName} />
      <Line strokeWidth={4} dot={false} type="monotone" dataKey="modelB" stroke={modelBColor} name={modelBName} />
    </ComposedChart>
  );
};

export default DXChart;
