import { Box, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import ValueHighlight from 'components/ValueHighlight';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import getAtomState from 'state/atomState';
import { densityNew, densityRef, DSOutletNew, DSOutletRef, evaporationNew, evaporationRef } from 'state/selectors';
import useInputWithState from 'state/useInputWithState';

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    display: 'flex',
    alignItems: 'center',
  },
  heading: {
    paddingBottom: theme.spacing(1),
  },
  input: {
    padding: theme.spacing(0.5, 5, 0.5, 0),
  },
}));

const EvapSaccData = (): ReactElement => {
  const DSInletRef = useRecoilValue(getAtomState<number>('refEnzymeDS'));
  const DSInletNew = useRecoilValue(getAtomState<number>('newEnzymeDS'));

  const refDSOutlet = useRecoilValue(DSOutletRef);
  const newDSOutlet = useRecoilValue(DSOutletNew);

  const refDensity = useRecoilValue(densityRef);
  const newDensity = useRecoilValue(densityNew);

  const refEvaporation = useRecoilValue(evaporationRef);
  const newEvaporation = useRecoilValue(evaporationNew);

  const [, evapDSFinalInput] = useInputWithState({
    key: 'evapDSFinal',
    unit: '% (w/w)',
  });

  const classes = useStyles();
  const { t } = useTranslation('evaporationSaccData');

  return (
    <>
      <Grid container>
        <Grid container item xs={2}>
          <Grid item xs={12} className={classes.label}>
            <Typography variant="body1">{t('DSFinal')}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={10}>
          <Grid container>
            <Grid item xs={3} className={classes.input}>
              {evapDSFinalInput}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box my={2} />
      <Grid container>
        <Grid container item xs={2}>
          <Grid item xs={12} />
        </Grid>
        <Grid item xs={10}>
          <Grid container>
            <Grid item xs={3} className={classes.heading}>
              <Typography variant="body1">{t('DSInlet')}</Typography>
            </Grid>
            <Grid item xs={3} className={classes.heading}>
              <Typography variant="body1">{t('DSOutlet')}</Typography>
            </Grid>
            <Grid item xs={3} className={classes.heading}>
              <Typography variant="body1">{t('density')}</Typography>
            </Grid>

            <Grid item xs={3} className={classes.heading}>
              <Typography variant="body1">{t('evaporation')}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={2}>
          <Grid item xs={12} className={classes.label}>
            <Typography variant="body1">{t('reference')}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={10}>
          <Grid container>
            <Grid item xs={3} className={classes.input}>
              <ValueHighlight value={DSInletRef} decimals={1} unit="% (w/w)" />
            </Grid>
            <Grid item xs={3} className={classes.input}>
              <ValueHighlight value={refDSOutlet} decimals={1} unit="% (w/w)" />
            </Grid>
            <Grid item xs={3} className={classes.input}>
              <ValueHighlight value={refDensity} unit="kg / l" />
            </Grid>

            <Grid item xs={3} className={classes.input}>
              <ValueHighlight
                value={refEvaporation}
                unit={
                  <span>
                    t H<sub>2</sub>0 / day
                  </span>
                }
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container item xs={2}>
          <Grid item xs={12} className={classes.label}>
            <Typography variant="body1"> {t('newSolution')}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={10}>
          <Grid container>
            <Grid item xs={3} className={classes.input}>
              <ValueHighlight value={DSInletNew} decimals={1} unit="% (w/w)" />
            </Grid>
            <Grid item xs={3} className={classes.input}>
              <ValueHighlight value={newDSOutlet} decimals={1} unit="% (w/w)" />
            </Grid>
            <Grid item xs={3} className={classes.input}>
              <ValueHighlight value={newDensity} unit="kg / l" />
            </Grid>

            <Grid item xs={3} className={classes.input}>
              <ValueHighlight
                value={newEvaporation}
                unit={
                  <span>
                    t H<sub>2</sub>0 / day
                  </span>
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default EvapSaccData;
